import { createStyles, makeStyles } from "@material-ui/core";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { Button } from "@mui/material";

export const MMTypeButtonStyles = Object.freeze({
  Primary: Symbol("primary"),
  Secondary: Symbol("secondary"),
});
const MMButtonStyles = {
  primary: {
    background: "#FFC10E",
    color: "#363853",
    fontWeight: "bold",
    textTransform: "none",
    minWidth: "100px",
    height: "48px",
    borderRadius: "8px",
  },
  secondary: {
    background: "white",
    color: "#404A56",
    fontWeight: "bold",
    textTransform: "none",
    minWidth: "100px",
    height: "48px",
    border: "1px solid #FFC10E",
    borderRadius: "8px",
  },
  disabled: {
    color: "#00000042",
    boxShadow: "none",
    backgroundColor: "#0000001e",
    minWidth: "100px",
    height: "48px",
    borderRadius: "8px",
  },
};

const MMButtonCustom = (props) => {
  const {
    nameButton,
    onAction,
    children,
    xs = null,
    type = MMTypeButtonStyles.Primary,
    disabled = false,
    isLoading = false,
    startIcon,
  } = props;

  const styleButton = setStyleByType(type);

  function setStyleByType(type) {
    if (!xs) {
      if (type === MMTypeButtonStyles.Primary) {
        return !disabled ? MMButtonStyles.primary : MMButtonStyles.disabled;
      } else {
        return !disabled ? MMButtonStyles.secondary : MMButtonStyles.disabled;
      }
    }
    return xs;
  }

  return (
    <Button
      data-testid={nameButton ? `test-${nameButton}` : "test-button-custom"}
      style={styleButton}
      onClick={onAction}
      className={nameButton}
      disabled={disabled}
      startIcon={startIcon}
      variant="contained"
      disableElevation
    >
      {isLoading ? <LoaderButton /> : children}
    </Button>
  );
};

const LoaderButton = (_) => {
  const useStyles = makeStyles(() =>
    createStyles({
      rotateIcon: {
        animation: "$spin 2s linear infinite",
      },
      "@keyframes spin": {
        "0%": {
          transform: "rotate(360deg)",
        },
        "100%": {
          transform: "rotate(0deg)",
        },
      },
    })
  );

  const classes = useStyles();

  return <DataUsageIcon className={classes.rotateIcon} />;
};

export default MMButtonCustom;
