import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Card, CardContent, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";

import GenericBackdrop from "../../../utils/GenericBackdrop";
import { Alert } from "../../utils/alert";

import { axiosClient } from "../../commons/axiosClient";

const RegisterPaymentBtn = ({ detailOwner }) => {
  const [t] = useTranslation("global");

  const [loader, setLoader] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleApp, setVisibleApp] = useState(false);
  const [isVisibleSave, setIsVisibleSave] = useState(true);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  const [metadata, setMetadata] = useState("");
  const [appId, setAppId] = useState("");

  const generateFormData = (type) => {
    return [
      {
        label: t("Owners.BtnPayment.Method"),
        name: "method",
        value: "",
        required: true,
        testid: `method_${type}`,
      },
      {
        label: t("Owners.BtnPayment.Url"),
        name: "url",
        value: "",
        required: true,
        testid: `url_${type}`,
      },
      {
        label: t("Owners.BtnPayment.Headers"),
        name: "headers",
        value: "",
        required: true,
        testid: `headers_${type}`,
      },
      {
        label: t("Owners.BtnPayment.Parameters"),
        name: "params",
        value: "",
        required: false,
        testid: `params_${type}`,
      },
      {
        label: t("Owners.BtnPayment.Body"),
        name: "body",
        value: "",
        required: false,
        testid: `params_${type}`,
      },
    ];
  };
  const [formDataWrong, setFormDataWrong] = useState(generateFormData("wrong"));
  const [formDataCorrect, setFormDataCorrect] = useState(generateFormData("correct"));
  const callbackOK = "BTN_FINISH_OK";
  const callbackRejected = "BTN_REJECTED";

  useEffect(() => {
    loadExistsData();
  }, [detailOwner]);

  const loadExistsData = () => {
    if (detailOwner.PaymentButton.length !== 0) {
      const callbackCorrect = detailOwner.PaymentButton.filter((button) => button.type === callbackOK)[0];
      const callbackWrong = detailOwner.PaymentButton.filter((button) => button.type === callbackRejected)[0];
      setMetadata(callbackCorrect.metadata);
      setAppId(callbackCorrect.app_id);

      const updatedCorrectData = formDataCorrect.map((field) => {
        const fieldName = field.name;
        const value = callbackCorrect[fieldName] || "";
        return { ...field, value };
      });
      setFormDataCorrect(updatedCorrectData);

      const updatedWrongData = formDataWrong.map((field) => {
        const fieldName = field.name;
        const value = callbackWrong[fieldName] || "";
        return { ...field, value };
      });
      setFormDataWrong(updatedWrongData);
      setIsVisibleSave(false);
      setIsVisible(true);
      setIsVisibleUpdate(false);
      setVisibleApp(true);
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === "Metadata") {
      setMetadata(value);
    } else {
      setAppId(value);
    }
  };

  const handleChangeCorrect = (event, index) => {
    const value = event.target.value;
    const updatedData = [...formDataCorrect];
    updatedData[index].value = value;
    setFormDataCorrect(updatedData);
  };

  const handleChangWrong = (event, index) => {
    const value = event.target.value;
    const updatedFormData = [...formDataWrong];
    updatedFormData[index].value = value;
    setFormDataWrong(updatedFormData);
  };

  const createObjetCallback = () => {
    const jsonObject = {};
    const jsonObjectWrong = {};
    const callbacks = [];

    for (const item of formDataCorrect) {
      jsonObject[item.name] = item.value;
    }
    jsonObject["type"] = callbackOK;
    callbacks.push(jsonObject);

    for (const item of formDataWrong) {
      jsonObjectWrong[item.name] = item.value;
    }
    jsonObjectWrong["type"] = callbackRejected;
    callbacks.push(jsonObjectWrong);

    return callbacks;
  };

  const handleSave = (event) => {
    event.preventDefault();
    handleLoaderOpen();

    const body = {
      wallet_id: detailOwner.wallet_id,
      metadata: metadata,
      callbacks: createObjetCallback(),
    };
    axiosClient
      .post("merchants/payment/data", body)
      .then((response) => {
        setAppId(response.data.app_id);
        setIsVisible(true);
        setIsVisibleSave(false);
        setVisibleApp(true);
        handleLoaderClose();
        detailOwner.PaymentButton = body.callbacks;
        return Alert(t("Owners.BtnPayment.Successful"), "success");
      })
      .catch((_) => {
        handleLoaderClose();
        return Alert(t("General.Message.ErrorService"), "error");
      });
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setIsVisible(false);
    setVisibleApp(true);
    setIsVisibleUpdate(true);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    handleLoaderOpen();

    const body = {
      app_id: appId,
      metadata: metadata,
      callbacks: createObjetCallback(),
    };

    axiosClient
      .patch("/merchants/payment/data", body)
      .then((_) => {
        setIsVisible(true);
        setIsVisibleUpdate(false);
        setVisibleApp(true);
        handleLoaderClose();
        detailOwner.PaymentButton = body.callbacks;
        return Alert(t("Owners.BtnPayment.Update"), "success");
      })
      .catch((_) => {
        handleLoaderClose();
        return Alert(t("General.Message.ErrorService"), "error");
      });
  };

  return (
    <Card>
      <CardContent>
        {detailOwner.stores.length === 0 ? (
          <div style={{ height: "50vh" }}>
            <div
              style={{
                position: "relative",
                left: "80%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {t("Owners.BtnPayment.NoBranches")}
            </div>
          </div>
        ) : (
          <form>
            <GenericBackdrop open={loader} />
            <Stack spacing={2} sx={{ maxWidth: 600, margin: "0 auto" }}>
              <TextField
                fullWidth
                label={t("Owners.BtnPayment.App")}
                name="AppId"
                value={appId}
                disabled={true}
                style={{ display: isVisibleApp ? "block" : "none" }}
                inputProps={{ "data-testid": "AppId" }}
                onChange={(e) => handleChange(e)}
              />
              <TextField
                fullWidth
                label={t("Owners.BtnPayment.Metadata")}
                name="Metadata"
                value={metadata}
                required={false}
                style={{ display: !isVisible ? "block" : "none" }}
                inputProps={{ "data-testid": "Metadata" }}
                onChange={(e) => handleChange(e)}
              />
              <Typography variant="h5" component="div" gutterBottom style={{ display: !isVisible ? "block" : "none" }}>
                {t("Owners.BtnPayment.CallbackCorrect")}
              </Typography>
              {formDataCorrect.map((field, index) => (
                <TextField
                  fullWidth
                  key={field.name}
                  label={field.label}
                  name={field.name}
                  value={field.value}
                  required={field.required}
                  inputProps={{ "data-testid": field.testid }}
                  style={{
                    display: !isVisible ? "block" : "none",
                    maxWidth: 600,
                  }}
                  onChange={(e) => handleChangeCorrect(e, index)}
                />
              ))}
              <Typography variant="h5" component="div" gutterBottom style={{ display: !isVisible ? "block" : "none" }}>
                {t("Owners.BtnPayment.CallbackWrong")}
              </Typography>
              {formDataWrong.map((field, index) => (
                <TextField
                  fullWidth
                  key={field.name}
                  label={field.label}
                  name={field.name}
                  value={field.value}
                  required={field.required}
                  inputProps={{ "data-testid": field.testid }}
                  style={{
                    display: !isVisible ? "block" : "none",
                    maxWidth: 600,
                  }}
                  onChange={(e) => handleChangWrong(e, index)}
                />
              ))}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSave}
                style={{ display: isVisibleSave ? "block" : "none" }}
              >
                {t("Owners.BtnPayment.BtnSave")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleEdit}
                style={{ display: isVisible ? "block" : "none" }}
              >
                {t("Owners.BtnPayment.BtnEdit")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                style={{ display: isVisibleUpdate ? "block" : "none" }}
              >
                {t("Owners.BtnPayment.BtnUpdate")}
              </Button>
            </Stack>
          </form>
        )}
      </CardContent>
    </Card>
  );
};

export default RegisterPaymentBtn;
