import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { AgGridReact } from "@ag-grid-community/react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import "../../Styles/App.scss";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { ExportExcel } from "../../utils/exportExcel";
import GenericBackdrop from "../../utils/GenericBackdrop";
import { isEmptyObject } from "../../utils/validateExpression";
import { notifyRejected } from "../utils/actions/axios-api-wc";
import { SnackBar } from "../utils/alert";
import { getAuthTokenAzure } from "../utils/localStorage";

import { axiosClientKym, axiosClientWc } from "../commons/axiosClient";
import ListSelect from "../commons/listSelect";
import PermissionsGate from "../commons/PermissionsGate";
import Search from "../commons/search";
import PopupEditCron from "../configuration/integrator/PopupEditCron";
import PopupEditInformation from "../configuration/integrator/PopupEditInformation";
import PopupEditParams from "../configuration/integrator/PopupEditParams";

const AggridTable = ({
  columnDefs,
  pagination,
  url,
  params,
  _setParams,
  _upperCase,
  height,
  width,
  rowdata,
  showsearch = true,
  showShadow = true,
  styleGrid = "standard",
  rowSelection = "single",
  edit = false,
  ValidateonRowValue = false,
  _startEditingcolumn = "",
  isWorkingCapital,
  masterDetail = false,
  detailCellRendererParams,
  kyx = false,
  reload = true,
  setReload = null,
  listSelectData = false,
  country = "",
}) => {
  const [t] = useTranslation("global");
  const azureToken = getAuthTokenAzure();

  const [gridApi, setGridApi] = useState(null);
  const [data, setdata] = useState([]);
  const [dataBack, setdataBack] = useState([]);
  const [backUp, setBackUp] = useState([]);
  const [columnsToDownload, setColumnsToDownload] = useState([]);
  const [columnsSearch, setColumnsSearch] = useState([]);
  const [dataToDownload, setDataToDownload] = useState([]);
  const [allDataToDownload, setAllDataToDownload] = useState([]);
  const [paramsType, setParamsType] = useState([]);
  const searchText = isWorkingCapital ? "Buttons.Search" : "Buttons.SearchInsight";

  const modules = useMemo(() => [ClientSideRowModelModule, CsvExportModule], []);
  const gridRef = useRef();
  const [loader, setLoader] = useState(false);
  const [validation, setValidation] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);
  const axiosPrivate = useAxiosPrivate();

  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  const onGridReady = (param) => {
    setGridApi(param.api);
  };

  const getRowStyle = (_) => {
    if (styleGrid === "ligth") return { borderBottom: "solid 1px #CFD5DC" };
    else return undefined;
  };

  function getData() {
    handleLoaderOpen();

    if (rowdata) {
      setdata(rowdata);
      handleLoaderClose();
    } else {
      axiosClientClass()
        .get(url, isSearch ? { params: { ...params, isSearch } } : { params })
        .then((response) => {
          let dt = checkResult(response);
          if (params && url === "owners") dt = filterMerchant(params, dt);
          setdata(dt);
          setdataBack(dt);
          setBackUp(dt);
          handleLoaderClose();
        })
        .catch((_) => {
          handleLoaderClose();
          SnackBar(t("General.Message.ErrorService"), "error");
        });
    }
  }

  function axiosClientClass() {
    if (isWorkingCapital) {
      url = "working_capital";

      axiosClientKym.defaults.headers["Authorization"] = azureToken;
      axiosClientWc.defaults.headers["Authorization"] = azureToken;

      return axiosClientKym;
    } else if (kyx) {
      return axiosClientKym;
    } else {
      return axiosPrivate;
    }
  }

  function checkResult(response) {
    let dataResultDownload = [];
    let dataColumnsDownload = [];
    let dataColumnsSearch = [];

    if (isWorkingCapital) {
      dataResultDownload = response.data.results_to_download_wc;
      dataColumnsDownload = [
        "Nombre del punto",
        "IDPDV / IDPTM",
        "Cédula",
        "#eMoney",
        "#ePin",
        "Fecha",
        "Estatus",
        "Documento de Identidad Frente",
        "Documento de Identidad Reverso",
        "Constancia de Registro Unico del contribuyente",
        "Comprobante de declaración de Impuestos",
      ];
    } else if (response.data?.results_to_download) {
      dataResultDownload = response.data.results_to_download;
      dataColumnsDownload = [
        "Merchant ID",
        "Nombre",
        "Fecha de nacimiento",
        "Nacionalidad",
        "Persona",
        "Tipo de persona",
      ];
    }

    setAllDataToDownload(dataResultDownload);
    setDataToDownload(dataResultDownload);
    setColumnsToDownload(dataColumnsDownload);
    setColumnsSearch(dataColumnsSearch);

    if (response.data?.results)
      for (let clave in response.data.results[0]) {
        let typeVariable = typeof response.data.results[0][clave];
        if (typeVariable === "string") dataColumnsSearch.push(clave);
      }

    if (response.data?.results) return isEmptyObject(response.data.results) ? [] : response.data.results;
    return isEmptyObject(response.data) ? [] : response.data;
  }

  useEffect(() => {
    if (reload) getData();
    if (reload && setReload) setReload(false);
  }, [reload]);

  const filterMerchant = (params, dt) => {
    let list = [];
    let pending = "PENDING";
    let validated = "VALIDATED";

    if (isEmptyObject(dt)) return dt;

    switch (params) {
      case "Pending":
        list = dt.filter(function (r) {
          return (
            (r.status_kym.toUpperCase() === pending ||
              r.sign_contract.toUpperCase() === pending ||
              r.status_aml.toUpperCase() === pending) &&
            r.status_aml !== "Rejected" &&
            r.status_kym.toUpperCase() !== "REJECTED"
          );
        });
        break;
      case "Validated":
        list = dt.filter(function (r) {
          return (
            r.status_kym.toUpperCase() === validated &&
            r.sign_contract.toUpperCase() === validated &&
            r.status_aml.toUpperCase() === validated
          );
        });
        break;
      case "Upload":
        list = dt.filter(function (r) {
          return r.documents_rejected === true && r.status_aml !== "Rejected";
        });
        break;
      case "RejectedAml":
        list = dt.filter(function (r) {
          return r.status_aml === "Rejected";
        });
        break;
      case "All_":
      default:
        list = dt;
        break;
    }
    return list;
  };

  const getFilterData = (filter, status, filterType) => {
    let dataFilter = [];
    let dataFilterDoc = [];
    let dataStatus = [];

    if (status === "ALL") {
      let all = "ALL";
      status = status.replace(all, "");
    }
    dataStatus = dataBack.filter((x) => x.status.includes(status));
    dataFilterDoc = allDataToDownload.filter((x) => x.status.includes(status));
    switch (filterType) {
      case "ePin":
        dataFilter = dataStatus.filter(
          (x) =>
            x.epin.toUpperCase() != "NO APLICA" &&
            x.epin != "" &&
            (x.emoney.toUpperCase() == "NO APLICA" || x.emoney == "")
        );
        dataFilterDoc = dataFilterDoc?.filter(
          (x) =>
            x.epin.toUpperCase() != "NO APLICA" &&
            x.epin != "" &&
            (x.emoney.toUpperCase() == "NO APLICA" || x.emoney == "")
        );
        break;
      case "eMoney":
        dataFilter = dataStatus.filter(
          (x) =>
            x.emoney.toUpperCase() != "NO APLICA" &&
            x.emoney != "" &&
            (x.epin.toUpperCase() == "NO APLICA" || x.epin == "")
        );
        dataFilterDoc = dataFilterDoc?.filter(
          (x) =>
            x.emoney.toUpperCase() != "NO APLICA" &&
            x.emoney != "" &&
            (x.epin.toUpperCase() == "NO APLICA" || x.epin == "")
        );
        break;
      case "Both":
        dataFilter = dataStatus.filter(
          (x) =>
            x.epin.toUpperCase() != "NO APLICA" &&
            x.epin != "" &&
            x.emoney.toUpperCase() != "NO APLICA" &&
            x.emoney != ""
        );
        dataFilterDoc = dataFilterDoc?.filter(
          (x) =>
            x.epin.toUpperCase() != "NO APLICA" &&
            x.epin != "" &&
            x.emoney.toUpperCase() != "NO APLICA" &&
            x.emoney != ""
        );
        break;
      default:
        dataFilter = dataBack.filter((x) => x.status.includes(status));
        dataFilterDoc = allDataToDownload.filter((x) => x.status.includes(status));
        break;
    }
    return { dataFilter, dataFilterDoc };
  };

  const filterWorkingCapital = (filter, filterType) => {
    let dataColumnsDownload;
    if (isWorkingCapital) {
      filter = filter.toUpperCase();
      const status = filter === "VALIDATED" ? "ACCEPTED" : filter;
      const dataFilter = getFilterData(filter, status, filterType);
      if (filter === "REJECTED") {
        dataColumnsDownload = [
          "Nombre del punto",
          "IDPDV / IDPTM",
          "Cédula",
          "#eMoney",
          "#ePin",
          "Fecha",
          "Estatus",
          "Documento de Identidad Frente",
          "Documento de Identidad Reverso",
          "Constancia de Registro Unico del contribuyente",
          "Comprobante de declaración de Impuestos",
        ];
      } else {
        dataColumnsDownload = ["Nombre del punto", "IDPDV / IDPTM", "Cédula", "#eMoney", "#ePin", "Fecha", "Estatus"];
      }
      setDataToDownload(dataFilter.dataFilterDoc);
      setColumnsToDownload(dataColumnsDownload);
      setdata(dataFilter.dataFilter);
      setBackUp(dataFilter.dataFilter);
    } else getData();
  };

  useEffect(() => {
    if (rowdata) return;
    filterWorkingCapital(params, paramsType);
  }, [params, paramsType]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
    };
  }, []);

  // Guardar por get y set desde las columnas
  const onCellValueChanged = useCallback((event) => {
    if (event.oldValue !== event.newValue) {
      setValidation(true);
    }
  }, []);

  const cleardata = () => {
    setdata([]);
    getData();
  };

  const updateValue = (method, upurl, updata) => {
    axiosPrivate({
      method,
      url: upurl,
      data: updata,
    })
      .then(() => {
        SnackBar(t("AGgrid.Message.Sucess"), "success", "#DFFFEA");
        cleardata();
        handleLoaderClose();
      })
      .catch((_) => {
        SnackBar(t("General.Message.ErrorService"), "error");
        cleardata();
        handleLoaderClose();
      });
  };

  const onRowValueChanged = useCallback(
    (event) => {
      let method;
      let upurl = url;
      if (!event.data.id) {
        method = "post";
      } else {
        method = "put";
        upurl = `${url}/${event.data.id}`;
      }
      handleLoaderOpen();
      if (validation) {
        if (typeof ValidateonRowValue == "function") {
          if (ValidateonRowValue(event.data)) {
            updateValue(method, upurl, event.data);
          } else {
            SnackBar(t("General.Message.ErrorUrl"), "error");
            cleardata();
            handleLoaderClose();
          }
        } else {
          updateValue(method, upurl, event.data);
        }
        setValidation(false);
      } else {
        gridRef.current.api.stopEditing();
        handleLoaderClose();
      }
    },
    [validation]
  );

  const frameworkComponents = {
    popupEditCron: PopupEditCron,
    popupEditParams: PopupEditParams,
    popupEditInfo: PopupEditInformation,
  };

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);

  const submitNotifyRejected = async () => {
    const response = await notifyRejected(country);
    if (response?.status !== 200) {
      SnackBar(t("General.Message.ErrorNotifyRejected"), "error");
      return;
    }
    SnackBar(t("General.Message.SucessNotifyRejected"), "success");
  };

  return (
    <Fragment>
      {kyx && (
        <ExportExcel fileType={"csv"} fileName={"Operaciones"} data={dataToDownload} columns={columnsToDownload} />
      )}
      <GenericBackdrop open={loader} />
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item xs={6} md={6}>
          <PermissionsGate scopes={"merchants.actions.search"}>
            {showsearch ? (
              <Search
                backUp={backUp}
                setSearch={setdata}
                columnsSearch={columnsSearch}
                setIsSearch={setIsSearch}
                placeholder={searchText}
              />
            ) : (
              <div></div>
            )}
          </PermissionsGate>
        </Grid>
        <Grid item xs={2} alignItems="center">
          {listSelectData && (
            <ListSelect itemsMenu={listSelectData.list} title={listSelectData.title} setFilter={setParamsType} />
          )}
        </Grid>

        {isWorkingCapital && (
          <Grid item xs={2}>
            <Button
              variant="outlined"
              startIcon={<EmailOutlinedIcon />}
              onClick={submitNotifyRejected}
              className="action-button-outlined-secondary"
            >
              {t("Buttons.NotifyRejected")}
            </Button>
          </Grid>
        )}
        {isWorkingCapital && (
          <Grid item xs={2}>
            <ExportExcel
              fileType={"xlsx"}
              fileName={"Working Capital"}
              data={dataToDownload}
              columns={columnsToDownload}
            />
          </Grid>
        )}
      </Grid>
      <Card style={showShadow ? { borderRadius: "5px" } : { borderRadius: "5px", boxShadow: "none" }}>
        <CardContent>
          <Grid container direction="row">
            <Grid container item xs={6} style={{ justifyContent: "end" }}>
              <Button
                style={{
                  borderColor: "white",
                  margin: "10px",
                  height: "min-content",
                  borderRadius: "10px",
                  textTransform: "none",
                  display: "none",
                  backgroundColor: "white",
                  color: "green",
                }}
                variant="outlined"
                startIcon={<ImportExportIcon />}
                data-testid="export"
                onClick={() => {
                  onBtnExport();
                }}
              >
                {t("AGgrid.ExportToCSV")}
              </Button>
            </Grid>
          </Grid>
          <div id="myGrid" className="ag-theme-material" style={{ height, width, fontFamily: "Roboto" }}>
            <AgGridReact
              reactUi="true"
              animateRows="true"
              rowData={data}
              columnDefs={columnDefs}
              pagination={pagination}
              modules={modules}
              defaultColDef={defaultColDef}
              onCellValueChanged={onCellValueChanged}
              rowSelection={rowSelection}
              // van juntos onRow y editType para guardar toda la fila entera
              onRowValueChanged={edit && onRowValueChanged}
              editType={edit && "fullRow"}
              suppressExcelExport={true}
              popupParent={document.body}
              onGridReady={onGridReady}
              suppressRowClickSelection="true"
              ref={gridRef}
              frameworkComponents={frameworkComponents}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              copyHeadersToClipboard={true}
              sortingOrder={sortingOrder}
              getRowStyle={getRowStyle}
              masterDetail={masterDetail}
              detailCellRendererParams={detailCellRendererParams}
            ></AgGridReact>
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default AggridTable;
