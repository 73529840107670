import React from "react";

import { ThemeProvider } from "@mui/material/styles";

import Theme from "../themes/Theme";

import Sesion from "../components/commons/sesion";
import Configurations from "../components/configuration/configurations";

const Configuration = () => {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Configuration"} />
      <ThemeProvider theme={Theme}>
        <Configurations />
      </ThemeProvider>
    </div>
  );
};

export default Configuration;
