import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Divider, MenuItem, Select } from "@material-ui/core";
import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { ExportExcel } from "../../utils/exportExcel";
import { SendEmail } from "../../utils/SendEmail";

import { FilterDetails } from "./filterDetails";

import GenericBackdrop from "../commons/GenericBackdrop";
import PermissionsGate from "../commons/PermissionsGate";
import Search from "../commons/search";

export default function Buttons({
  data,
  columns,
  backUp,
  setSearch,
  columnsSearch,
  setIsSearch,
  dataFilter,
  dateDefaultFilter,
  filterEmail,
  stores,
}) {
  const [t] = useTranslation("global");
  const [merchant, setMerchantSelected] = useState("");
  const [selected, setSelected] = useState(" ");
  const [merchants, setMerchants] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const [loader, setLoader] = useState(false);

  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  useEffect(() => {
    const getMerchants = () => {
      handleLoaderOpen();
      axiosPrivate
        .get(`transactions-internal`, {
          params: {
            client_id: "",
          },
        })
        .then((res) => {
          setMerchants(res.data);
          handleLoaderClose();
        })
        .catch((err) => {
          handleLoaderClose();
          console.error(err);
        });
    };
    getMerchants();
  }, []);

  const handleChange = (e) => {
    setMerchantSelected(e.target.value);
    setSelected(e.target.value);
    let parameters = {
      status: "",
      branch: "",
      payment: "",
      date_init: "",
      date_end: "",
      client_id: e.target.value,
    };
    dataFilter(parameters);
  };

  return (
    <Stack mb={2} mt={2}>
      <GenericBackdrop open={loader} />
      <Typography
        className="text"
        style={{
          display: "inline",
          fontSize: "18px",
          color: "#404A56",
          fontWeight: "600",
          marginTop: "30px",
          paddingBottom: "14px",
        }}
      >
        Merchants
      </Typography>
      <PermissionsGate scopes={"insights.actions.select"}>
        <Select
          disableUnderline
          style={{
            borderRadius: "8px",
            border: "1px solid #BFC7D0",
            color: "#565656",
            width: "300px",
            paddingLeft: "10px",
          }}
          onChange={handleChange}
          value={selected}
          inputProps={{ "data-testid": "select-merchant" }}
        >
          <MenuItem key={""} value={""}></MenuItem>
          {merchants.map((option) => (
            <MenuItem key={option.id_merchant} value={option.id_merchant}>
              {option.merchant}
            </MenuItem>
          ))}
        </Select>
      </PermissionsGate>
      <Divider style={{ marginTop: "45px", backgroundColor: "#AFB9C4" }} />
      <Typography
        variant="subTitle18"
        style={{
          display: "inline",
          fontSize: "18px",
          color: "#404A56",
          fontWeight: "600",
          paddingTop: "40px",
        }}
      >
        {t("Insights.Message.Movements")}
      </Typography>
      <Stack direction="row" width="100%" mt={2}>
        <Stack direction="row" width="150vh" spacing={1} justifyContent="start">
          <PermissionsGate scopes={"insights.actions.search"}>
            <Search backUp={backUp} setSearch={setSearch} columnsSearch={columnsSearch} setIsSearch={setIsSearch} />
          </PermissionsGate>
        </Stack>
        <Stack direction="row" width="150vh" spacing={1} justifyContent="end">
          <PermissionsGate scopes={"insights.actions.sendEmail"}>
            <SendEmail fileData={filterEmail} fileName={"Transfers"} />
          </PermissionsGate>
          <PermissionsGate scopes={"insights.actions.exportDataXlsx"}>
            <ExportExcel fileType={"xlsx"} fileName={"Transfers"} data={data} columns={columns} />
          </PermissionsGate>
          <PermissionsGate scopes={"insights.actions.exportDataCsv"}>
            <ExportExcel fileType={"csv"} fileName={"Transfers"} data={data} columns={columns} />
          </PermissionsGate>
        </Stack>
      </Stack>
      <Card
        sx={{
          margin: "1vh 0",
          padding: "4vh 0",
          backgroundColor: "#EFF1F399",
        }}
      >
        <PermissionsGate scopes={"insights.actions.viewFilter"}>
          <FilterDetails
            data={data}
            dataFilter={dataFilter}
            dateDefaultFilter={dateDefaultFilter}
            merchant={merchant}
            selected={selected}
            stores={stores}
          ></FilterDetails>
        </PermissionsGate>
      </Card>
    </Stack>
  );
}

Buttons.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  backUp: PropTypes.array.isRequired,
  setSearch: PropTypes.func.isRequired,
  columnsSearch: PropTypes.array.isRequired,
  setIsSearch: PropTypes.func.isRequired,
  dataFilter: PropTypes.func.isRequired,
  dateDefaultFilter: PropTypes.array.isRequired,
  filterEmail: PropTypes.object.isRequired,
  stores: PropTypes.object,
};
