import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Button from "@mui/material/Button";

import GenericBackdrop from "../../../utils/GenericBackdrop";
import { SnackBar } from "../../utils/alert";

import AggridTable from "../../aggrid/aggridTable";
import { DynamicAction } from "../../aggrid/dynamicAction";
import { axiosClientKym } from "../../commons/axiosClient";
import { ColumnsGenerator } from "../columnGenerator";

export default function Operations() {
  const [t] = useTranslation("global");
  const fileType = "csv";
  const csv = "csv";
  const validType = [csv];
  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);
  const getUrl = () => `merchants/pending-validation`;
  const [reload, setReload] = useState(true);
  const flex = {
    display: "flex",
    alignItems: "left",
    justifyContent: "left",
  };
  const columns = ColumnsGenerator([
    {
      headerName: t("Operations.AmlValidations.Merchant"),
      field: "Merchant",
      minWidth: 100,
      maxWidth: 140,
      cellStyle: () => flex,
    },
    {
      headerName: t("Operations.AmlValidations.Name"),
      field: "Name",
      minWidth: 180,
      cellStyle: () => flex,
    },
    {
      headerName: t("Operations.AmlValidations.Birthdate"),
      field: "BirthDate",
      minWidth: 120,
      cellStyle: () => flex,
    },
    {
      headerName: t("Operations.AmlValidations.TypePerson"),
      field: "TypePerson",
      minWidth: 170,
    },
    {
      headerName: t("Operations.AmlValidations.Nacionality"),
      field: "Nationality",
      minWidth: 150,
    },
    {
      headerName: t("Operations.BankAccountValidations.Status.Header"),
      field: "Status",
      cellRendererFramework: DynamicAction,
      cellRendererParams: { icon: "BankAccountStatus" },
      minWidth: 155,
    },
  ]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result.replace(`data:text/${validType};base64,`, ""));
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const importFile = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        handleLoaderOpen();
        const base64 = await convertBase64(file);
        await axiosClientKym
          .post(
            "validate_merchant_users",
            {
              file: base64,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            setReload(true);
            SnackBar(response.data, "success");
          })
          .catch((_) => {
            SnackBar(t("Download.Message.Error"), "error");
          })
          .finally((_) => {
            event.target.value = null;
          });
      }
    } catch (_) {
      SnackBar(t("Download.Message.Error"), "error");
    } finally {
      handleLoaderClose();
    }
  };
  return (
    <div style={{ textAlign: "right" }}>
      <GenericBackdrop open={loader} />
      <Button
        component="label"
        variant="outlined"
        startIcon={<ArrowUpwardIcon />}
        onChange={(e) => importFile(e)}
        className="action-button-outlined-secondary"
      >
        {t("Buttons.Import")}
        <input hidden accept={"." + fileType} multiple type="file" data-testid="file-input" />
      </Button>
      <AggridTable
        columnDefs={columns}
        pagination={true}
        height={600}
        url={getUrl()}
        width={"100%"}
        kyx={true}
        reload={reload}
        setReload={setReload}
      />
    </div>
  );
}
