import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PersonIcon from "@mui/icons-material/Person";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import GenericBackdrop from "../../utils/GenericBackdrop";
import { getCountryByDomain } from "../../utils/validateExpression";
import { Alert, Confirm, SnackBar } from "../utils/alert";
import { getAuthTokenAzure } from "../utils/localStorage";
import { validEmail } from "../utils/validateExpression";

import DetailOwner from "./components/detailOwner";
import RegisterPaymentBtn from "./components/registerPaymentBtn";

import AggridTable from "../aggrid/aggridTable";
import { DynamicAction } from "../aggrid/dynamicAction";
import { axiosClient, axiosClientKym } from "../commons/axiosClient";
import PermissionsGate from "../commons/PermissionsGate";
import CashOut from "../operations/cash_out/cash_out";
import QrGenerate from "../qr/qrGenerate";

const Tag = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#708195",
  boxShadow: "none",
}));

const Content = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#404A56",
  fontWeight: "bold",
  boxShadow: "none",
}));

const OwnerDetail = ({ client_id }) => {
  const [t] = useTranslation("global");
  const azureToken = getAuthTokenAzure();

  const [detailOwner, setDetailOwner] = useState({});
  const [urlImage, setUrlImage] = useState("");
  const [isPDF, setIsPDF] = useState(false);
  const [openImage, setOpenImage] = React.useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [value, setValue] = useState(0);
  const [render, setRender] = useState("Profile");
  const [screen, setScreen] = useState("stores");
  const [key, setKey] = useState("");
  const [name, setName] = useState("");
  const [msisdn, setMsisdn] = useState("");
  const [keyDocument, setKeyDocument] = useState("");
  const [valueRadio, setValueRadio] = useState(null);
  const [optionReject, setOptionReject] = useState(null);
  const [textReason, setTextReason] = useState(null);
  const [typeDocument, setTypeDocument] = useState("Document");
  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleOpen = () => setOpenImage(true);
  const handleClose = () => setOpenImage(false);
  const handleOpenOptions = () => setOpenOptions(true);
  const handleCloseOptions = () => setOpenOptions(false);
  const axiosPrivate = useAxiosPrivate();
  const arrTabs = [];

  const columnsStore = [
    {
      headerName: t("Owners.Merchant"),
      field: "store_name",
      flex: 1,
      cellClass: "ag-cell-left",
    },
    {
      headerName: t("Owners.MerchantId"),
      field: "store_key",
      flex: 1,
      cellClass: "ag-cell-left",
    },
    {
      headerName: "Actions",
      cellRendererFramework: DynamicAction,
      cellRendererParams: {
        ViewQR: ViewQR,
        ViewDetail: ViewDetail,
        icon: "StoresDetail",
      },
      flex: 1,
      cellClass: "ag-cell-right",
      headerClass: "ag-right-aligned-header",
    },
  ];

  const columnsPartners = [
    {
      headerName: t("Owners.Partner.Name"),
      field: "name",
      flex: 1,
      cellClass: "ag-cell-left",
    },
    {
      headerName: t("Owners.Partner.LastName"),
      field: "lastname",
      flex: 1,
      cellClass: "ag-cell-left",
    },
    {
      headerName: t("Owners.Partner.Cedule"),
      field: "cedula",
      flex: 1,
      cellClass: "ag-cell-left",
    },
  ];

  function ViewQR(props) {
    setName(props.data.store_name);
    setKey(props.data.store_key);
    setMsisdn(props.data.msisdn);
    setScreen("qr");
  }

  function ViewDetail(_) {
    setScreen("detail");
  }

  const screenQr = () => {
    return (
      <Fragment>
        <QrGenerate
          url={"https://tigomoney.com/qr/pay"}
          name={`${name} | ${detailOwner.business_name}`}
          id_number={msisdn}
          origin="merchant-payment"
          amount={0}
          idSucursal={key}
          alg="HS256"
          account={msisdn}
          reason=""
        ></QrGenerate>
        {btnBack()}
      </Fragment>
    );
  };

  const screenDetail = () => {
    return (
      <Fragment>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            paddingBottom: "20px",
            fontWeight: 700,
            fontSize: "18px",
            color: "#404A56",
            textAlign: "initial",
          }}
        >
          {t("QrGenerate.DetailStore")}
        </Typography>
        <Grid style={{ paddingTop: "10px" }} container direction="column">
          <Grid style={{ paddingBottom: "50px" }} item xs={6}>
            <Card
              style={{
                textAlign: "initial",
                borderRadius: "16px",
                boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
              }}
            >
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Grid container direction="column">
                    <Typography
                      variant="h5"
                      gutterBottom
                      component="div"
                      sx={{
                        paddingTop: "20px",
                        marginLeft: "24px",
                        fontWeight: 700,
                        fontSize: "24px",
                        color: "#404A56",
                      }}
                    >
                      {`${name} | ${detailOwner.business_name}`}
                    </Typography>
                    <Typography
                      variant="subTitle18_700"
                      gutterBottom
                      component="div"
                      sx={{
                        paddingTop: "5px",
                        paddingBottom: "10px",
                        marginLeft: "24px",
                      }}
                    >
                      {key}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    textAlign: "right",
                    paddingRight: "20px",
                    paddingTop: "35px",
                  }}
                ></Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {btnBack()}
      </Fragment>
    );
  };

  const btnBack = () => {
    return (
      <div
        style={{
          textAlign: "right",
          marginBlockEnd: "30px",
          marginTop: "30px",
        }}
      >
        <Button
          data-testid="button-back"
          variant="outlined"
          style={{
            background: "white",
            color: "#404A56",
            textTransform: "none",
            minWidth: "139px",
            borderColor: "#FFC10E",
            height: "48px",
          }}
          onClick={() => setScreen("stores")}
          className="action-button-outlined"
        >
          {t("Buttons.Back")}
        </Button>
      </div>
    );
  };

  const statementUrl = (type) => {
    let p_key = "";
    let view = "view";
    let download = "download";
    let isView = view === type;
    let isDownload = download === type;
    const url = "user/filesDownload";

    p_key = detailOwner.statement.key;
    axiosClientKym.defaults.headers["Authorization"] = azureToken;

    axiosClientKym
      .get(url, {
        params: {
          key: p_key,
          isView: isView,
          isDownload: isDownload,
        },
      })
      .then((response) => {
        setUrlImage(response.data);
        if (isView) {
          handleOpen();
        } else {
          window.open(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function updateStatusAccount(status) {
    let urlAccount = `withdrawals/bank-accounts/${detailOwner.account_id}/status`;

    axiosPrivate
      .patch(urlAccount, JSON.stringify({ status: status }))
      .then((_) => {
        setDetailOwner({ ...detailOwner, status: status });
        SnackBar(t("Owners.Message.AccountStatus"), "success");
      })
      .catch((_) => {
        SnackBar(t("General.Message.ErrorService"), "error");
      });
  }

  const [showSwitchOwner, setShowSwitchOwner] = useState(false);
  const [ownerActive, setOwnerActive] = useState(false);

  const handleChangeDisable = (event) => {
    setOwnerActive(event.target.checked);
    handleUpdateOwner(true, false);
  };

  const handleUpdateOwner = async (status = false, isEmail = false) => {
    setLoader(true);

    let urlOwner = `owners/${client_id}`;
    let paramsOwner = {};

    if (status) paramsOwner = { status: !ownerActive };
    else if (isEmail) {
      if (!validEmail.test(document.getElementById("owner-email").value))
        return SnackBar(t("Owners.Message.InvalidEmail"), "warning");

      paramsOwner = { email: document.getElementById("owner-email").value };
    }

    await axiosPrivate
      .patch(urlOwner, JSON.stringify(paramsOwner))
      .then(() => {
        if (isEmail) {
          setDetailOwner({
            ...detailOwner,
            email: document.getElementById("owner-email").value,
          });
        }

        SnackBar(status ? t("Owners.Message.OwnerStatus") : t("Owners.Message.UpdateEmail"), "success");
      })
      .catch(() => {
        if (status) setOwnerActive(!!ownerActive);

        SnackBar(t("General.Message.ErrorService"), "error");
      });

    setLoader(false);
  };

  const [showDetail, setShowDetail] = useState(false);

  const getDetailMerchant = async () => {
    setLoader(true);

    const SHOW_SWITCH_OWNER = ["LOCALHOST", "PY"];
    let url = `owners/${client_id}/details`;

    await axiosPrivate
      .get(url)
      .then((response) => {
        setShowDetail(true);
        setDetailOwner(response.data);
        setOwnerActive(response.data.is_activate);

        const country = getCountryByDomain();
        if (SHOW_SWITCH_OWNER.includes(country)) setShowSwitchOwner(true);
      })
      .catch((_) => {
        setShowDetail(false);
        return Alert(t("General.Message.ErrorService"), "error");
      });

    setLoader(false);
  };

  useEffect(() => {
    getDetailMerchant();
  }, []);

  function showOwnerStatus(isLabel = true) {
    if (isLabel) {
      if (ownerActive) return t("Owners.Message.UserEnable");
      return t("Owners.Message.UserDisable");
    } else {
      const ENABLED = "#404A56";
      const DISABLED = "#B4B7BB";

      if (ownerActive) return ENABLED;
      return DISABLED;
    }
  }

  function bankVoucher() {
    let voucher;

    if (detailOwner.statement.key !== undefined && detailOwner.statement.key !== "") {
      voucher = (
        <Fragment>
          <Grid item xs={2}>
            <Tag>{t("Owners.BankAccount.BankVoucher")}</Tag>
          </Grid>
          <Grid item xs={7}>
            <Content>
              <VisibilityIcon
                className="Cursor"
                data-testid="VisibilityIcon"
                sx={{ marginRight: 2 }}
                onClick={() => statementUrl("view")}
              />
              <PermissionsGate scopes={"merchants.actions.DownloadBankFile"}>
                <CloudDownloadIcon
                  data-testid="CloudDownloadIcon"
                  className="Cursor"
                  onClick={() => statementUrl("download")}
                />
              </PermissionsGate>
            </Content>
          </Grid>
        </Fragment>
      );
    } else {
      voucher = <Grid item xs={9}></Grid>;
    }
    return voucher;
  }
  function showBankAccount(detail) {
    if (detail.account !== undefined && detail.account !== null) {
      return (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{ borderStyle: "none" }} className={"card-grid-padding"}>
            {Object.entries(detail.account).map((item, index) => (
              <Fragment key={index}>
                <Grid item xs={2}>
                  <Tag>{t(`Owners.BankAccount.${item[0]}`)}</Tag>
                </Grid>
                <Grid item xs={10}>
                  <Content>{item[1]}</Content>
                </Grid>
              </Fragment>
            ))}
            {bankVoucher()}

            <Grid item xs={3} xsOffset={9}>
              <Box alignItems="center">
                {detail.status !== undefined && detail.status !== null && detail.status === "pending" ? (
                  <Fragment>
                    <PermissionsGate scopes={"merchants.actions.AcceptBakAccount"}>
                      <Button
                        variant="contained"
                        startIcon={<DoneIcon />}
                        color="success"
                        sx={{ marginRight: 2 }}
                        onClick={async () => {
                          let isConfirm = await Confirm("", t("Owners.Message.AccountStatusAccept"), "warning");
                          if (isConfirm) updateStatusAccount("accepted");
                        }}
                      >
                        {t("Owners.BankAccount.Accept")}
                      </Button>
                    </PermissionsGate>
                    <PermissionsGate scopes={"merchants.actions.RejectBakAccount"}>
                      <Button
                        variant="contained"
                        startIcon={<ClearIcon />}
                        color="error"
                        onClick={async () => {
                          if (await Confirm("", t("Owners.Message.AccountStatusReject"), "warning"))
                            updateStatusAccount("rejected");
                        }}
                      >
                        {t("Owners.BankAccount.Reject")}
                      </Button>
                    </PermissionsGate>
                  </Fragment>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      );
    } else {
      return (
        <div style={{ height: "50vh" }}>
          <div
            style={{
              position: "relative",
              left: "80%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {t("General.Message.NoData")}
          </div>
        </div>
      );
    }
  }

  function showPartner(data) {
    let boxArray = [];
    for (const aField in data) {
      boxArray.push(
        <Grid item xs={12} sx={{ borderStyle: "none", marginLeft: "50px" }}>
          <Typography
            variant="subtitle1"
            style={{
              fontFamily: "Roboto",
              fontSize: "24px",
              fontWeight: "700",
              margin: "20px 0",
              lineHeight: "18px",
            }}
          >
            <div
              style={{
                width: "32px",
                height: "32px",
                background: "#FFC70D",
                borderRadius: 100,
                color: "white",
                padding: "4px",
                display: "inline-block",
                marginRight: "12px",
              }}
            >
              <StoreMallDirectoryOutlinedIcon></StoreMallDirectoryOutlinedIcon>
            </div>
            {t("Owners.Partner." + aField)}
          </Typography>

          <AggridTable
            columnDefs={columnsPartners}
            pagination={false}
            params={""}
            upperCase={false}
            height={250}
            width={"60%"}
            rowdata={data[aField]}
            showsearch={false}
            showShadow={false}
            styleGrid={"ligth"}
          />
        </Grid>
      );
    }
    return boxArray;
  }

  const fnGate = (tabName, _) => {
    arrTabs.push(tabName);
    let label;
    let Weight;
    let color;
    switch (tabName) {
      case "Profile":
        label = "Owners.Profile.";
        break;
      case "BankAccount":
        label = "Owners.BankAccount.";
        break;
      case "Branches":
        label = "Owners.Branches.";
        break;
      case "Partner":
        label = "Owners.Partner.";
        break;
      case "Document":
        label = "Owners.Document.";
        break;
      case "Contracts":
        label = "Owners.Contracts.";
        break;
      case "Transfers":
        label = "Owners.Transfers.";
        break;
      case "BtnPayment":
        label = "Owners.BtnPayment.";
        break;
      default:
        break;
    }
    let scope =
      label + tabName.charAt(0).toLowerCase() + tabName.slice(1) + (tabName.endsWith("s") ? "" : "s") + ".actions.view";

    if (render == tabName) {
      Weight = 500;
      color = "#404A56";
    } else {
      Weight = 400;
      color = "#4C5866";
    }

    return (
      <PermissionsGate scopes={scope}>
        <Tab
          label={t(label + tabName)}
          data-testid={"tab-" + tabName}
          onClick={() => handleTabs(tabName)}
          style={{
            minWidth: "172px",
            fontFamily: "Roboto",
            fontSize: "18px",
            color: color,
            opacity: 100,
            fontWeight: Weight,
          }}
        />
      </PermissionsGate>
    );
  };

  const handleTabs = (tabName) => {
    setRender(tabName);
    const index = arrTabs.indexOf(tabName);
    setValue(index);
  };

  const downloadFile = (key, type) => {
    let p_key = "";
    let view = "view";
    let download = "download";
    let isView = view === type;
    let isDownload = download === type;
    const url = "user/filesDownload";

    p_key = key;
    axiosClientKym.defaults.headers["Authorization"] = azureToken;
    axiosClientKym
      .get(url, {
        params: {
          bucket: "",
          key: p_key,
          isView: isView,
          isDownload: isDownload,
        },
      })
      .then((response) => {
        setUrlImage(response.data);
        if (isView) {
          if (key.indexOf(".pdf") != -1) {
            setIsPDF(true);
          } else {
            setIsPDF(false);
          }
          handleOpen();
        } else {
          window.open(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadFromMerchant = (k, t) => {
    let p_key = "";
    let view = "view";
    let download = "download";
    let isView = view === t;
    let toDownload = download === t;
    const url = "merchants/files";
    p_key = k;
    axiosPrivate
      .get(url, {
        params: {
          bucket: "",
          key: p_key,
          isView: isView,
          toDownload: toDownload,
        },
      })
      .then((res) => {
        setUrlImage(res.data);
        if (isView) {
          handleOpen();
        } else {
          window.open(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getKeyDocument = (key) => {
    setKeyDocument(key);
    handleOpenOptions();
  };

  const handleChangeReject = (event) => {
    setOptionReject(event.target.value);
  };

  const buildDocuments = (docs) => {
    let gridArray = [];
    let count = 0;
    if (docs.Documents.documents_list !== null && docs.Documents.documents_list) {
      Object.keys(docs.Documents.documents_list).forEach(function (key) {
        renderDocsValidate(key, docs, count, gridArray, "Document");
        count++;
      });
    } else {
      gridArray.push(
        <div style={{ height: "50vh" }}>
          <div
            style={{
              position: "relative",
              left: "80%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {t("General.Message.NoData")}
          </div>
        </div>
      );
    }
    return gridArray;
  };

  const renderDocsValidate = (key, docs, count, gridArray, type) => {
    let typeDoc = type == "Document" ? docs.Documents.documents_list[key] : docs.Contract.contract;
    let key_document = key;
    let status = typeDoc.status.toLowerCase();
    let icon;
    let classaContent;
    let classNumber;

    if (status === "pending") {
      icon = <div>{count}</div>;
      classaContent = "box-principal-document";
      classNumber = "number-box";
    } else if (status === "rejected") {
      icon = (
        <div>
          <ErrorOutlineIcon />
        </div>
      );
      classaContent = "box-principal-document rejected";
      classNumber = "number-box rejected";
    } else {
      icon = (
        <div>
          <CheckCircleOutlineIcon />
        </div>
      );
      classaContent = "box-principal-document validate";
      classNumber = "number-box validate";
    }
    gridArray.push(
      <Box className={classaContent} key={count}>
        <Box className={classNumber}>{icon}</Box>
        <Box style={{ fontFamily: "Roboto" }} className="title-box">
          <CardContent style={{ padding: "0px", boxShadow: "none" }}>
            <Card
              style={{
                padding: "0px",
                boxShadow: "none",
                background: "transparent",
              }}
            >
              {t("Owners." + type + "." + key)}
            </Card>
            {status === "rejected" && (
              <Card
                style={{
                  padding: "0px",
                  boxShadow: "none",
                  background: "transparent",
                  fontSize: "14px",
                  color: "#708195",
                  borderTop: "1px solid #CFD5DC",
                }}
              >
                {" "}
                Motivo del rechazo: {typeDoc.reasonRejected}
              </Card>
            )}
          </CardContent>
        </Box>
        <Box
          style={{
            left: "4.92%",
            right: "4.92%",
            top: "8px",
            bottom: "12.5%",
            background: "#FFFFFF",
            padding: "10px",
          }}
        >
          <CloudDownloadIcon
            data-testid={"download-icon-" + type + count}
            style={{ cursor: "pointer" }}
            onClick={() => {
              type == "Contracts"
                ? downloadFromMerchant(typeDoc.key, "download")
                : downloadFile(typeDoc.key, "download");
            }}
          />
        </Box>
        <Box
          style={{
            left: "4.92%",
            right: "4.92%",
            top: "8px",
            bottom: "12.5%",
            background: "#FFFFFF",
            padding: "10px",
          }}
        >
          <VisibilityIcon
            data-testid={"see-icon-" + type + count}
            style={{ cursor: "pointer" }}
            onClick={() => {
              type == "Contracts" ? downloadFromMerchant(typeDoc.key, "view") : downloadFile(typeDoc.key, "view");
            }}
          />
        </Box>
        {status === "pending" && (
          <Box
            className={"content-button-validate"}
            onClick={() => getKeyDocument(key_document)}
            data-testid="btn-validate"
          >
            <Box style={{ fontFamily: "Roboto" }} className={"button-validate"}>
              Validar
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const buildContracts = (docs) => {
    let gridArray = [];
    let count = 0;
    Object.keys(docs.Contract).forEach(function (key) {
      renderDocsValidate(key, docs, count, gridArray, "Contracts");
      count++;
    });
    return gridArray;
  };

  const renderiza = () => {
    // eslint-disable-next-line default-case
    switch (render) {
      case "Partner": {
        return (
          <Card style={{ borderRadius: "20px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} sx={{ borderStyle: "none" }} className={"card-grid-padding"}>
                {showPartner(detailOwner[render])}
              </Grid>
            </Box>
          </Card>
        );
      }
      case "Document": {
        typeDocument !== "Document" && setTypeDocument("Document");
        return <Card style={{ borderRadius: "20px" }}>{buildDocuments(detailOwner)}</Card>;
      }
      case "BankAccount": {
        return <Card style={{ borderRadius: "20px" }}>{showBankAccount(detailOwner)}</Card>;
      }
      case "Profile": {
        return <DetailOwner detailOwner={detailOwner[render]} translate="Owners"></DetailOwner>;
      }
      case "Transfers": {
        return <CashOut ownerId={client_id} />;
      }
      case "Branches": {
        switch (screen) {
          case "qr":
            return <div className="App">{screenQr()}</div>;
          case "detail":
            return <div className="App">{screenDetail()}</div>;
          case "stores":
            return <div className="App">{screenStores()}</div>;
          default:
            return <div className="App">{screenQr()}</div>;
        }
      }
      case "Contracts": {
        typeDocument !== "Contract" && setTypeDocument("Contract");
        return <Card style={{ borderRadius: "20px" }}>{buildContracts(detailOwner)}</Card>;
      }
      case "BtnPayment": {
        return <RegisterPaymentBtn detailOwner={detailOwner} />;
      }
    }
  };

  const screenStores = () => {
    return (
      <AggridTable
        columnDefs={columnsStore}
        pagination={true}
        params={""}
        upperCase={false}
        height={400}
        width={"100%"}
        rowdata={detailOwner.stores}
        showsearch={false}
      />
    );
  };

  const validateContract = async () => {
    let url = `signContract`;
    let account = detailOwner.email;
    axiosClientKym.defaults.headers["Authorization"] = azureToken;

    await axiosPrivate
      .patch(url, { email: account })
      .then((_) => {
        SnackBar(t("Owners.Message.AccountStatus"), "success");
      })
      .catch(async (_) => {
        let data = `{
          "Negocio": {
              "contract": {
                  "status": "pending"
              }
          }`;
        await axiosClientKym
          .put(`/user?email=${detailOwner.email}&formId=${detailOwner.Documents.form_id}`, { ...data })
          .then(() => {
            SnackBar(t("General.Message.ErrorService"), "error");
          });
      });
  };

  const validDocuments = async (type) => {
    const FORM_ID = "F0009";
    let status;
    let dataKYM;
    let dataInternal;

    if (valueRadio === null) {
      SnackBar("select a radio please");
      return;
    } else if (typeDocument === "Document") {
      if (valueRadio === false) {
        status = "rejected";
        dataKYM = `{
          "Negocio": {
            "documents": {
                "${keyDocument}": {
                  "status": "${status}",
                  "reasonRejected":"${optionReject}",
                  "otherReason":"${textReason}"
                }
            }
          }
        }`;

        dataInternal = {
          status: status,
          document_id: keyDocument,
          cause: optionReject,
        };
      } else {
        status = "Validated";
        dataKYM = `{
        "Negocio": {
          "documents": {
            "${keyDocument}": {
                "status": "${status}"
              }
          }
        }
      }`;

        dataInternal = {
          status: status,
          document_id: keyDocument,
          cause: "",
        };
      }
    }
    if (typeDocument === "Contract") {
      if (valueRadio === false) {
        status = "rejected";
        dataKYM = `{
        "Negocio": {
            "contract": {
                "status": "${status}",
                "reasonRejected":"${optionReject}",
                "otherReason":"${textReason}"
            }
        }
      }`;
      } else {
        status = "Validated";
        dataKYM = `{
        "Negocio": {
            "contract": {
                "status": "${status}"
            }
        }
      }`;
      }
    }

    dataKYM = JSON.parse(dataKYM);
    setLoader(true);
    handleCloseOptions();

    if (FORM_ID === detailOwner.Documents.form_id && typeDocument === "Document") {
      await axiosClient
        .patch(`/merchants/documents/${client_id}`, {
          ...dataInternal,
        })
        .then(async (_) => {
          await responseDetailOwner(type, status);
        })
        .catch((error) => {
          setTextReason("");
          console.log(error);
        });
    } else axiosClientKym.defaults.headers["Authorization"] = azureToken;

    await axiosClientKym
      .put(`/user?email=${detailOwner.email}&formId=${detailOwner.Documents.form_id}`, { ...dataKYM })
      .then(async (_) => {
        await responseDetailOwner(type, status);
      })
      .catch((error) => {
        setTextReason("");
        console.log(error);
      });

    setLoader(false);
  };

  const responseDetailOwner = async (type, status) => {
    let ownerCopy = Object.assign({}, detailOwner);
    setOpenOptions(false);
    setValueRadio(null);
    setTextReason("");

    if (type === "Contract") {
      ownerCopy.Contract.contract["status"] = status;
      if (status === "Validated") {
        return await validateContract();
      }
    } else {
      ownerCopy.Documents.documents_list[keyDocument]["status"] = status;
    }
    setDetailOwner(ownerCopy);
  };

  const handleChangeInput = (e) => {
    setTextReason(e.target.value);
  };

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      {showDetail ? (
        <div>
          <Card style={{ borderRadius: "10px" }}>
            <Grid container direction="row" style={{ justifyContent: "space-between" }}>
              <Grid item md={6} xs={12}>
                <Grid container direction="column">
                  <Grid item>
                    <Box display="flex" justifyContent="left" alignItems="left">
                      <CardContent
                        sx={{
                          textAlign: "left",
                          paddingBottom: "0px !important",
                          paddingTop: "0px !important",
                          width: "100%",
                        }}
                      >
                        <Grid container>
                          <Grid item>
                            <Typography variant="h3" style={{ marginTop: "6px", marginBottom: "6px" }}>
                              <Avatar
                                alt="Remy Sharp"
                                sx={{
                                  bgcolor: showOwnerStatus(false),
                                  width: 55,
                                  height: 55,
                                }}
                              >
                                <PersonIcon fontSize="40" />
                              </Avatar>
                            </Typography>
                          </Grid>
                          <Grid item sx={{ paddingLeft: "20px" }}>
                            <Typography
                              variant="subtitle1"
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "24px",
                                fontWeight: "700",
                                marginTop: "14px",
                                lineHeight: "18px",
                                color: showOwnerStatus(false),
                              }}
                            >
                              {detailOwner.business_name}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                marginTop: "8px",
                                lineHeight: "18px",
                                color: showOwnerStatus(false),
                              }}
                            >
                              {t("Owners.Profile.Merchant")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {showSwitchOwner && (
                <Grid item md={6} xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Box display="flex" justifyContent="right" alignItems="right" mt={2} mr={2}>
                        <FormControl component="fieldset">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={ownerActive}
                                onChange={handleChangeDisable}
                                inputProps={{
                                  "aria-label": "controlled",
                                  "data-testid": "checkId",
                                }}
                              />
                            }
                            label={showOwnerStatus()}
                            labelPlacement="start"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Card>
          <Box sx={{ borderBottom: 1, borderColor: "#DFE3E7" }} mt={3} mr={3} ml={0}>
            <Tabs value={value}>
              {fnGate("Profile")}
              {detailOwner["Partner"] !== null && fnGate("Partner")}
              {fnGate("Document")}
              {fnGate("BankAccount")}
              {fnGate("Branches")}
              {detailOwner["Contract"] !== null && fnGate("Contracts")}
              {detailOwner["Transfers"] !== null &&
                typeof detailOwner["Transfers"] !== "undefined" &&
                fnGate("Transfers")}
              {fnGate("BtnPayment")}
            </Tabs>
          </Box>
          <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>{renderiza()}</div>
        </div>
      ) : null}
      <Dialog fullScreen={fullScreen} fullWidth={isPDF} open={openImage} onClose={handleClose}>
        <DialogContent>
          {isPDF ? (
            <iframe
              id="PdfPreview"
              title="PDF preview"
              width="100%"
              height="500px"
              src={urlImage + "#toolbar=0&view=FitH"}
            />
          ) : (
            <img data-testid="asd" src={urlImage} alt="asd" width="100%" height="100%" />
          )}
        </DialogContent>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={openOptions} onClose={handleCloseOptions}>
        <DialogContent>
          <h4>{t(`Owners.Document.TitleModal`)}</h4>
          <h6>{t(`Owners.Document.Question`)}</h6>
          <Box style={{ width: "300px" }}>
            <Box style={{ float: "left", marginRight: "39px", width: "60px" }}>
              <input
                type="radio"
                data-testid="radio-yes"
                value="yes"
                onChange={(_) => setValueRadio(true)}
                name="validateDocument"
              />{" "}
              {t(`Owners.Document.RadioYes`)}
            </Box>
            <Box style={{ float: "left", marginRight: "39px", width: "60px" }}>
              <input
                type="radio"
                data-testid="radio-no"
                value="no"
                onChange={(_) => setValueRadio(false)}
                name="validateDocument"
              />{" "}
              {t(`Owners.Document.RadioNo`)}
            </Box>
            {valueRadio === false && (
              <Box style={{ clear: "both", width: "300px", marginTop: "40px" }}>
                <FormControl
                  sx={{
                    m: 1,
                    width: "250px",
                    borderRadius: "10px",
                    height: "35px",
                  }}
                >
                  <InputLabel id="demo-simple-select-autowidth-label">{t(`Owners.Document.ReasonReject`)}</InputLabel>
                  <Select
                    value={optionReject}
                    onChange={handleChangeReject}
                    label="Motivo de rechazo"
                    autoWidth
                    style={{ borderRadius: "20px", height: "40px" }}
                    inputProps={{ "data-testid": "reject-options" }}
                  >
                    <MenuItem value="">
                      <em></em>
                    </MenuItem>
                    <MenuItem value={t(`Owners.Document.unfocused`)}>{t(`Owners.Document.unfocused`)}</MenuItem>
                    <MenuItem value={t(`Owners.Document.darkfile`)}>{t(`Owners.Document.darkfile`)}</MenuItem>
                    <MenuItem value={t(`Owners.Document.timeout`)}>{t(`Owners.Document.timeout`)}</MenuItem>
                    <MenuItem value={t(`Owners.Document.invalid`)}>{t(`Owners.Document.invalid`)}</MenuItem>
                    <MenuItem value={t(`Owners.Document.others`)}>{t(`Owners.Document.others`)}</MenuItem>
                  </Select>
                </FormControl>
                {optionReject === t(`Owners.Document.others`) && (
                  <FormControl style={{ marginTop: "15px" }}>
                    <TextField
                      placeholder="Explica el motivo"
                      multiline
                      value={textReason}
                      data-testid={"textreason"}
                      rows={2}
                      onChange={handleChangeInput}
                      maxRows={6}
                      style={{ width: "250px" }}
                    />
                  </FormControl>
                )}
              </Box>
            )}
          </Box>
          <Box style={{ marginTop: "30px", clear: "both" }}>
            <Box
              style={{
                float: "right",
                marginRight: "39px",
                textAlign: "right",
              }}
            >
              <Button
                style={{
                  padding: "16px",
                  gap: "16px",
                  width: "129px",
                  height: "48px",
                  background: "#363853",
                  borderRadius: "16px",
                  order: "1",
                }}
                onClick={() => validDocuments(typeDocument)}
              >
                {t(`Owners.Document.ButtonAcept`)}
              </Button>
            </Box>
            <Box
              style={{
                float: "right",
                marginRight: "39px",
                textAlign: "right",
              }}
            >
              <Button onClick={handleCloseOptions}>{t(`Owners.Document.ButtonCancel`)}</Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

OwnerDetail.propTypes = {
  client_id: PropTypes.string,
};

export default OwnerDetail;
