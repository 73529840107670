import { Button } from "@mui/material";

export function FilterButton({ filterFnc, label }) {
  return (
    <Button
      data-testid="button-filter"
      style={{
        background: "#FFC10E",
        color: "#404A56",
        textTransform: "none",
        minWidth: "100px",
        height: "40px",
        marginInlineEnd: "20px",
      }}
      onClick={filterFnc}
      className="action-button"
    >
      {label}
    </Button>
  );
}
