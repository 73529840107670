import { useTranslation } from "react-i18next";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box, Button, Fade, IconButton, Modal, Stack, Typography } from "@mui/material";

const ConfirmationModal = ({
  open,
  title,
  subtitle,
  keepMounted,
  handleClose,
  confirmButtonText,
  confirmButtonHandle,
  loading,
  children,
}) => {
  const [t] = useTranslation("global");
  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition keepMounted={keepMounted}>
      <Fade in={open}>
        <Box sx={styles.box()}>
          <Stack
            direction="row"
            sx={{
              gap: "10px",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              padding: "0",
            }}
          >
            <IconButton onClick={handleClose} title="close">
              <CancelOutlinedIcon />
            </IconButton>
          </Stack>
          <Typography variant="h2" sx={{ marginBottom: "28px" }}>
            {title}
          </Typography>
          <Typography variant="subtitle1">{subtitle}</Typography>
          {children}
          <Stack
            direction="row"
            sx={{
              justifyContent: "flex-end",
              gap: "10px",
              width: "100%",
            }}
          >
            <Button variant="blank" onClick={handleClose} disabled={loading}>
              {t("Risk.Cancel")}
            </Button>
            <Button variant={loading ? "disabled" : "solid"} onClick={confirmButtonHandle} disabled={loading}>
              {confirmButtonText}
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

const styles = {
  box: () => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 773,
    bgcolor: "background.paper",
    boxshadow: 24,
    padding: "24px 32px",
    borderRadius: "16px",
  }),
};

export default ConfirmationModal;
