import { useParams } from "react-router-dom";

import ClientDetails from "../../components/backoffice/ClientDetails";
import Sesion from "../../components/commons/sesion";

export default function ClientDetailsView() {
  const { client_id } = useParams();

  return (
    <div className={"Drawer"}>
      <Sesion screen={"Backoffice"} />
      <ClientDetails clientId={client_id} />
    </div>
  );
}
