import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";

const MMDialogCustom = (props) => {
  const style = {
    position: "absolute",
    right: 8,
    top: 8,
    color: "#343C46",
  };

  const { title, onClose, open, children, actions } = props;

  return (
    <Dialog maxWidth={"sm"} open={open}>
      <DialogTitle data-testid="test-dialog-title">
        <span data-testid="test-title">{title ?? ""}</span>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} sx={style}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default MMDialogCustom;
