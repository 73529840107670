import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import CustomButton from "../../commons/backoffice/customButton";
import CustomInput from "../../commons/backoffice/customInput";

const InvoicesFilter = ({ onFilterSubmit }) => {
  const [t] = useTranslation("global");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const filter = {
      dateFrom,
      dateTo,
    };
    if (onFilterSubmit) onFilterSubmit(filter);
  };

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "none",
        backgroundColor: "#EEF0F2",
        borderRadius: "16px",
        display: "flex",
        padding: "16px 32px",
        alignItems: "end",
        justifyContent: "start",
        gap: "24px",
      }}
    >
      <div>
        <CustomInput
          onChange={(newValue) => setDateFrom(newValue)}
          type="date"
          title={t("Backoffice.InvoicesFilter.Title")}
          placeholder={t("Backoffice.InvoicesFilter.From")}
        />
      </div>
      <div>
        <CustomInput
          onChange={(newValue) => setDateTo(newValue)}
          type="date"
          placeholder={t("Backoffice.InvoicesFilter.To")}
        />
      </div>
      <CustomButton width="150px" onClick={handleSubmit}>
        Buscar
      </CustomButton>
    </Box>
  );
};

export default InvoicesFilter;
