import * as React from "react";
import { useTranslation } from "react-i18next";

import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import noShipmentHistory from "../../img/noShipmentHistory.svg";

const styles = {
  iconSize: {
    width: 66,
    height: 66,
  },
};
const NoShipmentHistory = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <Grid data-testid="NoshipmentId" container justifyContent="center" paddingTop={"10px"}>
        <Grid style={{ paddingLeft: 30 }}>
          <Avatar src={"../" + noShipmentHistory} sx={styles.iconSize} />
        </Grid>
        <Grid style={{ paddingTop: 10, paddingLeft: 15 }}>
          <Typography fontSize={"26px"}>{t("Risk.NoShipmentHistory")}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default NoShipmentHistory;
