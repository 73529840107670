import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { axiosClient } from "../../commons/axiosClient";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
};
export default forwardRef((props, ref) => {
  const [t] = useTranslation("global");
  const [selectedValue, setSelectedValue] = useState(props.value);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let fileInput = React.createRef();

  let InitialFormState = {};
  try {
    InitialFormState = JSON.parse(props.value);
  } catch (error) {
    InitialFormState = { host: "", port: "", user: "", pem: "" };
  }
  const [formData, setFormData] = useState(InitialFormState);

  function inputHandler(e) {
    setSelectedValue(e.target.value);
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return selectedValue;
      },
    };
  });

  const handleInputChange = (ev) => {
    setFormData({
      ...formData,
      [ev.target.name]: ev.target.value,
    });
  };
  const onSubmitHandle = (ev) => {
    ev.preventDefault();
    setSelectedValue(JSON.stringify(formData));
    const form = new FormData();
    if (fileInput.current.files[0] !== undefined) {
      let filename = fileInput.current.files[0].name;
      form.append("file", fileInput.current.files[0], filename);
      Object.assign(axiosClient.defaults.headers, {
        "Content-Type": "multipart/form-data",
      });
      axiosClient
        .put("/file-upload-pem", form)
        .then(() => {
          setSelectedValue(
            JSON.stringify({
              ...formData,
              pem: filename,
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
    handleClose();
  };
  return (
    <>
      <input
        data-testid="info-value"
        type="text"
        className="ag-text-field-input"
        onChange={inputHandler}
        readOnly={true}
        value={selectedValue}
        placeholder={"Enter " + props.column.colId}
      />
      <SettingsIcon onClick={handleOpen} data-testid="config" />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container component="main" maxWidth="xs" sx={style}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              {t("Integrator.Cron.Settings_info.Title")}
            </Typography>
            <Box component="form" noValidate onSubmit={onSubmitHandle} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="host"
                    required
                    fullWidth
                    id="host"
                    label={t("Integrator.Cron.Settings_info.Host")}
                    autoFocus
                    value={formData.host}
                    onChange={handleInputChange}
                    data-testid="host-field"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="port"
                    label={t("Integrator.Cron.Settings_info.Port")}
                    name="port"
                    value={formData.port}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="user"
                    label={t("Integrator.Cron.Settings_info.User")}
                    name="user"
                    value={formData.user}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" component="label" color="primary">
                    {" "}
                    <AddIcon /> {t("Integrator.Cron.Settings_info.File")}
                    <input
                      data-testid="file-field"
                      type="file"
                      accept=".pem"
                      hidden
                      name="pem"
                      id="pem"
                      ref={fileInput}
                      onChange={handleInputChange}
                    />
                  </Button>
                  {formData.pem}
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    {t("Integrator.Cron.Settings_info.Update")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Modal>
    </>
  );
});
