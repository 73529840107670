import React, { useState } from "react";

import CardContent from "@mui/material/CardContent";
import { ThemeProvider } from "@mui/material/styles";

import Audience from "../../components/batchs/audience";
import CreateBatchHeader from "../../components/batchs/createBatchHeader";
import RecordsNumbers from "../../components/batchs/recordsnumbers";
import Sesion from "../../components/commons/sesion";
import ThemeRisks from "../../themes/ThemeRisks";

export default function CreateBatch() {
  const [shipmentNumber, setShipmentNumber] = useState("");
  const [aviableClients, setAviableClients] = useState(0);
  const [ClientsUsedBatches, setClientsUsedBatches] = useState(0);
  const [initialAviableClients, setInitialAviableClients] = useState(0);
  const [initialRisks, setInitialRisks] = useState([]);

  const [classes, setClasses] = useState([]);
  const [riskTypes, setRiskTypes] = useState([]);

  const [bases, setBases] = useState([]);
  const [baseSelected, setBaseSelected] = useState("");
  const [baseSelectedName, setBaseSelectedName] = useState("");

  const [loading, setLoading] = useState(false);

  const [audiences, setAudiences] = useState([
    {
      classtxt: "",
      inputClass: "",
      id_class: "",
      maxAviableClients: "--",
      amount_clients: "",
      risk_percentages: [],
    },
  ]);

  return (
    <ThemeProvider theme={ThemeRisks}>
      <div className={"Drawer"}>
        <CardContent>
          <Sesion screen={"CreateBatch"} subtitle={"BatchConfig"} />
        </CardContent>
        <CardContent>
          <RecordsNumbers
            ClientsUsedBatches={ClientsUsedBatches}
            AviableClients={aviableClients}
            InitialRisks={initialRisks}
          />
        </CardContent>
        <CardContent>
          <CreateBatchHeader
            shipmentNumber={shipmentNumber}
            audiences={audiences}
            aviableClients={aviableClients}
            baseSelectedName={baseSelectedName}
            baseSelected={baseSelected}
            bases={bases}
            initialAviableClients={initialAviableClients}
            setBaseSelectedName={setBaseSelectedName}
            setShipmentNumber={setShipmentNumber}
            setAviableClients={setAviableClients}
            setBaseSelected={setBaseSelected}
            setInitialAviableClients={setInitialAviableClients}
            setAudiences={setAudiences}
            setClientsUsedBatches={setClientsUsedBatches}
            setInitialRisks={setInitialRisks}
            endpoint={"/batch/info"}
            baseendpoint={""}
            setBases={setBases}
            setRiskTypes={setRiskTypes}
            setClasses={setClasses}
            setLoading={setLoading}
          />
        </CardContent>
        <CardContent>
          {audiences.map((audience, index) => (
            <Audience
              audience={audience}
              index={index}
              key={index}
              classes={classes}
              audiences={audiences}
              setAudiences={setAudiences}
              disabled={shipmentNumber === "" || initialAviableClients === 0}
              aviableClients={aviableClients}
              setAviableClients={setAviableClients}
              clientsUsedBatches={ClientsUsedBatches}
              setClientsUsedBatches={setClientsUsedBatches}
              shipmentNumber={shipmentNumber}
              initialAviableClients={initialAviableClients}
              riskTypes={riskTypes}
              setRiskTypes={setRiskTypes}
              initialRisks={initialRisks}
              baseSelected={baseSelected}
              setBaseSelected={setBaseSelected}
              setBaseSelectedName={setBaseSelectedName}
              setInitialRisks={setInitialRisks}
              setInitialAviableClients={setInitialAviableClients}
              setShipmentNumber={setShipmentNumber}
              endpoint={"/batch"}
              loading={loading}
            />
          ))}
        </CardContent>
      </div>
    </ThemeProvider>
  );
}
