import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Box,
  CardContent,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableCell,
  Tooltip,
} from "@mui/material";

import BasicTable from "../../commons/basicTable";
import ModalStatusMM, { TypeModals } from "../../commons/modalStatusMM";
import MMSearchBar from "../components/MMSearchBar";
import { getKyxMicromerchants } from "../data/repositories/micromerchant_repository";

const Blocked = (props) => {
  const { country } = props;
  const [t] = useTranslation("global");
  const [blockLoading, setBlockLoading] = useState(true);
  const [blockLevel, setBlockLevel] = useState("");
  const [blockData, setBlockData] = useState([]);
  const [blockDataBackUp, setBlockDataBackUp] = useState([]);
  const [blockColumns, setBlockColumns] = useState([]);
  const [blockRowsPerPage, setBlockRowsPerPage] = useState(50);
  const [blockPage, setBlockPage] = useState(0);
  const [blockColumnSort, setBlockColumnSort] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [mmSelected, setMmSelected] = useState([]);

  const [totalBlocked, setTotalBlocked] = useState(0);
  const [filterBlocked, setFilterBlocked] = useState({});
  const [blockedValueSort, setBlockedValueSort] = useState({});

  const showTable = () => setBlockLoading(false);
  const hideTable = () => setBlockLoading(true);

  // Localstorage
  const mmTable = "Micromerchant";
  const mmDetailStorage = `${mmTable}Detail`;
  const mmRowsPerPageStorage = `${mmTable}-rowsPerPage`;
  const mmPageStorage = `${mmTable}-page`;
  const mmLevelStorage = `${mmTable}-level`;
  const mmSearchStorage = `${mmTable}-search`;
  const mmSortStorage = `${mmTable}-sort`;

  useEffect(() => {
    if (localStorage.getItem(mmDetailStorage) != null) {
      buildlocalStorage("set");
    } else {
      buildlocalStorage("remove");
    }
    getDataBlocked()
      .then(() => console.log)
      .finally(() => console.log);
  }, []);

  const buildlocalStorage = (action) => {
    let params = {};
    if (action == "set") {
      if (localStorage.getItem(mmRowsPerPageStorage) != null) {
        setBlockRowsPerPage(parseInt(localStorage.getItem(mmRowsPerPageStorage)));
      }
      if (localStorage.getItem(mmPageStorage) != null) {
        setBlockPage(parseInt(localStorage.getItem(mmPageStorage)));
      }
      if (localStorage.getItem(mmLevelStorage) != null) {
        setBlockLevel(localStorage.getItem(mmLevelStorage));
        if (localStorage.getItem(mmLevelStorage) != "") {
          params = { ...params, level: localStorage.getItem(mmLevelStorage) };
        }
      }
      if (localStorage.getItem(mmSearchStorage) != null) {
        params = {
          ...params,
          search_field: localStorage.getItem(mmSearchStorage),
        };
      }
      if (localStorage.getItem(mmSortStorage) != null) {
        setBlockedValueSort(JSON.parse(localStorage.getItem(mmSortStorage)));
      }
    } else {
      localStorage.removeItem(mmRowsPerPageStorage);
      localStorage.removeItem(mmPageStorage);
      localStorage.removeItem(mmLevelStorage);
      localStorage.removeItem(mmSearchStorage);
      localStorage.removeItem(mmSortStorage);
    }
    Object.assign(filterBlocked, params);
  };

  const getDataBlocked = async () => {
    hideTable();

    let blockedSearch = filterBlocked;
    let blockedPage = blockPage + 1;
    let blockedRowPerPage = blockRowsPerPage;
    let sortBlocked = blockedValueSort;

    if (localStorage.getItem(mmPageStorage) != null) {
      blockedPage = parseInt(localStorage.getItem(mmPageStorage)) + 1;
    }

    if (localStorage.getItem(mmRowsPerPageStorage) != null) {
      blockedRowPerPage = parseInt(localStorage.getItem(mmRowsPerPageStorage));
    }

    if (localStorage.getItem(mmSortStorage) != null) {
      sortBlocked = JSON.parse(localStorage.getItem(mmSortStorage));
    }

    let blockTranslation = "Micromerchants.Locks.Columns.";
    let block_columns = [];

    const res = await getKyxMicromerchants({
      country: country,
      page: blockedPage,
      limit: blockedRowPerPage,
      search: blockedSearch,
      sort: sortBlocked,
      status: "blocked",
    });

    for (let col of Object.values(res.data.columns)) {
      block_columns.push(blockTranslation.concat(col));
    }

    setTotalBlocked(res.data.total_micromerchant);
    setBlockData(res.data.results);
    setBlockDataBackUp(res.data.results);
    setBlockColumns(block_columns);
    setBlockColumnSort(res.data.sort);
    setFilterBlocked(blockedSearch);
    setBlockedValueSort(sortBlocked);
    showTable();
  };

  const handleChangeModal = (type, row) => {
    setMmSelected(row);
    setTypeModal(type);
    setShowModal(true);
  };

  const changeDataTable = useCallback(() => {}, []);

  const buildActionsBlock = useCallback((row) => {
    return (
      <Fragment>
        <Fragment>
          <TableCell
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <IconButton
              data-testid="btn-icon-un-blocked-mm"
              onClick={() => handleChangeModal(TypeModals.UnBlocked, row)}
            >
              <Tooltip title={"Desbloquear"}>
                <LockOpenIcon className="Cursor" />
              </Tooltip>
            </IconButton>
            {props.eyedIcon(row.client_id, row)}
          </TableCell>
        </Fragment>
      </Fragment>
    );
  }, []);

  const handleChange = useCallback(async (e) => {
    const valueLevel = e.target.value;
    localStorage.setItem(mmLevelStorage, e.target.value);
    localStorage.setItem(mmPageStorage, 0);
    setBlockLevel(e.target.value);
    setBlockPage(0);

    delete filterBlocked["level"];
    const addFilter = valueLevel !== "" ? Object.assign({}, { ...filterBlocked, level: valueLevel }) : filterBlocked;
    Object.assign(filterBlocked, addFilter);
    await getDataBlocked();
  }, []);

  const handleSearchBlocked = async (value) => {
    const filter = {
      ...filterBlocked,
      search_field: value,
    };

    Object.assign(filterBlocked, filter);
    await getDataBlocked();
  };

  return (
    <Fragment>
      <ModalStatusMM
        row={mmSelected}
        setMmSelected={setMmSelected}
        typeModal={typeModal}
        openModal={showModal}
        setShowModal={setShowModal}
        data={blockData}
        setData={setBlockData}
        dataBackUp={blockDataBackUp}
        setBackUp={setBlockDataBackUp}
        setPage={setBlockPage}
        country={country}
        changeDataTable={changeDataTable}
      />
      <Stack width="100%" mb={2} direction="row" justifyContent="start">
        <Stack direction="row" width="30%" mb={2}>
          <MMSearchBar type={mmTable} placeholder={"Micromerchants.Locks.Search"} onSearch={handleSearchBlocked} />
        </Stack>
        <Stack width="15%" mb={2} ml={2}>
          <FormControl>
            <InputLabel id="select-change-level">{t("Micromerchants.Locks.Filters.Level.SelectLevel")}</InputLabel>
            <Select
              labelId="select-change-level-lock"
              id="select-change-level-lock-helper"
              value={blockLevel}
              label="blocklevel"
              onChange={handleChange}
              data-testid="select-change-level-lock-test"
              sx={{ paddingBottom: "0px", borderRadius: "16px" }}
            >
              <MenuItem value="">
                <em>{t("Micromerchants.Locks.Filters.Level.SelectOption")}</em>
              </MenuItem>
              <MenuItem value={"5000"}>5000</MenuItem>
              <MenuItem value={"6000"}>6000</MenuItem>
              <MenuItem value={"otros"}>Otros</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Fragment>
        {blockLoading ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <div>Loading ...</div>
            <CardContent data-testid={"loading-card-micromerchant-lock"}>
              <CircularProgress data-testid={"loading-circular-progress-micromerchant-lock"} color="warning" />
            </CardContent>
          </Box>
        ) : (
          <BasicTable
            dataTable={blockData}
            dataColumns={blockColumns}
            action={buildActionsBlock}
            condition={["is_active"]}
            dynamicAction={[{ field: "user_status", icon: "MicromerchantsUserStatus" }]}
            rowsPerPageOpt={[]}
            tableType={mmTable}
            rowsPerPageValue={
              localStorage.getItem(mmRowsPerPageStorage) != null
                ? parseInt(localStorage.getItem(mmRowsPerPageStorage))
                : 50
            }
            pageValue={localStorage.getItem(mmPageStorage) !== null ? parseInt(localStorage.getItem(mmPageStorage)) : 0}
            columnsSort={blockColumnSort}
            isTxtRowsPerPagHidden={false}
            totalData={totalBlocked}
            getData={getDataBlocked}
          />
        )}
      </Fragment>
    </Fragment>
  );
};

export default Blocked;
