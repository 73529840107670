import useLoans from "../../../hooks/backoffice/useLoans";

import FinancialProductFilter from "./financialProductFilter";

import LoansList from "../../commons/backoffice/loansList";

const FinancialProduct = ({ clientId }) => {
  const { isLoading, loans, totalItems, currentPage, onFilterChange, onPageChange } = useLoans({ clientId });

  const handleFilterSubmit = (filter) => {
    onFilterChange(filter);
  };

  return (
    <div>
      <FinancialProductFilter onFilterSubmit={handleFilterSubmit} />
      <div style={{ marginTop: "36px" }}>
        <LoansList
          onPageChange={(page) => onPageChange(page)}
          isLoading={isLoading}
          loans={loans}
          loanId={loans}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default FinancialProduct;
