import { useState } from "react";

import useSWR from "swr";

import ApisEnum from "../../utils/enums/apis.enum";

import useAxiosPrivate from "../useAxiosPrivate";

const useLoans = ({ clientId, limit = 5, pag = 0 }) => {
  const [page, setPage] = useState(pag);
  const [filter, setFilter] = useState({
    loanId: "",
    loanType: "",
    loanStatus: "",
    dateFrom: "",
    dateTo: "",
  });

  const axiosClient = useAxiosPrivate(ApisEnum.Backoffice);

  const loansFetcher = async (url) => {
    const { data } = await axiosClient.get(url);
    return data;
  };

  const { isLoading, data } = useSWR(
    `/credits_use?clientId=${clientId}&page=${page + 1}&limit=${limit}&loanId=${filter.loanId}&loanType=${
      filter.loanType
    }&loanStatus=${filter.loanStatus}&dateFrom=${filter.dateFrom}&dateTo=${filter.dateTo}`,
    loansFetcher
  );

  const loanMapper = (loans) => {
    return loans.map((loan) => ({
      id: loan.id,
      loanType: loan.loan_name,
      loanStatus: loan.account_state,
      date: loan.creation_date,
      amount: loan.loan_amount,
      actions: "",
    }));
  };

  return {
    onPageChange: (page) => setPage(page),
    onFilterChange: (filter) => {
      setFilter(filter);
      setPage(0);
    },
    isLoading,
    loans: data?.data && loanMapper(data?.data),
    currentPage: parseInt(page) || 0,
    totalItems: data?.pagination?.totalItems || 0,
  };
};

export default useLoans;
