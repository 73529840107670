import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Alert, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ApisEnum from "../../utils/enums/apis.enum";

import ConfirmationModal from "./confirmationModal";

const SingleBatchModal = ({ url, method, data, id, columns, action, batch, onSuccess, handleClose, ...props }) => {
  const [t] = useTranslation("global");
  const axiosClient = useAxiosPrivate(ApisEnum.Risk);

  const [loading, setLoading] = useState(false);
  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    color: "success",
    message: t(`Risk.${action}BatchSuccess`),
  });

  const makeRequest = () => {
    setLoading(true);
    axiosClient({
      method: method,
      url: url,
      data: data,
    })
      .then(() => {
        handleSnackbar(true);
        onSuccess(batch[id]);
      })
      .catch(() => {
        setSnackbarSettings({
          open: true,
          color: "error",
          message: t(`Risk.${action}BatchError`),
        });
      })
      .finally(() => {
        handleClose();
        setLoading(false);
      });
  };

  const handleSnackbar = (open) => {
    setSnackbarSettings((prev) => ({ ...prev, open }));
  };

  return (
    <>
      <ConfirmationModal
        {...props}
        title={t(`Risk.${action}Confirmation`)}
        subtitle={t(`Risk.BatchTo${action}`)}
        confirmButtonText={t(`Risk.${action}`)}
        confirmButtonHandle={() => makeRequest()}
        handleClose={handleClose}
        loading={loading}
      >
        {batch && (
          <TableContainer
            sx={{
              maxHeight: "350px",
              marginTop: "16px",
              marginBottom: "48px",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ padding: "20px 16px", height: "88px" }}>
                <TableRow>
                  {Object.keys(columns).map((k) => (
                    <TableCell key={k} align="center">
                      {t(k)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.values(columns).map((v) => (
                    <TableCell key={v} align="center">
                      {v}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </ConfirmationModal>
      <Snackbar open={snackbarSettings.open} onClose={() => handleSnackbar(false)} data-testid="feedback-snackbar">
        <Alert
          severity={snackbarSettings.color}
          shadow="true"
          color={snackbarSettings.color}
          variant="standard"
          onClose={() => handleSnackbar(false)}
        >
          {snackbarSettings.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SingleBatchModal;
