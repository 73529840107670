import React from "react";

import { Button } from "@mui/material";

import AzureAuthenticationContext from "../utils/azure-confirguration/azure-authentication-context";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;

// Log In, Log Out button
const AzureAuthenticationComponent = ({ onAuthenticated }) => {
  // Azure client context
  const authenticationModule = new AzureAuthenticationContext();

  const logIn = (_method) => {
    const typeName = "loginPopup";
    const logInType = isIE ? "loginRedirect" : typeName;

    // Azure Login
    authenticationModule.login(logInType, returnedAccountInfo);
  };

  const returnedAccountInfo = (_user) => {
    onAuthenticated(_user);
  };

  const showLogInButton = () => {
    return (
      <Button
        color="primary"
        className="authentication-button"
        variant="contained"
        id="authenticationButton"
        onClick={() => logIn("loginPopup")}
      >
        Log in
      </Button>
    );
  };

  return (
    <div id="authentication">
      {authenticationModule.isAuthenticationConfigured ? (
        showLogInButton()
      ) : (
        <div>Authentication Client ID not configured.</div>
      )}
    </div>
  );
};

export default AzureAuthenticationComponent;
