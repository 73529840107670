import { Button } from "@mui/material";

export function CleanFilterButton({ filterFnc, label }) {
  return (
    <Button
      data-testid="button-clean-filter"
      variant="outlined"
      style={{
        background: "white",
        color: "#404A56",
        textTransform: "none",
        minWidth: "100px",
        height: "40px",
        borderColor: "#FFC10E",
      }}
      onClick={filterFnc}
      className="action-button-outlined"
    >
      {label}
    </Button>
  );
}
