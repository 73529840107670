import React from "react";
import { useTranslation } from "react-i18next";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";

function BackButton() {
  const [t] = useTranslation("global");

  const handleBack = () => {
    window.history.back();
  };

  const iconStyle = {
    cursor: "pointer",
    color: "#363853",
    marginRight: "5px",
  };

  return (
    <div onClick={handleBack}>
      <Typography variant="body2">
        <ArrowBackIcon style={iconStyle} />
        {t(`Risk.Return`)}
      </Typography>
    </div>
  );
}

export default BackButton;
