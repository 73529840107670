import React from "react";
import { useTranslation } from "react-i18next";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";

function FileUploadAlert({ fileUploaded, onDismiss }) {
  const [t] = useTranslation("global");

  if (!fileUploaded) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "152%",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "#EDF7EF",
        color: "#4CAF50",
        padding: "16px 24px",
        borderRadius: "8px",
        border: "1px solid #EDF7EF",
        boxShadow: "0px 4px 12px 0px #C2D1D975",
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <CheckCircleOutlineIcon color="inherit" />
      <Typography
        fontSize={"16px"}
        sx={{
          fontFamily: "Readex Pro",
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: "0.247px",
          textAlign: "center",
          color: "#4C5866",
        }}
      >
        {t("Risk.BankPayments.UploadedSuccessfully")}
      </Typography>

      <IconButton size="small" onClick={onDismiss} data-testid="on-dismiss">
        <CloseIcon />
      </IconButton>
    </Box>
  );
}

FileUploadAlert.propTypes = {
  fileUploaded: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
};
export default FileUploadAlert;
