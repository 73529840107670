import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Alert,
  Skeleton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ApisEnum from "../../utils/enums/apis.enum";

import ConfirmationModal from "./confirmationModal";

const SendModal = ({ open, onSuccess, handleClose, selectedBatches, ...props }) => {
  const [t] = useTranslation("global");
  const axiosClient = useAxiosPrivate(ApisEnum.Risk);

  const tableRef = useRef(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [allBatches, setAllBatches] = useState([]);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    color: "success",
    message: t("Risk.SendBatchesSuccess"),
  });

  const hasMore = page < totalPages;
  const batches = selectedBatches.length === 0 ? allBatches : selectedBatches;

  const fetchData = useCallback(() => {
    setLoading(true);
    axiosClient
      .get("/batches", {
        params: {
          page: page + 1,
          pageSize: 10,
          publishable: false,
        },
      })
      .then((response) => {
        setAllBatches((prev) => [...prev, ...response.data.data]);
        setTotalPages(response.data.totalPages);
        setPage((prev) => prev + 1);
      })
      .finally(() => setLoading(false));
  });

  const scrollListener = useCallback(() => {
    let bottom = tableRef.current.scrollHeight - tableRef.current.clientHeight;
    setDistanceBottom(Math.round(bottom * 0.2));
    if (tableRef.current.scrollTop > bottom - distanceBottom && hasMore && !loading && selectedBatches.length === 0) {
      setLoading(true);
      fetchData();
    }
  }, [fetchData, totalPages, loading, selectedBatches, distanceBottom]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener("scroll", scrollListener);
    }
    return () => {
      if (tableRef.current) {
        tableRef.current.removeEventListener("scroll", scrollListener);
      }
    };
  }, [scrollListener]);

  const formatDate = (stringDate) => {
    let date = new Date(stringDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return `${day}/${month}/${year}`;
  };

  const sendBatches = () => {
    setButtonLoading(true);

    let batchIds = selectedBatches.length == 0 ? [] : selectedBatches.map((b) => b.id);
    axiosClient
      .post("/batches/publish", { batchIds: batchIds })
      .then((response) => {
        handleSnackbar(true);
        onSuccess(response.data);
      })
      .catch(() => {
        setSnackbarSettings({
          open: true,
          color: "error",
          message: t("Risk.SendBatchesError"),
        });
      })
      .finally(() => {
        handleClose();
        setButtonLoading(false);
      });
  };

  const handleSnackbar = (open) => {
    setSnackbarSettings((prev) => ({ ...prev, open }));
  };

  return (
    <>
      <ConfirmationModal
        {...props}
        open={open}
        keepMounted={true}
        title={t("Risk.SendConfirmation")}
        subtitle={t("Risk.BatchesToSend")}
        confirmButtonText={t("Risk.Activate")}
        confirmButtonHandle={sendBatches}
        handleClose={handleClose}
        loading={buttonLoading}
      >
        <TableContainer
          ref={tableRef}
          data-testid="table-container"
          sx={{
            maxHeight: "350px",
            marginTop: "16px",
            marginBottom: "48px",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ padding: "20px 16px", height: "88px" }}>
              <TableRow>
                <TableCell align="center">{t("Risk.BatchId")}</TableCell>
                <TableCell align="center">{t("Risk.CreatedDate")}</TableCell>
                <TableCell align="center">{t("Risk.UsedClients")}</TableCell>
                <TableCell align="center">{t("Risk.SentNumberVariant")}</TableCell>
                <TableCell align="center">{t("Risk.NumberOfAudiencesVariant")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {batches.map((b, i) => (
                <TableRow
                  key={b.id}
                  sx={{
                    background: i % 2 == 0 ? "" : "#DDDFE1",
                    "& > *": {
                      borderBottom: "unset",
                    },
                  }}
                >
                  <TableCell align="center">{b.id}</TableCell>
                  <TableCell align="center">{formatDate(b.created_date)}</TableCell>
                  <TableCell align="center">{b.used_clients}</TableCell>
                  <TableCell align="center">{b.sent}</TableCell>
                  <TableCell align="center">{b.audiences}</TableCell>
                </TableRow>
              ))}
              {loading && (
                <TableRow data-testid="loading-row">
                  {[...Array(5).keys()].map((v) => {
                    return (
                      <TableCell align="center" key={v}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ConfirmationModal>
      <Snackbar open={snackbarSettings.open} onClose={() => handleSnackbar(false)} data-testid="feedback-snackbar">
        <Alert
          severity={snackbarSettings.color}
          shadow="true"
          color={snackbarSettings.color}
          variant="standard"
          onClose={() => handleSnackbar(false)}
        >
          {snackbarSettings.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SendModal;
