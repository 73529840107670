import useSWR from "swr";

import ApisEnum from "../../utils/enums/apis.enum";

import useAxiosPrivate from "../useAxiosPrivate";

const useKycInfo = (clientId) => {
  const axiosClient = useAxiosPrivate(ApisEnum.Backoffice);

  const kycFetcher = async (url) => {
    const { data } = await axiosClient.get(url);
    return data;
  };

  const { data, isLoading, error } = useSWR(`/clients/kyc/${clientId}`, kycFetcher);

  return {
    isLoading,
    kycInfo: data,
    error,
  };
};

export default useKycInfo;
