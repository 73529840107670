import { axiosClientKym } from "../../commons/axiosClient";

export const updateDocumentStatus = async (email, formId, country, body) => {
  let url = `/user?email=${email}&formId=${formId}&country=${country}`;
  return axiosClientKym
    .put(url, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getFile = (key, type) => {
  let downloadKey = key;
  let isView = "view" === type;
  let isDownload = "download" === type;
  const url = "user/filesDownload";

  return axiosClientKym
    .get(url, {
      params: {
        key: downloadKey,
        isView: isView,
        isDownload: isDownload,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
