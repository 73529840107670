import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Avatar, Box, Divider, Grid, IconButton, Typography } from "@mui/material";

import bankPayments from "../../img/BankPayments.svg";
import risk from "../../img/risk.svg";

const styles = {
  avatarSize: {
    width: 60,
    height: 60,
    marginRight: "12px",
    margin: "10% auto",
  },
  serviceContainer: {
    marginTop: "2%",
    marginBottom: "2%",
    width: "100%",
    height: "100%",
  },
  service: {
    p: 0,
    border: "1px solid #2D6DDD",
    borderRadius: "24px",
    margin: "1%",
    boxShadow: "0px 4px 12px rgba(194, 209, 217, 0.46)",
    textAlign: "center",
    position: "relative",
  },
};

const categories = [
  {
    name: "Riesgos",
    keyTranslation: "Risk.Risks",
    services: [
      {
        keyTranslation: "Risk.BatchConfig",
        icon: risk,
        urlNavigate: "/Risk/Batch",
      },
      {
        keyTranslation: "Risk.BankPayments.title",
        icon: bankPayments,
        urlNavigate: "/Risk/BankPayments",
      },
    ],
  },
];

export default function ServiceContainer() {
  const [t] = useTranslation("global");
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2">{t("Risk.selectService")}</Typography>
        </Grid>
      </Grid>

      {categories.map((category) => {
        return <Category key={category.keyTranslation} category={category} />;
      })}
    </>
  );
}

export function Category(props) {
  const { category } = props;
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  return (
    <>
      <Grid sx={{ marginTop: "2%", marginBottom: "0%" }}>
        <Typography variant="h4" sx={{ marginBottom: "0.5%" }}>
          {t(category.keyTranslation)}
        </Typography>
      </Grid>
      <Divider></Divider>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Grid
          container
          sx={styles.serviceContainer}
          width={100}
          height={100}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          justify="center"
        >
          {category.services.map((service, _) => (
            <Grid item key={service.urlNavigate} height={170} xs={2} sx={styles.service}>
              <div style={{ position: "absolute", top: 0, right: 0 }}>
                <IconButton aria-label="add to favorites" onClick={() => navigate(service.urlNavigate)}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>
              <Avatar alt="not batch" src={service.icon} sx={styles.avatarSize} />
              <Typography variant="subtitle1">{t(service.keyTranslation)}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
