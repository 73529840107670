import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Avatar, Box } from "@mui/material";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import GenericBackdrop from "../../../utils/GenericBackdrop";
import PDFPreview from "../../utils/PDFPreview";

const Title = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#404A56",
  boxShadow: "none",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
}));

const TableContract = (element, key, handleOnClick) => {
  let color = key % 2 !== 0;
  return (
    <Grid key={key} container ml={2} mr={2} mt={1} backgroundColor={color ? "gray" : "white"}>
      <Grid item xs={6}>
        {element.name.replace(".pdf", "")}
      </Grid>
      <Grid container xs={6}>
        <Grid item xs={9}>
          {element.date.replaceAll("-", "/")}
        </Grid>
        <Grid container xs={3} spacing={1} justifyContent={"flex-end"}>
          <Grid item>
            <Box className={"box-actionsContainer"}>
              <FileDownloadOutlinedIcon
                data-testid={"download-icon-" + key}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOnClick(element.contract_url, "download");
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box className={"box-actionsContainer"}>
              <VisibilityOutlinedIcon
                data-testid={"see-icon-" + key}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOnClick(element.contract_url, "view");
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const DetailContract = ({ translate, elements, loader, status }) => {
  const [urlImage, setUrlImage] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [t] = useTranslation("global");
  const [openPdf, setopenPdf] = useState(false);

  useEffect(() => {}, [elements]);
  const handleClosePdf = () => setopenPdf(false);
  const handleOnClick = (url, type) => {
    let isView = "view" === type;

    setUrlImage(url);
    if (isView) {
      setopenPdf(true);
    } else {
      window.open(url);
    }
  };
  return (
    <Card style={{ borderRadius: "20px" }}>
      <GenericBackdrop open={loader} />
      <Dialog fullScreen={fullScreen} open={openPdf} onClose={handleClosePdf}>
        <DialogContent>
          <PDFPreview pdfUrl={urlImage} />
        </DialogContent>
      </Dialog>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container sx={{ borderStyle: "none" }} className={"card-grid-padding"}>
          <Grid item xs={6}>
            <Grid item xs={12} sx={{ borderStyle: "none" }}>
              <Box style={{ width: "8%", float: "left" }}>
                <Avatar
                  style={{
                    backgroundColor: "#FFC70D",
                    color: "#FFFFFF",
                    marginBottom: "10px",
                  }}
                >
                  <AssignmentOutlinedIcon />
                </Avatar>
              </Box>

              <Box
                style={{
                  width: "92%",
                  float: "left",
                  marginBottom: "10px",
                }}
              >
                <Title>{t(`${translate}.Contract.Title`)}</Title>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container style={{ borderBottom: "1px solid gray" }} ml={1} mr={1}>
            <Grid container ml={1} mr={1} xs={12} mb={1}>
              <Grid item xs={6}>
                {t("OwnersWC.Contract.Name")}
              </Grid>
              <Grid item xs={6}>
                {t("OwnersWC.Contract.Date")}
              </Grid>
            </Grid>
          </Grid>
          {status == 200 && (
            <React.Fragment>
              {elements.map((element, key) => {
                return TableContract(element, key, handleOnClick);
              })}
            </React.Fragment>
          )}
        </Grid>
      </Box>
    </Card>
  );
};

export default DetailContract;
