import React, { useState } from "react";

import AddHelp from "./addHelp";
import Topics from "./topics";

const Help = () => {
  const [isAddHelp, setIsAddHelp] = useState(false);
  const [idTopic, setIdTopic] = useState(null);

  function getHelp() {
    if (isAddHelp && idTopic === null) return <AddHelp setIsAddHelp={setIsAddHelp} />;
    else if (idTopic !== null) return <AddHelp setIsAddHelp={setIsAddHelp} idTopic={idTopic} setIdTopic={setIdTopic} />;
    return <Topics setIsAddHelp={setIsAddHelp} setIdTopic={setIdTopic} />;
  }

  return getHelp();
};

export default Help;
