import { removeAuthTokenAzure } from "../components/utils/localStorage";

import { UseSignOut } from "./authUser";

const useLogout = () => {
  const signOut = UseSignOut();

  return async () => {
    try {
      signOut();
      removeAuthTokenAzure();
    } catch (err) {
      console.error(err);
    }
  };
};

export default useLogout;
