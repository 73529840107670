import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import MMButtonCustom, { MMTypeButtonStyles } from "../components/MMButtonCustom";
import MMDialogCustom from "../components/MMDialogCustom";

const MMUnBlockedDialog = (props) => {
  const { onAction, onClose, isOpen, isLoading = false } = props;

  const [t] = useTranslation("global");
  const [isUpdateData, setIsUpdateData] = useState(false);

  useEffect(() => {
    setIsUpdateData(isLoading);
  }, [isLoading]);

  const buildActionsModal = () => {
    return (
      <Fragment>
        <MMButtonCustom
          nameButton={"button-cancel"}
          onAction={onClose}
          type={MMTypeButtonStyles.Secondary}
          disabled={isUpdateData}
        >
          <span>{t("Micromerchants.Modal.Commons.Actions.BtnCancel")}</span>
        </MMButtonCustom>
        <MMButtonCustom
          nameButton={"button-accept"}
          onAction={onAction}
          disabled={isUpdateData}
          isLoading={isUpdateData}
        >
          <span>{t("Micromerchants.Modal.Commons.Actions.BtnContinue")}</span>
        </MMButtonCustom>
      </Fragment>
    );
  };

  return (
    <MMDialogCustom
      title={t("Micromerchants.Modal.UnBlocked.Title")}
      open={isOpen}
      onClose={!isUpdateData ? onClose : () => {}}
      actions={buildActionsModal()}
    >
      <Box sx={{ height: 145, maxWidth: 360, padding: 1 }}>
        <span>{t("Micromerchants.Modal.UnBlocked.Description")}</span>
      </Box>
    </MMDialogCustom>
  );
};

export default MMUnBlockedDialog;
