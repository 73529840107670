import React, { useCallback, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";

import { Box, Tab, Tabs } from "@mui/material";

import BankAccountValidation from "./validations/bank_account_validation";
import Validations from "./validations/validations_aml";

function Operation() {
  const useAuth = useAuthUser();
  const auth = useAuth();

  const [t] = useTranslation("global");

  const [value, setValue] = useState(0);

  const tabStyle = {
    "&.Mui-selected": { color: "#404A56" },
    color: "#4C5866",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    textAlign: "center",
  };

  const handleChange = useCallback((event, tabValue) => {
    setValue(tabValue);
  }, []);

  function renderPanel() {
    if (value === 0) {
      return <BankAccountValidation />;
    }
    if (value === 1) {
      return <Validations />;
    }
  }

  function validateUserAccess(scopes = []) {
    let permissionGranted = auth?.roles && scopes.split(".").reduce((obj, i) => obj[i], auth?.roles);
    return permissionGranted;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "#DFE3E7" }}>
        <Tabs value={value} onChange={handleChange} sx={{ "& .MuiTabs-indicator": { backgroundColor: "#004FB6" } }}>
          {validateUserAccess("resources.configuration") && (
            <Tab
              label={t("Operations.BankAccountValidations.TabName")}
              sx={tabStyle}
              value={0}
              data-testid="validations"
            />
          )}
          <Tab
            label={t("Operations.BankAccountValidations.TabNameKyx")}
            sx={tabStyle}
            value={1}
            data-testid="validationsAml"
          />
        </Tabs>
      </Box>
      {renderPanel()}
    </Box>
  );
}

export default Operation;
