import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";
import { CardActionArea } from "@mui/material";

export default function BackButton({ backTo }) {
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  const handleClick = () => {
    if (backTo) return navigate(backTo, { replace: true });
    navigate(-1);
  };

  return (
    <CardActionArea
      onClick={handleClick}
      sx={{
        boxShadow: "none",
        display: "inline-block",
        width: "fit-content",
        borderRadius: "16px",
        ".MuiCardActionArea-focusHighlight": {
          backgroundColor: "transparent",
        },
      }}
    >
      <ArrowBack
        sx={{
          display: "inline-block",
          verticalAlign: "middle",
          color: "#363853",
        }}
      />
      <p
        style={{
          display: "inline-block",
          marginLeft: "12px",
          verticalAlign: "middle",
          marginBottom: "0px",
          color: "#363853",
          fontWeight: "500",
          fontSize: "16px",
        }}
      >
        {t("Buttons.Back")}
      </p>
    </CardActionArea>
  );
}
