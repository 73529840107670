import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableCell,
} from "@mui/material";

import { axiosClientKym } from "../../commons/axiosClient";
import BasicTable from "../../commons/basicTable";
import MMSearchBar from "../components/MMSearchBar";
import { getKyxMicromerchants } from "../data/repositories/micromerchant_repository";

const ignoreFields = ["step"];

const Inprogress = (props) => {
  const { country } = props;
  const [t] = useTranslation("global");
  const [tblLoading, setLoading] = useState(true);
  const [step, setStep] = useState("");
  const [data, setData] = useState([]);
  const [formSections, setformSections] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowsPerPage, setRowPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [inProgressColumnSort, setInProgressColumnSort] = useState([]);

  const [totalInProgress, setTotalInProgress] = useState(0);
  const [filterInProgress, setFilterInProgress] = useState({});
  const [inProgressValueSort, setInProgressValueSort] = useState({});

  const handleShowTbl = () => setLoading(false);
  const handleHideTbl = () => setLoading(true);

  const inProgressMMTable = "Micromerchant";
  const translateMMFormStepFormat = `${inProgressMMTable}s.Form.Step.Names`;

  const inProgressMMDetailStorage = `${inProgressMMTable}Detail`;
  const inProgressMMrowsPerPageStorage = `${inProgressMMTable}-rowsPerPage`;
  const inProgressMMPageStorage = `${inProgressMMTable}-page`;
  const inProgressMMStepStorage = `${inProgressMMTable}-step`;
  const inProgressMMSearchStorage = `${inProgressMMTable}-search`;
  const inProgressMMSortStorage = `${inProgressMMTable}-sort`;

  useEffect(() => {
    buildLocalStorage();
    getTableData();
  }, []);

  const buildLocalStorage = () => {
    let paramsInProgress = {};
    const action = localStorage.getItem(inProgressMMDetailStorage) != null ? "set" : "remove";
    if (action === "set") {
      if (localStorage.getItem(inProgressMMrowsPerPageStorage) != null) {
        setRowPerPage(parseInt(localStorage.getItem(inProgressMMrowsPerPageStorage)));
      }
      if (localStorage.getItem(inProgressMMPageStorage) != null) {
        setPage(parseInt(localStorage.getItem(inProgressMMPageStorage)));
      }
      if (localStorage.getItem(inProgressMMStepStorage) != null) {
        setStep(localStorage.getItem(inProgressMMStepStorage));
        paramsInProgress = checkValueSteps(paramsInProgress);
      }
      if (localStorage.getItem(inProgressMMSearchStorage) != null) {
        paramsInProgress = {
          ...paramsInProgress,
          search_field: localStorage.getItem(inProgressMMSearchStorage),
        };
      }
      if (localStorage.getItem(inProgressMMSortStorage) != null) {
        setInProgressValueSort(JSON.parse(localStorage.getItem(inProgressMMSortStorage)));
      }
    } else {
      localStorage.removeItem(inProgressMMrowsPerPageStorage);
      localStorage.removeItem(inProgressMMPageStorage);
      localStorage.removeItem(inProgressMMStepStorage);
      localStorage.removeItem(inProgressMMSearchStorage);
      localStorage.removeItem(inProgressMMSortStorage);
    }
    Object.assign(filterInProgress, paramsInProgress);
  };

  const checkValueSteps = (paramsInProgress) => {
    if (localStorage.getItem(inProgressMMStepStorage) != "") {
      paramsInProgress = {
        ...paramsInProgress,
        form: parseInt(localStorage.getItem(inProgressMMStepStorage)),
      };
    }

    return paramsInProgress;
  };

  const getTableData = async () => {
    handleHideTbl();
    let sections = await getFormMMKyx();
    let progressSearch = filterInProgress;
    let progressPage = page + 1;
    let progressRowPerPage = rowsPerPage;
    let sortInprogress = inProgressValueSort;

    if (localStorage.getItem(inProgressMMPageStorage) != null) {
      progressPage = parseInt(localStorage.getItem(inProgressMMPageStorage)) + 1;
    }

    if (localStorage.getItem(inProgressMMrowsPerPageStorage) != null) {
      progressRowPerPage = parseInt(localStorage.getItem(inProgressMMrowsPerPageStorage));
    }

    if (localStorage.getItem(inProgressMMSortStorage) != null) {
      sortInprogress = JSON.parse(localStorage.getItem(inProgressMMSortStorage));
    }

    const response = await getKyxMicromerchants({
      country: country,
      page: progressPage,
      limit: progressRowPerPage,
      search: progressSearch,
      sort: sortInprogress,
      status: "inprogress",
    });

    setDataPage(response.data, progressSearch, sections, sortInprogress);
  };

  const getFormMMKyx = async () => {
    return await axiosClientKym
      .get("form", {
        params: {
          country: country.toUpperCase(),
          formId: "F0040",
          email: "",
        },
      })
      .then((response) => {
        const sections = response.data["forms"][0]["seccion"];
        setformSections(sections);
        return sections;
      });
  };

  const setDataPage = (data, search, sections, sortInprogress) => {
    const columnStepTranslate = "step_translate";
    const stepIndex = data.columns.findIndex((column) => column === "step");
    data.columns[stepIndex] = columnStepTranslate;

    data.results.map((mm) => {
      const mmStepIndex = (mm?.step ?? 0) - 1;
      const mmStep = sections[mmStepIndex] ?? { type: "step_unknown" };
      mm["step_translate"] = t(`${translateMMFormStepFormat}.${mmStep["type"]}`);
      return mm;
    });

    translateColumns(data.columns);
    setInProgressColumnSort(data.sort);
    setTotalInProgress(data.total_micromerchant);
    setData(data.results);
    setFilterInProgress(search);
    setInProgressValueSort(sortInprogress);
    handleShowTbl();
  };

  const handleChange = useCallback(async (e) => {
    localStorage.setItem(inProgressMMStepStorage, e.target.value);
    localStorage.setItem("Micromerchant-page", 0);
    setStep(e.target.value);
    setPage(0);

    delete filterInProgress["form"];
    const addFilterSteps =
      e.target.value !== ""
        ? Object.assign({}, { ...filterInProgress, form: parseInt(e.target.value) })
        : filterInProgress;
    Object.assign(filterInProgress, addFilterSteps);
    await getTableData();
  }, []);

  const handleSearchInProgress = async (value) => {
    Object.assign(filterInProgress, {
      ...filterInProgress,
      search_field: value,
    });
    await getTableData();
  };

  const translateColumns = (cols) => {
    let trans = "Micromerchants.InProgress.Columns.";
    let arrColumns = [];

    for (let col of Object.values(cols)) {
      arrColumns.push(trans.concat(col));
    }
    setColumns(arrColumns);
  };

  const FilterStepValues = (seccions) => {
    return seccions.map((seccion, index) => (
      <MenuItem key={`menu-item-${seccion["type"]}`} value={`${index + 1}`}>
        {t(`${translateMMFormStepFormat}.${seccion["type"]}`)}
      </MenuItem>
    ));
  };

  const buildActions = useCallback((row) => {
    return (
      <Fragment>
        <Fragment>
          <TableCell
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {props.eyedIcon(row.client_id, row)}
          </TableCell>
        </Fragment>
      </Fragment>
    );
  }, []);

  const Table = useCallback(() => {
    return (
      <BasicTable
        dataTable={data}
        dataColumns={columns}
        action={buildActions}
        condition={ignoreFields}
        dynamicAction={[]}
        rowsPerPageOpt={[]}
        tableType={inProgressMMTable}
        rowsPerPageValue={
          localStorage.getItem("Micromerchant-rowsPerPage") !== null
            ? parseInt(localStorage.getItem("Micromerchant-rowsPerPage"))
            : 50
        }
        pageValue={
          localStorage.getItem("Micromerchant-page") !== null ? parseInt(localStorage.getItem("Micromerchant-page")) : 0
        }
        columnsSort={inProgressColumnSort}
        isTxtRowsPerPagHidden={false}
        totalData={totalInProgress}
        getData={getTableData}
      />
    );
  }, [data, page, rowsPerPage, formSections]);

  return (
    <Fragment>
      <Stack width="100%" mb={2} direction="row" justifyContent="start">
        <Stack direction="row" width="30%" mb={2}>
          <MMSearchBar
            type={inProgressMMTable}
            placeholder={"Micromerchants.InProgress.Search"}
            onSearch={handleSearchInProgress}
          />
        </Stack>
        <Stack width="15%" mb={2} ml={2}>
          <FormControl>
            <InputLabel id="select-change-step">
              {t("Micromerchants.InProgress.Filters.Step.SelectStepForm")}
            </InputLabel>
            <Select
              labelId="select-change-step"
              id="select-change-step-helper"
              value={step}
              label="step"
              onChange={handleChange}
              data-testid="select-change-step-test"
              sx={{ paddingBottom: "0px", borderRadius: "16px" }}
            >
              <MenuItem value="">
                <em>{t("Micromerchants.InProgress.Filters.Step.SelectOption")}</em>
              </MenuItem>
              {FilterStepValues(formSections)}
              <MenuItem value={"otros"}>Otros</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Fragment>
        {tblLoading ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <div>Loading ...</div>
            <CardContent data-testid={"loading-card-micromerchant-inprogress"}>
              <CircularProgress data-testid={"loading-circular-progress-micromerchant-inprogress"} color="warning" />
            </CardContent>
          </Box>
        ) : (
          <Stack>
            <Table />
          </Stack>
        )}
      </Fragment>
    </Fragment>
  );
};

export default Inprogress;
