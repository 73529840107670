import axios from "axios";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_DOMAIN;
axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  "x-api-key": process.env.REACT_APP_DOMAIN_APIKEY,
  Accept: "application/json",
};

//All request will wait 30 seconds before timeout
axiosClient.defaults.timeout = 30000;

const axiosClientKym = axios.create();
axiosClientKym.defaults.baseURL = process.env.REACT_APP_KYM_BACK_DOMAIN;
axiosClientKym.defaults.headers = {
  "Content-Type": "application/json",
  "x-api-key": process.env.REACT_APP_DOMAIN_APIKEY_KYM,
  Accept: "application/json",
};

//All request will wait 30 seconds before timeout
axiosClientKym.defaults.timeout = 30000;

const axiosClientWc = axios.create();
axiosClientWc.defaults.baseURL = process.env.REACT_APP_MWC_BACK_DOMAIN;
axiosClientWc.defaults.headers = {
  "Content-Type": "application/json",
  "x-api-key": process.env.REACT_APP_DOMAIN_APIKEY_MWC,
  Accept: "application/json",
};

//All request will wait 30 seconds before timeout
axiosClientWc.defaults.timeout = 30000;

const axiosClientBo = axios.create();
axiosClientBo.defaults.baseURL = process.env.REACT_APP_BACKOFFICE_DOMAIN;
axiosClientBo.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

//All request will wait 30 seconds before timeout
axiosClientBo.defaults.timeout = 30000;

export { axiosClient, axiosClientKym, axiosClientWc, axiosClientBo };
