export function validateexp(currentvalue, exp, value, set, description) {
  if (exp) {
    if (exp.test(currentvalue)) {
      set({
        ...value,
        value: currentvalue,
        error: false,
        errordescription: "",
      });
      return true;
    } else {
      set({ ...value, error: true, errordescription: description });
    }
  }
}

export function formatYMD(date) {
  if (date) {
    return date.toISOString().slice(0, 10);
  } else {
    return "";
  }
}

export function currencyFormatter(number, local, currency) {
  return new Intl.NumberFormat(local, {
    style: "currency",
    currency: currency,
  }).format(number);
}

export function monthAdd(date, month) {
  let temp = new Date(date.getFullYear(), date.getMonth(), 1);

  temp.setMonth(temp.getMonth() + month + 1);
  temp.setDate(temp.getDate() - 1);

  if (date.getDate() < temp.getDate()) temp.setDate(date.getDate());

  return temp;
}

/**
 * The function checks if an object is empty by returning true if the object is null, 0, NaN, "", or
 * undefined, and false if the object has no keys.
 *
 * Args:
 *   obj: The `obj` parameter is the object that we want to check if it is empty or not.
 *
 * Returns:
 *   a boolean value. It will return true if the object is empty (has no properties), and false if the
 * object has any properties.
 */
export function isEmptyObject(obj) {
  if (!obj) return true; //null, 0, NaN, "", undefined
  return Object.keys(obj).length === 0;
}

/**
 * The sleep function in JavaScript returns a promise that resolves after a specified number of
 * milliseconds.
 *
 * Args:
 *   ms: The `ms` parameter is the number of milliseconds to wait before resolving the promise. By
 * default, it is set to 200 milliseconds.
 *
 * Returns:
 *   The sleep function returns a Promise object.
 */
export function sleep(ms = 200) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * The function `getCountryByDomain` extracts the country code from the domain name of the current
 * website.
 *
 * Returns:
 *   the country in uppercase letters, if local environment returns LOCALHOST.
 */
export function getCountryByDomain() {
  const LOCAL = "localhost";
  const MERCHANT = "comercios.interno.";

  let country = LOCAL;
  let domain = window.location.hostname;

  if (!domain.includes(LOCAL)) {
    domain = domain.split(MERCHANT)[1];
    country = domain.split(".")[0];
  }

  return country.toUpperCase();
}
