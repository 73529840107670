import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Tab, Tabs } from "@mui/material";

import { sendMailDocumentRemedy, validateDocumentsComplete } from "../utils/actions/axios-api-wc";
import { SnackBar } from "../utils/alert";

import AggridTable from "../aggrid/aggridTable";
import { DynamicAction } from "../aggrid/dynamicAction";

const Owners = ({ isWorkingCapital }) => {
  const [t] = useTranslation("global");

  const [value, setValue] = useState(0);
  const [params, setParams] = useState("");
  const country = "py";
  const arrTabs = [];
  const rejectedTab = isWorkingCapital ? "Rejected" : "Upload";
  const url = "owners";
  const cellClassCenter = "ag-header-center";
  const cellClassLeft = "ag-cell-left";
  const columnsMerchant = [
    {
      headerName: t("Owners.Merchant"),
      field: "name",
      flex: 1,
      cellClass: cellClassLeft,
    },
    {
      headerName: t("Owners.MerchantId"),
      field: "wallet_id",
      flex: 1,
      cellClass: cellClassLeft,
    },
    {
      headerName: t("Owners.DateRegister"),
      field: "created_at",
      flex: 1,
      cellClass: cellClassLeft,
    },
    {
      headerName: t("Owners.StatusKym.Title"),
      field: "status_kym",
      cellRendererFramework: DynamicAction,
      cellRendererParams: { icon: "status_kym" },
      flex: 1,
      cellClass: cellClassCenter,
    },
    {
      headerName: t("Owners.Status.StatusAML"),
      field: "status_aml",
      cellRendererFramework: DynamicAction,
      cellRendererParams: { icon: "status_aml" },
      flex: 1,
      headerClass: cellClassCenter,
    },
    {
      headerName: t("Owners.Status.StatusContract"),
      field: "sign_contract",
      cellRendererFramework: DynamicAction,
      cellRendererParams: { icon: "signContract" },
      flex: 1,
      headerClass: cellClassCenter,
    },
    {
      headerName: t("Owners.Status.Status"),
      field: "is_activate",
      cellRendererFramework: DynamicAction,
      cellRendererParams: { icon: "IsDisabled" },
      flex: 1,
      headerClass: cellClassCenter,
    },
    {
      headerName: t("Owners.Detail"),
      cellRendererFramework: DynamicAction,
      cellRendererParams: { path: "/Merchants/", icon: "LinkDetail" },
      flex: 1,
      headerClass: cellClassCenter,
    },
  ];

  const generateColumnsWorkingCapital = () => {
    let array = [];
    let key = "";
    let columnsWc = [
      [t("WorkingCapital.Columns.NamePDV"), "name", 1, "cellClassLeft", "", ""],
      [t("WorkingCapital.Columns.IDPDV_IDPTM"), "id_pdv", 1, "cellClassCenter", "", ""],
      [t("WorkingCapital.Columns.Cedula"), "cedula", 1, "cellClassCenter", "", ""],
      [t("WorkingCapital.Columns.#ePin"), "epin", 1, "cellClassCenter", "", ""],
      [t("WorkingCapital.Columns.#eMoney"), "emoney", 1, "cellClassCenter", "", ""],
      [t("WorkingCapital.Columns.Date"), "updateDate", 1, "cellClassCenter", "", ""],
      [
        t("WorkingCapital.Columns.Status"),
        "status",
        1,
        "cellClassCenter",
        DynamicAction,
        { icon: "WorkingCapitalStatus" },
      ],
      [
        t("WorkingCapital.Columns.Actions"),
        "",
        1,
        "cellClassCenter",
        DynamicAction,
        { path: "/WorkingCapital/", icon: "LinkDetail", submitNotifyRejected: submitNotifyRejected },
      ],
    ];
    let keysWc = ["headerName", "field", "flex", "cellClass", "cellRendererFramework", "cellRendererParams"];

    for (let valueWc of columnsWc) {
      let elements = {};
      for (let v of valueWc) {
        let a = valueWc.indexOf(v);
        key = keysWc[a];
        elements[key] = v;
      }
      array.push(elements);
    }
    return array;
  };

  const submitNotifyRejected = async (props) => {
    const responseValidDocs = await validateDocumentsComplete(props.data.email, props.data.formId, country);
    if (responseValidDocs?.status !== 200) {
      SnackBar(t("General.Message.ErrorNotifyRejected"), "error");
      return;
    }
    const response = await sendMailDocumentRemedy({
      email: props.data.email,
      id_pdv: props.data.id_pdv,
      documents: responseValidDocs.data.documents,
    });
    if (response?.status !== 200) {
      SnackBar(t("General.Message.ErrorNotifyRejected"), "error");
      return;
    }
    SnackBar(t("General.Message.SucessNotifyRejected"), "success");
  };

  const columns = isWorkingCapital ? generateColumnsWorkingCapital() : columnsMerchant;

  const listSelectData = {
    title: t("OwnersWC.FlterPhoneNumber.Title"),
    list: [
      {
        title: t("OwnersWC.FlterPhoneNumber.List.ePin"),
        value: "ePin",
      },
      {
        title: t("OwnersWC.FlterPhoneNumber.List.eMoney"),
        value: "eMoney",
      },
      {
        title: t("OwnersWC.FlterPhoneNumber.List.Both"),
        value: "Both",
      },
      {
        title: t("OwnersWC.FlterPhoneNumber.List.All"),
        value: "All",
      },
    ],
  };

  const handleFilter = (event) => {
    setParams(event.currentTarget.id);
    const index = arrTabs.indexOf(event.currentTarget.id);
    setValue(index);
  };

  const fnFilters = (tabName, onclick) => {
    arrTabs.push(tabName);

    return (
      <Tab
        label={t("Owners.Filters." + tabName)}
        id={tabName}
        onClick={onclick}
        style={{
          minWidth: "172px",
          fontFamily: "Roboto",
          fontSize: "18px",
          opacity: 100,
        }}
      />
    );
  };

  return (
    <div className="App">
      {" "}
      <Fragment>
        <Box sx={{ width: "100%", borderBottom: 1, borderColor: "#DFE3E7" }} mt={3} mr={3} ml={0}>
          <Tabs value={value}>
            {fnFilters(isWorkingCapital ? "All" : "All_", handleFilter)}
            {fnFilters("Pending", handleFilter)}
            {fnFilters("Validated", handleFilter)}
            {fnFilters(rejectedTab, handleFilter)}
            {!isWorkingCapital && fnFilters("RejectedAml", handleFilter)}
          </Tabs>
        </Box>
        <div style={{ paddingTop: "30px" }}>
          {" "}
          <AggridTable
            columnDefs={columns}
            pagination={true}
            url={url}
            params={params}
            setParams={setParams}
            upperCase={false}
            height={400}
            width={"100%"}
            isWorkingCapital={isWorkingCapital}
            listSelectData={isWorkingCapital ? listSelectData : false}
          />
        </div>
      </Fragment>
    </div>
  );
};

export default Owners;
