export function validateexp(currentvalue, exp, value, set, description) {
  if (exp) {
    if (exp.test(currentvalue)) {
      set({
        ...value,
        value: currentvalue,
        error: false,
        errordescription: "",
      });
      return true;
    } else {
      set({ ...value, error: true, errordescription: description });
    }
  }
}

export function currencyFormatter(number, local = "es-HN", currency = "HNL") {
  return new Intl.NumberFormat(local, {
    style: "currency",
    currency: currency,
  }).format(number);
}

export const validEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
