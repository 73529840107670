import React from "react";

import Sesion from "../components/commons/sesion";
import Screentransfer from "../components/commons/transfer";

export default function Transfer() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Transfer"} />
      <Screentransfer></Screentransfer>
    </div>
  );
}
