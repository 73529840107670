import { useAuthUser } from "react-auth-kit";

const PermissionsGateAzure = ({ children, scopes = [] }) => {
  const useAuth = useAuthUser();
  const auth = useAuth();
  let permissionGranted;
  if (scopes.length !== 0) {
    permissionGranted = scopes?.split(".").every((element) => {
      return auth?.idTokenClaims?.roles?.includes(element);
    });
  }

  if (!permissionGranted) return <></>;

  return <>{children}</>;
};

export default PermissionsGateAzure;
