import React, { useState } from "react";

import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";

export default function ListSelect({ itemsMenu, title, setFilter }) {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setFilter(event.target.value);
    setValue(event.target.value);
  };

  return (
    <Stack direction="row" alignItems="center" width="100%">
      <FormControl sx={{ minWidth: 160 }}>
        <InputLabel id="demo-simple-select-helper-label">{title}</InputLabel>
        <Select
          value={value}
          onChange={handleChange}
          label={title}
          placeholder={title}
          sx={{ height: "49px !important", borderRadius: "8px" }}
          data-testid="generic-list-select"
        >
          <MenuItem value="">
            <em>{title}</em>
          </MenuItem>
          {itemsMenu.map((item, index) => (
            <MenuItem id={`${index}-item`} key={`${item.value}-item`} value={item.value}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
