import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import CustomButton from "../../commons/backoffice/customButton";
import CustomInput from "../../commons/backoffice/customInput";

const FinancialProductFilter = ({ onFilterSubmit }) => {
  const [t] = useTranslation("global");
  const [loanId, setLoanId] = useState("");
  const [loanType, setLoanType] = useState("");
  const [loanStatus, setLoanStatus] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const filter = {
      loanId,
      loanType,
      loanStatus,
      dateFrom,
      dateTo,
    };
    if (onFilterSubmit) onFilterSubmit(filter);
  };

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "none",
        backgroundColor: "#EEF0F2",
        borderRadius: "16px",
        display: "flex",
        padding: "16px 32px",
        alignItems: "end",
        justifyContent: "space-between",
        gap: "24px",
      }}
    >
      <CustomInput placeholder={t("Backoffice.FinancialProduct.LoanID")} onChange={(newValue) => setLoanId(newValue)} />
      <CustomInput
        type="select"
        placeholder={t("Backoffice.FinancialProduct.LoanType.Title")}
        onChange={(newValue) => setLoanType(newValue)}
        options={[
          t("Backoffice.FinancialProduct.LoanType.Micro"),
          t("Backoffice.FinancialProduct.LoanType.Installments"),
        ]}
      />
      <CustomInput
        type="select"
        onChange={(newValue) => setLoanStatus(newValue)}
        options={[
          t("Backoffice.FinancialProduct.Status.Active"),
          t("Backoffice.FinancialProduct.Status.Paid"),
          t("Backoffice.FinancialProduct.Status.Overdue"),
        ]}
        placeholder={t("Backoffice.FinancialProduct.Status.Title")}
      />
      <CustomInput
        onChange={(newValue) => setDateFrom(newValue)}
        type="date"
        title={t("Backoffice.FinancialProduct.Date")}
        placeholder={t("Backoffice.FinancialProduct.From")}
      />
      <CustomInput
        onChange={(newValue) => setDateTo(newValue)}
        type="date"
        placeholder={t("Backoffice.FinancialProduct.To")}
      />
      <CustomButton width="50%" onClick={handleSubmit}>
        Buscar
      </CustomButton>
    </Box>
  );
};

export default FinancialProductFilter;
