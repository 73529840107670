import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";

import { SnackBar } from "../../utils/alert";
import { currencyFormatter } from "../../utils/validateExpression";

import AggridTable from "../../aggrid/aggridTable";
import { DynamicAction } from "../../aggrid/dynamicAction";
import { axiosClient } from "../../commons/axiosClient";
import { ColumnsGenerator } from "../columnGenerator";

export default function CashOut({ ownerId }) {
  const [t] = useTranslation("global");
  const columns = ColumnsGenerator([
    {
      headerName: t("Operations.CashOut.Merchant"),
      field: "organization_name",
      minWidth: 140,
    },
    {
      headerName: t("Operations.CashOut.Date"),
      field: "date",
      minWidth: 120,
    },
    {
      headerName: t("Operations.CashOut.Hour"),
      field: "time",
      minWidth: 90,
    },
    {
      headerName: t("Operations.CashOut.Amount"),
      field: "amount",
      minWidth: 110,
      valueFormatter: (param) => currencyFormatter(param.data.amount),
    },
    {
      headerName: t("Operations.CashOut.Bank"),
      field: "bank",
      minWidth: 120,
    },
    {
      headerName: t("Operations.CashOut.AccountNumber"),
      field: "bankAccount",
      minWidth: 150,
    },
    {
      headerName: t("Operations.CashOut.Status.Header"),
      field: "status",
      cellRendererFramework: DynamicAction,
      cellRendererParams: { icon: "TransfersStatus" },
      minWidth: 155,
    },
    {
      headerName: t("Operations.CashOut.Actions.Header"),
      cellRendererFramework: DynamicAction,
      cellRendererParams: {
        process: InProcess,
        complete: Complete,
        rejected: Rejected,
        icon: "TransfersChangeStatus",
      },
      minWidth: 100,
    },
  ]);

  const [showTable, setShowTable] = useState(true);

  const handleShowTale = () => setShowTable(true);
  const handleHideTale = () => setShowTable(false);
  const getUrl = (owner_id) => `cash-outs/${ownerId || owner_id || ""}`;

  function updateStatusCashOut(status, owner_id, transfer_id) {
    handleHideTale();
    const urlAccount = `${getUrl(owner_id)}/${transfer_id}/status`;
    axiosClient
      .patch(urlAccount, JSON.stringify({ status: status }))
      .then((_) => {
        SnackBar(t("Owners.Message.AccountStatus"), "success");
        handleShowTale();
      })
      .catch((_) => {
        SnackBar(t("General.Message.ErrorService"), "error");
        handleShowTale();
      });
  }

  function InProcess(props, e) {
    e.preventDefault();
    updateStatusCashOut("process", ownerId || props.data.owner_id, props.data.transaction_id);
  }

  function Complete(props, e) {
    e.preventDefault();
    updateStatusCashOut("complete", ownerId || props.data.owner_id, props.data.transaction_id);
  }

  function Rejected(props, e) {
    e.preventDefault();
    updateStatusCashOut("rejected", ownerId || props.data.owner_id, props.data.transaction_id);
  }

  function showTableAgGrid() {
    if (showTable) {
      return <AggridTable columnDefs={columns} pagination={true} height={600} url={getUrl()} width={"100%"} />;
    }
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <CardContent data-testid={"loading-change-bank-status"}>
          <CircularProgress data-testid={"loading-change-cash-out-status"} color="warning" />
        </CardContent>
      </Box>
    );
  }

  return <Fragment>{showTableAgGrid()}</Fragment>;
}
