import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { createTheme } from "@mui/material/styles";

const ThemeRisks = createTheme({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Readex Pro",
          borderRadius: "16px",
          padding: "16px",
          minWidth: "129px",
          height: "48px",
        },
      },
      variants: [
        {
          props: { variant: "solid" },
          style: {
            background: "#363853",
            color: "white",
            ":hover": {
              backgroundColor: "#363853",
            },
          },
        },
        {
          props: { variant: "solid" },
          style: {
            background: "#363853",
            color: "white",
            ":hover": {
              backgroundColor: "#363853",
            },
          },
        },
        {
          props: { variant: "solid", size: "sm" },
          style: {
            maxHeight: "36px",
            maxWidth: "130px",
          },
        },
        {
          props: { variant: "disabled" },
          style: {
            background: "#CFD0DF",
            color: "#FFFFFF !important",
            ":hover": {
              backgroundColor: "#CFD0DF",
            },
          },
        },
        {
          props: { variant: "blank" },
          style: {
            color: "#363853",
          },
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#FABE00",
          height: "3px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          textTransform: "none",
          color: "#708195",
          fontFamily: "Readex Pro",
          fontStyle: "normal",
          fontSize: "14px",
          fontWeight: "400",
          width: "170px",
          "&.Mui-selected": {
            color: "#363853",
            fontWeight: 600,
          },
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        autoHideDuration: 5000,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      },
      styleOverrides: {
        root: {
          marginBottom: "72px",
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <ErrorIcon fontSize="inherit" />,
          success: <CheckCircleIcon fontSize="inherit" />,
        },
      },
      variants: [
        {
          props: { variant: "standard", shadow: "true" },
          style: {
            width: "100%",
            boxShadow: "0px 4px 12px rgba(194, 209, 217, 0.46)",
            borderRadius: "5px",
          },
        },
      ],
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: "400",
          color: "#4C5866",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          sx: {
            borderRadius: 15,
            maxWidth: "160px",
          },
        },
        InputLabelProps: {
          sx: {
            "& Focused": {
              color: "black",
            },
          },
        },
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: ["Readex Pro"].join(","),
      textTransform: "none",
      color: "#343C46",
    },
    h2: {
      fontSize: "28px",
      fontWeight: "700",
      color: "#404A56",
      lineHeight: "150%",
    },
    h3: {
      fontSize: "20px",
      fontWeight: "500",
      color: "#4C5866",
      lineHeight: "24px",
      marginBottom: "20px",
    },
    h4: {
      fontSize: "24px",
      fontWeight: "400",
      color: "#4C5866",
      lineHeight: "24px",
      marginBottom: "20px",
    },
    h5: {
      fontSize: "24px",
      fontWeight: "600",
      color: "#343C46",
      lineHeight: "34px",
    },
    h6: {
      fontSize: "20px",
      fontWeight: "500",
      color: "#4C5866",
      lineHeight: "24px",
    },
    subtitle1: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
    },
    subtitle2: {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#4C5866",
    },
    body1: {
      fontWeight: "400",
      fontSize: "16px",
    },
    body2: {
      fontWeight: "400",
      fontSize: "14px",
    },
  },
  tableStyle: {
    tableContainer: {
      boxShadow: "none",
    },
    tableHeaders: {
      fontFamily: "Readex Pro",
      fontStyle: "normal",
      fontWeight: "500",
      fontWeightImportant: "500 !important",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#4C5866 !important",
    },
    tableBody: {
      fontFamily: "Readex Pro",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#4C5866 !important",
    },
  },
});

export default ThemeRisks;
