import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, IconButton, InputAdornment, InputBase, Paper, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { v4 as uuidv4 } from "uuid";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { isEmptyObject } from "../../utils/validateExpression";

export default function Search({
  backUp,
  setSearch,
  columnsSearch,
  setIsSearch,
  placeholder = "Buttons.SearchInsight",
  type,
  setPage,
}) {
  const [t] = useTranslation("global");

  let totalRows = 0;
  const typeStorage = `${type}Detail`;
  const rowsPerPageStorage = `${type}-rowsPerPage`;
  const searchStorage = `${type}-search`;
  const pageStorage = `${type}-page`;

  useEffect(() => {
    if (localStorage.getItem(typeStorage) !== null && columnsSearch !== null && columnsSearch.length > 0)
      SearchValue(getSearchLocal(searchStorage));
  }, [columnsSearch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    SearchValue(event.target[0].value.toLowerCase());
  };

  const handleChange = (event) => {
    event.preventDefault();
    SearchValue(event.target.value.toLowerCase());
  };

  const getSearchLocal = (search) => {
    if (localStorage.getItem(typeStorage) !== null && search !== undefined && search !== null)
      return localStorage.getItem(search);
    else return "";
  };

  function SearchValue(value) {
    if (value !== undefined && value !== null && value !== "") {
      setIsSearch(true);

      const rows = getRecords(value);
      totalRows = rows.length;
      setSearch(rows);

      setIsSearch(false);
      setStorage(value);
    } else {
      setSearch(backUp);
      setIsSearch(false);
      removeStorage();
    }
  }

  function setStorage(value) {
    if (searchStorage !== undefined && searchStorage !== null) localStorage.setItem(searchStorage, value);

    const rowsPerPage =
      localStorage.getItem(rowsPerPageStorage) !== null ? parseInt(localStorage.getItem(rowsPerPageStorage)) : 50;
    const page = parseInt(localStorage.getItem(pageStorage));

    if (totalRows <= rowsPerPage || totalRows / rowsPerPage < page) {
      if (setPage !== undefined && setPage !== null) setPage(0);
    }
  }

  function removeStorage() {
    if (localStorage.getItem(searchStorage) !== null) {
      if (setPage !== undefined && setPage !== null) setPage(0);

      if (searchStorage !== undefined && searchStorage !== null) localStorage.removeItem(searchStorage);

      if (pageStorage !== undefined && pageStorage !== null) localStorage.removeItem(pageStorage);
    }
  }

  const getRecords = (value) => {
    if (isEmptyObject(backUp)) return backUp;

    return backUp.filter((row) => {
      for (let col of Object.values(columnsSearch)) {
        if (row[col] !== undefined && row[col] !== null && row[col].replace(",", "").toLowerCase().includes(value))
          return true;
      }
    });
  };

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        alignItems: "center",
        width: 450,
        boxShadow: "none",
        border: "1px solid #BFC7D0",
        boxSizing: "border-box",
        borderRadius: "8px",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={t(placeholder)}
        inputProps={{ "aria-label": "search", "data-testid": "input-search" }}
        onChange={handleChange}
        defaultValue={getSearchLocal(searchStorage)}
      />
      <IconButton type="submit" sx={{ p: "10px", color: "#343C46" }} aria-label="search" data-testid="button-search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

const SearchBack = ({ setSearch, endPoint }) => {
  const [t] = useTranslation("global");

  const [options, setOptions] = useState([]);
  const [isSearching, setIsSearching] = useState("hidden");
  const axiosPrivate = useAxiosPrivate();

  const optionLabel = (option) => {
    let value = option;

    if (option.topicId !== undefined && option.topicId !== null) value = option.search;

    return value;
  };

  const filterOptions = (option) => {
    return option;
  };

  const handleInputChange = (event) => {
    const { value } = event.target;

    if (value.length > 2) {
      setIsSearching("visible");

      axiosPrivate
        .get(endPoint, { params: { search: value } })
        .then((response) => {
          setIsSearching("hidden");
          setOptions(response.data);
        })
        .catch(() => {
          setIsSearching("hidden");
          setOptions([]);
        });
    }
  };

  const handleChange = (event, value) => {
    event.preventDefault();

    if (value !== undefined && value !== null && value !== "" && value.topicId !== undefined && value.topicId !== null)
      setSearch(value);
  };

  return (
    <Autocomplete
      data-testid="autocomplete"
      sx={{
        width: "120vh",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
      }}
      getOptionLabel={optionLabel}
      options={options}
      renderOption={(props, option) => {
        return (
          <span {...props} key={uuidv4()}>
            {option.search}
          </span>
        );
      }}
      freeSolo
      filterOptions={filterOptions}
      onChange={handleChange}
      popupIcon={<SearchIcon />}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            borderRadius: "8px",
          }}
          size="small"
          label={t("Buttons.Search")}
          onChange={handleInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                <InputAdornment position="start">
                  <CircularProgress
                    style={{
                      width: "30px",
                      height: "30px",
                      visibility: isSearching,
                    }}
                  />
                </InputAdornment>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export { SearchBack };
