import { useState } from "react";

import useSWR from "swr";

import ApisEnum from "../../utils/enums/apis.enum";

import useAxiosPrivate from "../useAxiosPrivate";

const useClients = ({ limit = 5, pag = 0 }) => {
  const [page, setPage] = useState(pag);
  const [search, setSearch] = useState("");

  const axiosClient = useAxiosPrivate(ApisEnum.Backoffice);

  const clientsFetcher = async (url) => {
    const { data } = await axiosClient.get(url);
    return data;
  };

  const { isLoading, data } = useSWR(`/clients?page=${page + 1}&search=${search}&limit=${limit}`, clientsFetcher);

  return {
    onPageChange: (pag) => setPage(pag),
    onSearchClients: (value) => {
      setSearch(value);
      setPage(0);
    },
    clients: data?.data || [],
    currentPage: parseInt(page) || 0,
    totalItems: data?.pagination?.totalItems || 0,
    isLoading,
  };
};

export default useClients;
