export default class LoanEnum {
  constructor(t) {
    this.t = t;
  }

  get loanType() {
    return {
      microloan: this.t("Backoffice.FinancialProduct.LoanType.Micro"),
      installment: this.t("Backoffice.FinancialProduct.LoanType.Installments"),
    };
  }

  get loanStatus() {
    return {
      active: this.t("Backoffice.FinancialProduct.Status.Active"),
      overdue: this.t("Backoffice.FinancialProduct.Status.Overdue"),
      paid: this.t("Backoffice.FinancialProduct.Status.Paid"),
    };
  }
}
