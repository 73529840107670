import { useIsAuthenticated, useSignIn, useSignOut } from "react-auth-kit";

/**
 * The function UseSignIn calls the useSignIn function.
 */
const UseSignIn = () => useSignIn();
/**
 * The UseSignOut function is a JavaScript arrow function that calls the useSignOut function.
 */
const UseSignOut = () => useSignOut();
/**
 * The function `UseIsAuthenticated` returns the result of the `useIsAuthenticated` hook.
 */
const UseIsAuthenticated = () => useIsAuthenticated();

export { UseSignIn, UseSignOut, UseIsAuthenticated };
