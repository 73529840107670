import { useEffect, useState } from "react";

import { InputBase } from "@mui/material";

export default function TextInput({ onChange, placeholder }) {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  useEffect(() => {
    if (onChange) {
      return onChange(value);
    }
  }, [value, onChange]);

  return (
    <InputBase
      sx={{
        width: "inherit",
      }}
      placeholder={placeholder}
      inputProps={{
        "aria-label": "search",
      }}
      onChange={handleChange}
      type="text"
      value={value}
    />
  );
}
