import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckIcon from "@mui/icons-material/Check";
import ReportProblem from "@mui/icons-material/ReportProblem";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import { v4 as uuidv4 } from "uuid";

import Theme from "../../themes/Theme";

import { isEmptyObject } from "../../utils/validateExpression";

import { BasicStatusButton } from "../aggrid/dynamicAction";
import { debounce } from "../micromerchants/components/MMSearchBar";

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: Theme.tableStyle.tableHeaders.fontWeightImportant,
  },
}));

const BasicTable = ({
  dataTable,
  dataColumns,
  action,
  condition = [],
  align = "center",
  dynamicAction = [],
  rowsPerPageOpt = [5, 10, 25, 50],
  tableType = "",
  pageValue = 0,
  rowsPerPageValue = 10,
  columnsSort = ["amount", "store_name", "date"],
  isTxtRowsPerPagHidden = true,
  totalData = null,
  getData,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(pageValue);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageValue);
  const [t] = useTranslation("global");
  const [rowData, setRowData] = useState(dataTable);

  //sort columns
  const ascending = "asc";
  const descending = "desc";

  const initArrowColumns = () => {
    let arrow = {};
    let arrowNumber = {};

    if (!isEmptyObject(columnsSort)) {
      columnsSort.forEach((column) => {
        arrow[column] = ascending;
        arrowNumber[column] = 1;
      });

      // Validamos que estemos en micromerchant
      if (tableType === "Micromerchant") {
        arrow = initArrowColumnsMicromerchant(arrow, arrowNumber);
      }
    }

    return arrow;
  };

  const initArrowColumnsMicromerchant = (arrow, arrowNumber) => {
    if (localStorage.getItem(tableType + "-sort") == null) {
      localStorage.setItem(tableType + "-sort", JSON.stringify(arrowNumber));
    } else {
      let getObjectSort = JSON.parse(localStorage.getItem(tableType + "-sort"));
      for (let elm in getObjectSort) {
        arrow[elm] = getObjectSort[elm] === 1 ? descending : ascending;
      }
    }

    return arrow;
  };

  const [arrowColumns, setArrowColumns] = useState(initArrowColumns);

  useEffect(() => {
    setRowData(dataTable);
  }, [dataTable]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    if (tableType === "Micromerchant") {
      localStorage.setItem(tableType + "-page", parseInt(newPage));
      getData();
    }
  };

  const storageRowsPerPage = useCallback(
    (event) => {
      setPage(0);
      if (tableType === "Micromerchant") {
        localStorage.setItem(tableType + "-rowsPerPage", parseInt(event));
        localStorage.setItem(tableType + "-page", parseInt(0));
        if (event != "") {
          getData();
        }
      }
    },
    [tableType]
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    storageRowsPerPage(event.target.value);
  };

  const handleChangeRowsPerPageText = useCallback(
    debounce((event) => {
      if (event.target.value !== "") {
        if (parseInt(event.target.value) < 1) {
          event.target.value = 1;
        }
        setRowsPerPage(parseInt(event.target.value, 10));
        storageRowsPerPage(event.target.value);
      } else {
        event.target.value = rowsPerPageValue;
      }
    }, 500),
    [storageRowsPerPage]
  );

  const stringParse = (text) => {
    if (isEmptyObject(text)) return "";

    return text.toString().toLowerCase();
  };

  const numberParse = (text) => {
    if (isEmptyObject(text)) return 0;

    return Number(text.toString().replace(/[^0-9.-]+/g, ""));
  };

  const sortColumn = (fieldName, fun = stringParse) => {
    return rowData.sort(sortBy(fieldName, arrowColumns[fieldName] === descending, fun));
  };

  const sortBy = (fieldName, reverse, fun) => {
    const key = function (x) {
      return fun(x[fieldName]);
    };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
      a = key(a);
      b = key(b);
      return reverse * ((a > b) - (b > a));
    };
  };

  const handleSortRequest = (col) => {
    const fieldName = col.split(".").pop();

    switch (fieldName) {
      case "amount":
        setRowData(sortColumn(fieldName, numberParse));
        break;
      case "date":
        setRowData(sortColumn(fieldName, Date.parse));
        break;
      default:
        if (tableType === "Micromerchant") {
          let getSortLocalStorage = JSON.parse(localStorage.getItem(tableType + "-sort"));
          getSortLocalStorage[fieldName] = getSortLocalStorage[fieldName] === 1 ? -1 : 1;
          localStorage.setItem(tableType + "-sort", JSON.stringify(getSortLocalStorage));
          getData();
        } else {
          setRowData(sortColumn(fieldName));
        }
        break;
    }

    directionArrow(fieldName);
  };

  const directionArrow = (fieldName) => {
    arrowColumns[fieldName] = arrowColumns[fieldName] === ascending ? descending : ascending;
    setArrowColumns({ ...arrowColumns });
  };

  if (Object.keys(dataTable).length === 0)
    return <div className="no-information-message">{t("Users.Delete.NoUsers")}</div>;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const getOrder = (fieldName) => {
    return arrowColumns[fieldName];
  };

  const getTableCell = (column, idx) => {
    const fieldName = column.split(".").pop();

    return (
      <TableCell key={idx} align={align} className={classes.header}>
        {columnsSort.includes(fieldName) ? (
          <TableSortLabel active={true} direction={getOrder(fieldName)} onClick={() => handleSortRequest(column)}>
            {t(column)}
          </TableSortLabel>
        ) : (
          t(column)
        )}
      </TableCell>
    );
  };

  const valueDesign = (result, cellData) => {
    if (result.icon === "MicromerchantsFormStatus") {
      let boton;
      const expr = cellData;
      switch (expr) {
        case "complete":
        case null:
          boton = (
            <BasicStatusButton title="Micromerchants.LevelChange.LblStatusForm" color="green" icon={<CheckIcon />} />
          );
          break;
        case "inprogress":
          boton = (
            <BasicStatusButton
              title="Micromerchants.LevelChange.LblStatusFormInProgress"
              color="yellow"
              icon={<AccessTimeIcon />}
            />
          );
          break;
        case "inreview":
          boton = (
            <BasicStatusButton
              title="Micromerchants.LevelChange.LblStatusFormInReview"
              color="orange"
              icon={<ReportProblem />}
            />
          );
          break;
      }
      return boton;
    } else {
      if (cellData) {
        return (
          <BasicStatusButton
            title="Micromerchants.LevelChange.LblStatusUser.Valid"
            color="green"
            icon={<CheckIcon />}
          />
        );
      } else {
        return (
          <BasicStatusButton
            title="Micromerchants.LevelChange.LblStatusUser.Pending"
            color="yellow"
            icon={<AccessTimeIcon />}
          />
        );
      }
    }
  };

  const cellValue = (rowData, cell, existDynamicAction) =>
    existDynamicAction ? valueDesign(dynamicAction.filter((x) => x.field === cell)[0], rowData) : rowData;

  const getDataCell = (cell) => (cell ? cell.toString() : "");

  function getCells(row, i) {
    return Object.keys(row).map((cell, index) => {
      if (index + 1 === Object.keys(row).length) {
        return setCell(row, i, cell);
      } else {
        if (!condition.includes(cell)) {
          return (
            <TableCell key={uuidv4()} align={align} style={Theme.tableStyle.tableBody}>
              {cellValue(
                getDataCell(row[cell]),
                cell,
                dynamicAction.some((item) => item.field === cell)
              )}
            </TableCell>
          );
        }
      }
    });
  }

  function setCell(row, i, cell) {
    if (condition.includes(cell)) {
      return action !== undefined && action(row, i);
    } else {
      return (
        <Fragment key={uuidv4()}>
          <TableCell key={uuidv4()} align={align} style={Theme.tableStyle.tableBody}>
            {row[cell]}
          </TableCell>
          {action !== undefined && action(row, i)}
        </Fragment>
      );
    }
  }

  return (
    <div>
      <div>
        <Paper
          sx={{
            boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
            borderRadius: "16px",
          }}
        >
          <TableContainer role="grid">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {dataColumns.map((col, idx) =>
                    col.includes("Action") ? (
                      <TableCell key={idx} align="center" colSpan="2" className={classes.header}>
                        {t(col)}
                      </TableCell>
                    ) : (
                      getTableCell(col, idx)
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableType === "Micromerchant"
                  ? rowData.map((row, i) => {
                      return <StyledTableRow key={uuidv4()}>{getCells(row, i)}</StyledTableRow>;
                    })
                  : rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                      return <StyledTableRow key={uuidv4()}>{getCells(row, i)}</StyledTableRow>;
                    })}
              </TableBody>
            </Table>
          </TableContainer>

          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              marginTop: "1%",
            }}
          >
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              hidden={isTxtRowsPerPagHidden}
              onChange={handleChangeRowsPerPageText}
              type="number"
              label={t("Insights.Transactions.Table.LabelRowsPerPage")}
              defaultValue={rowsPerPageValue}
              sx={{
                width: "110px",
              }}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOpt}
              component="div"
              count={totalData == null ? dataTable.length : totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("Insights.Transactions.Table.LabelRowsPerPage")}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default BasicTable;
