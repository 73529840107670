import React from "react";
import { useParams } from "react-router-dom";

import { ThemeProvider } from "@mui/styles";

import Theme from "../themes/Theme";

import Sesion from "../components/commons/sesion";
import OwnerDetail from "../components/owners/ownerDetail";
import Owners from "../components/owners/owners";

export default function Merchants() {
  const { ownerId } = useParams();

  return (
    <div className={"Drawer"}>
      <Sesion screen={"Merchants"} />
      <ThemeProvider theme={Theme}>
        {ownerId !== undefined ? <OwnerDetail client_id={ownerId} /> : <Owners />}
      </ThemeProvider>
    </div>
  );
}
