import React from "react";

import { ReUnixCron, Tab } from "@sbzen/re-cron";

const localization = {
  common: {
    month: {
      january: "Enero",
      february: "Febrero",
      march: "Marzo",
      april: "Abril",
      may: "Mayo",
      june: "Junio",
      july: "Julio",
      august: "Agosto",
      september: "Septiembre",
      october: "Octubre",
      november: "Noviembre",
      december: "Diciembre",
    },
    dayOfWeek: {
      sunday: "Domingo",
      monday: "Sábado",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado",
    },
  },
  tabs: {
    seconds: "Segúndos",
    minutes: "Minutos",
    hours: "Horas",
    day: "Día",
    month: "Mes",
    year: "Año",
  },
  month: {
    every: {
      label: "Cada mes",
    },
    increment: {
      label1: "Cada",
      label2: "mes(es) a partir del mes",
    },
    and: {
      label: "Mes específico (elija uno o varios)",
    },
    range: {
      label1: "Cada mes entre mes",
      label2: "y mes",
    },
  },
  unix: {
    day: {
      every: {
        label: "Cada mes",
      },
      dayOfWeekIncrement: {
        label1: "Cada",
        label2: "día(s) de la semana",
      },
      dayOfMonthIncrement: {
        label1: "Cada",
        label2: "día(s) del mes",
      },
      dayOfWeekAnd: {
        label: "Día específico de la semana (elija uno o varios)",
      },
      dayOfMonthAnd: {
        label: "Día específico del mes (elija uno o varios)",
      },
    },
    month: {
      every: {
        label: "Cada mes",
      },
      increment: {
        label1: "Cada",
        label2: "mes(s)",
      },
      and: {
        label: "Mes específico (elija uno o varios)",
      },
      range: {
        label1: "Cada mes entre mes",
        label2: "y el mes",
      },
    },
    minute: {
      every: {
        label: "Cada minuto",
      },
      increment: {
        label1: "Cada",
        label2: "minuto(s)",
      },
      and: {
        label: "Minuto específico (elija uno o varios)",
      },
      range: {
        label1: "Cada minuto entre el minuto",
        label2: "y minuto",
      },
    },
  },
};
const Createcron = ({ cronValue, setValue }) => {
  return (
    <div data-testid="cron-value">
      <ReUnixCron
        data-testid="cron-comp"
        tabs={[Tab.DAY, Tab.MONTH, Tab.YEAR]}
        value={cronValue}
        onChange={setValue}
        localization={localization}
      />
    </div>
  );
};
export default Createcron;
