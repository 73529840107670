import React, { Fragment, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Box, IconButton, Tab, Tabs, Tooltip } from "@mui/material";

import TabPanel from "../../utils/tabPanel";

import Blocked from "./blocked/Blocked";
import ChangeLevel from "./change_level/ChangeLevel";
import Inprogress from "./inprogress/Inprogress";
import Review from "./review/Review";

import countries from "../../translations/country.json";
import PermissionsGate from "../commons/PermissionsGate";

const getCountryPage = () => {
  let result = window.location.origin;
  let country = "";

  let div = result.split(".");
  for (let x in div) {
    for (let y in Object.keys(countries)) {
      if (div[x] === Object.keys(countries)[y]) {
        country = div[x].toString();
        break;
      }
    }
  }
  return country;
};

const eyedIcon = (client_id, row) => {
  return (
    <IconButton>
      <Tooltip title={"Detalle"}>
        <Link to={`/Micromerchants/${client_id}`} className="ag-link-icon" state={row} style={{ color: "#707070" }}>
          <RemoveRedEyeIcon />
        </Link>
      </Tooltip>
    </IconButton>
  );
};

const Micromerchant = () => {
  const [t] = useTranslation("global");
  const [tab, setTab] = React.useState(0);

  useEffect(() => {
    if (localStorage.getItem("tab-micromerchant") != null) {
      setTab(parseInt(localStorage.getItem("tab-micromerchant")));
    }
  }, []);

  const handleChange = useCallback((event, tabValue) => {
    localStorage.removeItem("MicromerchantDetail");
    localStorage.setItem("tab-micromerchant", parseInt(tabValue));
    setTab(tabValue);
  }, []);

  return (
    <Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "#DFE3E7;", margin: "0 0 33px 0" }}>
        <Tabs value={tab} onChange={handleChange} data-testid="tabs">
          <Tab label={t("Micromerchants.LevelChange.TabName")} data-testid="tab" value={0} />
          <Tab label={t("Micromerchants.Reviews.TabName")} data-testid="tab-revision" value={1} />
          <Tab label={t("Micromerchants.Locks.TabName")} data-testid="tab-bloqueos" value={2} />
          <Tab label={t("Micromerchants.InProgress.TabName")} data-testid="tab-en-progreso" value={3} />
        </Tabs>
      </Box>
      <PermissionsGate scopes={"resources.micromerchants"}>
        <TabPanel value={tab} index={0}>
          <ChangeLevel country={getCountryPage()} eyedIcon={eyedIcon} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Review country={getCountryPage()} eyedIcon={eyedIcon} />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Blocked country={getCountryPage()} eyedIcon={eyedIcon} />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Inprogress country={getCountryPage()} eyedIcon={eyedIcon} />
        </TabPanel>
      </PermissionsGate>
    </Fragment>
  );
};

export { Micromerchant, eyedIcon };
