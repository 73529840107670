import { useState } from "react";

import useSWR from "swr";

import ApisEnum from "../../utils/enums/apis.enum";

import useAxiosPrivate from "../useAxiosPrivate";

const useInvoices = ({ clientId, limit = 5, pag = 0 }) => {
  const [page, setPage] = useState(pag);
  const [filter, setFilter] = useState({
    dateFrom: "",
    dateTo: "",
  });

  const axiosClient = useAxiosPrivate(ApisEnum.Backoffice);

  const invoicesFetcher = async (url) => {
    const { data } = await axiosClient.get(url);
    return data;
  };

  const { isLoading, data } = useSWR(
    () =>
      `/invoices?clientId=${clientId}&page=${page + 1}&limit=${limit}&dateFrom=${filter.dateFrom}&dateTo=${
        filter.dateTo
      }`,
    invoicesFetcher
  );

  return {
    onPageChange: (page) => setPage(page),
    onFilterChange: (filter) => {
      setFilter(filter);
      setPage(0);
    },
    isLoading,
    invoices: data?.data || [],
    currentPage: parseInt(page) || 0,
    totalItems: data?.pagination?.totalItems || 0,
  };
};

export default useInvoices;
