import { useEffect, useState } from "react";

import { Clear } from "@mui/icons-material";
import { FormControl, IconButton, MenuItem, Select } from "@mui/material";

export default function SelectInput({ placeholder, onChange, options = [] }) {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  const handleClear = () => {
    setValue("");
  };

  useEffect(() => {
    if (onChange) {
      return onChange(value);
    }
  }, [value, onChange]);

  return (
    <FormControl
      sx={{
        width: "inherit",
      }}
    >
      <Select
        variant="standard"
        disableUnderline
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        sx={{
          "& .MuiSelect-icon": {
            display: value ? "none" : "",
          },
        }}
        displayEmpty
        endAdornment={
          <IconButton size="small" sx={{ display: value ? "" : "none" }} onClick={handleClear}>
            <Clear />
          </IconButton>
        }
        renderValue={(value) => {
          if (value === "") {
            return <div style={{ color: "grey" }}>{placeholder}</div>;
          }

          return value;
        }}
        data-testid="my-wrapper"
      >
        {options?.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
