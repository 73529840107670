import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputBase, Paper } from "@mui/material";

const MMSearchBar = (props) => {
  const { type, placeholder, onSearch = () => {}, resetStorage = false, disableDetailsView = false } = props;
  const [t] = useTranslation("global");
  const [searchInput, setSearchInput] = useState("");
  const searchStorage = `${type}-search`;
  const pageStorage = `${type}-page`;
  const typeStorage = `${type}Detail`;

  useEffect(() => {
    const detailsView = localStorage.getItem(typeStorage);
    const initSearchValue = detailsView || disableDetailsView ? localStorage.getItem(searchStorage) : null;
    setSearchInput(initSearchValue ?? "");

    return () => {
      if (resetStorage) localStorage.removeItem(searchStorage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((value) => {
      const searchValue = value.toLowerCase();
      localStorage.setItem(searchStorage, searchValue);
      if (localStorage.getItem(pageStorage) != null) localStorage.setItem(pageStorage, 0);
      onSearch(searchValue);
    }, 500),
    [onSearch, searchStorage]
  );

  const handlerSearch = (event) => {
    event.preventDefault();
    const target = event.target.tagName === "FORM" ? event.target[0] : event.target;
    setSearchInput(target?.value ?? "");
    debouncedChangeHandler(target?.value ?? "");
  };

  return (
    <Paper
      component="form"
      onSubmit={handlerSearch}
      sx={{
        display: "flex",
        alignItems: "center",
        width: 450,
        boxShadow: "none",
        border: "1px solid #BFC7D0",
        boxSizing: "border-box",
        borderRadius: "8px",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={t(placeholder)}
        inputProps={{ "aria-label": "search", "data-testid": "input-search" }}
        onChange={handlerSearch}
        value={searchInput}
      />
      <IconButton type="submit" sx={{ p: "10px", color: "#343C46" }} aria-label="search" data-testid="button-search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  };
};

export default MMSearchBar;
