import { useEffect, useState } from "react";

import { InputLabel, Paper } from "@mui/material";
import PropTypes from "prop-types";

import DateInput from "./dateInput";
import SelectInput from "./selectInput";
import TextInput from "./textInput";

/**
 * Custom Input component
 * @param {string} props.placeholder The hint displayed in the input
 * @param {Function} props.onChange The function to be called when the input changes, receives the new value as a string parameter
 * @param {string} props.title The title of the input, if not provided, no title will be displayed
 * @param {('text'|'date'|'select')} props.type The type of the input, if not provided, the default type is text
 * @param {Array} props.options The options to be displayed in the select input, only used if the type is select
 */
const CustomInput = ({ placeholder, onChange, title, type = "text", options }) => {
  const [value, setValue] = useState("");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (onChange) {
      return onChange(value);
    }
  }, [value, onChange]);

  return (
    <div style={{ width: "100%" }}>
      {title && <label>{title}</label>}
      <Paper
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          width: "inherit",
          height: "40px",
          boxShadow: "none",
          border: "1px solid #BFC7D0",
          boxSizing: "border-box",
          borderRadius: "16px",
          padding: "16px",
          position: "relative",
        }}
      >
        {value && (
          <>
            <div
              style={{
                position: "absolute",
                top: "-1px",
                left: "13px",
                backgroundColor: "white",
                padding: "0 5px",
                height: "12px",
                "&:before": {
                  content: "''",
                  position: "absolute",
                  top: "-1px",
                  left: "-1px",
                  right: "-1px",
                  bottom: "-1px",
                  border: "1px solid white",
                  zIndex: "0",
                },
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  visibility: "hidden",
                }}
              >
                {placeholder}
              </span>
            </div>
            <InputLabel
              id="select-label"
              sx={{
                position: "absolute",
                top: "-8px",
                left: "16px",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              {placeholder}
            </InputLabel>
          </>
        )}
        {type === "date" && <DateInput placeholder={placeholder} onChange={handleChange} />}
        {type === "select" && <SelectInput placeholder={placeholder} onChange={handleChange} options={options} />}
        {type === "text" && <TextInput placeholder={placeholder} onChange={handleChange} />}
      </Paper>
    </div>
  );
};

CustomInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.oneOf(["text", "date", "select"]),
  options: PropTypes.array,
};

export default CustomInput;
