import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  CardContent,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableCell,
  Tooltip,
} from "@mui/material";

import BasicTable from "../../commons/basicTable";
import ModalStatusMM, { TypeModals } from "../../commons/modalStatusMM";
import MMSearchBar from "../components/MMSearchBar";
import { getKyxMicromerchants } from "../data/repositories/micromerchant_repository";

const Review = (props) => {
  const { country } = props;
  const [t] = useTranslation("global");
  const [tblLoading, setLoading] = useState(true);
  const [lvl, setLvl] = useState("");
  const [data, setData] = useState([]);
  const [dataBackUp, setDataBackUp] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowsPerPage, setRowPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [reviewColumnSort, setReviewColumnSort] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [mmSelected, setMmSelected] = useState([]);

  const [totalInReview, setTotalInreview] = useState(0);
  const [filterReview, setFilterReview] = useState({});
  const [reviewValueSort, setReviewValueSort] = useState({});

  const handleShowTbl = () => setLoading(false);
  const handleHideTbl = () => setLoading(true);

  // Localstorage
  const reviewMMTable = "Micromerchant";
  const reviewMMDetailStorage = `${reviewMMTable}Detail`;
  const reviewMMrowsPerPageStorage = `${reviewMMTable}-rowsPerPage`;
  const reviewMMPageStorage = `${reviewMMTable}-page`;
  const reviewMMLevelStorage = `${reviewMMTable}-level`;
  const reviewMMSearchStorage = `${reviewMMTable}-search`;
  const reviewMMSearchSort = `${reviewMMTable}-sort`;

  useEffect(() => {
    if (localStorage.getItem(reviewMMDetailStorage) != null) {
      buildLocalStorage("set");
    } else {
      buildLocalStorage("remove");
    }
    getData()
      .then(() => console.log)
      .finally(() => console.log);
  }, []);

  const buildLocalStorage = (action) => {
    let params = {};
    if (action === "set") {
      if (localStorage.getItem(reviewMMrowsPerPageStorage) != null) {
        setRowPerPage(parseInt(localStorage.getItem(reviewMMrowsPerPageStorage)));
      }
      if (localStorage.getItem(reviewMMPageStorage) != null) {
        setPage(parseInt(localStorage.getItem(reviewMMPageStorage)));
      }
      if (localStorage.getItem(reviewMMLevelStorage) != null) {
        setLvl(localStorage.getItem(reviewMMLevelStorage));
        if (localStorage.getItem(reviewMMLevelStorage) != "") {
          params = {
            ...params,
            level: localStorage.getItem(reviewMMLevelStorage),
          };
        }
      }
      if (localStorage.getItem(reviewMMSearchStorage) != null) {
        params = {
          ...params,
          search_field: localStorage.getItem(reviewMMSearchStorage),
        };
      }
      if (localStorage.getItem(reviewMMSearchSort) != null) {
        setReviewValueSort(JSON.parse(localStorage.getItem(reviewMMSearchSort)));
      }
    } else {
      localStorage.removeItem(reviewMMrowsPerPageStorage);
      localStorage.removeItem(reviewMMPageStorage);
      localStorage.removeItem(reviewMMLevelStorage);
      localStorage.removeItem(reviewMMSearchStorage);
      localStorage.removeItem(reviewMMSearchSort);
    }
    Object.assign(filterReview, params);
  };

  const translateColumns = (cols) => {
    let trans = "Micromerchants.Reviews.Columns.";
    let arrColumns = [];

    for (let col of Object.values(cols)) {
      arrColumns.push(trans.concat(col));
    }
    setColumns(arrColumns);
  };

  const getData = async () => {
    handleHideTbl();
    const search = filterReview;
    let countPage = page + 1;
    let countRowPerPage = rowsPerPage;
    let sortReview = reviewValueSort;

    if (localStorage.getItem(reviewMMPageStorage) != null) {
      countPage = parseInt(localStorage.getItem(reviewMMPageStorage)) + 1;
    }

    if (localStorage.getItem(reviewMMrowsPerPageStorage) != null) {
      countRowPerPage = parseInt(localStorage.getItem(reviewMMrowsPerPageStorage));
    }

    if (localStorage.getItem(reviewMMSearchSort) != null) {
      sortReview = JSON.parse(localStorage.getItem(reviewMMSearchSort));
    }

    const response = await getKyxMicromerchants({
      country: country,
      page: countPage,
      limit: countRowPerPage,
      search: search,
      sort: sortReview,
      status: "inreview",
    });
    setColumns(response.data.columns);
    translateColumns(response.data.columns);
    setReviewColumnSort(response.data.sort);
    setTotalInreview(response.data.total_micromerchant);
    setData(response.data.results);
    setDataBackUp(response.data.results);
    setFilterReview(search);
    setReviewValueSort(sortReview);
    handleShowTbl();
  };

  const handleChange = useCallback(async (e) => {
    const value = e.target.value;
    localStorage.setItem(reviewMMLevelStorage, value);
    localStorage.setItem("Micromerchant-page", 0);
    setLvl(value);
    setPage(0);

    delete filterReview["level"];
    const reviewFilters = value !== "" ? Object.assign({}, { ...filterReview, level: value }) : filterReview;
    Object.assign(filterReview, reviewFilters);
    await getData();
  }, []);

  const handleSearch = async (value) => {
    const reviewFilters = {
      ...filterReview,
      search_field: value,
    };
    Object.assign(filterReview, reviewFilters);
    await getData();
  };

  const changeDataTable = useCallback(() => {}, []);

  const handleChangeModal = (type, row) => {
    setMmSelected(row);
    setTypeModal(type);
    setShowModal(true);
  };

  const buildActions = useCallback((row) => {
    return (
      <Fragment>
        <Fragment>
          <TableCell
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <IconButton data-testid="btn-icon-approve-mm" onClick={() => handleChangeModal(TypeModals.Approve, row)}>
              <Tooltip title={"Aprobar"}>
                <AssignmentTurnedInIcon className="Cursor" />
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => handleChangeModal(TypeModals.Blocked, row)} data-testid="btn-blocked-review">
              <Tooltip title={"Bloquear"}>
                <LockIcon className="Cursor" style={{ color: "#707070" }} />
              </Tooltip>
            </IconButton>
            {props.eyedIcon(row.client_id, row)}
          </TableCell>
        </Fragment>
      </Fragment>
    );
  }, []);

  const Table = useCallback(() => {
    return (
      <BasicTable
        dataTable={data}
        dataColumns={columns}
        action={buildActions}
        condition={["is_active"]}
        dynamicAction={[{ field: "user_status", icon: "MicromerchantsUserStatus" }]}
        rowsPerPageOpt={[]}
        tableType={reviewMMTable}
        rowsPerPageValue={
          localStorage.getItem("Micromerchant-rowsPerPage") !== null
            ? parseInt(localStorage.getItem("Micromerchant-rowsPerPage"))
            : 50
        }
        pageValue={
          localStorage.getItem("Micromerchant-page") !== null ? parseInt(localStorage.getItem("Micromerchant-page")) : 0
        }
        columnsSort={reviewColumnSort}
        isTxtRowsPerPagHidden={false}
        totalData={totalInReview}
        getData={getData}
      />
    );
  }, [data, page, rowsPerPage]);

  return (
    <Fragment>
      <ModalStatusMM
        row={mmSelected}
        setMmSelected={setMmSelected}
        typeModal={typeModal}
        openModal={showModal}
        setShowModal={setShowModal}
        data={data}
        setData={setData}
        dataBackUp={dataBackUp}
        setBackUp={setDataBackUp}
        setPage={setPage}
        country={country}
        changeDataTable={changeDataTable}
      />
      <Stack width="100%" direction="row" mb={2} justifyContent="start">
        <Stack direction="row" width="30%" mb={2}>
          <MMSearchBar type={reviewMMTable} placeholder={"Micromerchants.Reviews.Search"} onSearch={handleSearch} />
        </Stack>

        <Stack width="15%" mb={2} ml={2}>
          <FormControl>
            <InputLabel id="select-change-level">{t("Micromerchants.Reviews.Filters.Level.SelectLevel")}</InputLabel>
            <Select
              labelId="select-change-level"
              id="select-change-level-helper"
              value={lvl}
              label="level"
              onChange={handleChange}
              data-testid="select-change-level-test"
              sx={{ paddingBottom: "0px", borderRadius: "16px" }}
            >
              <MenuItem value="">
                <em>{t("Micromerchants.Reviews.Filters.Level.SelectOption")}</em>
              </MenuItem>
              <MenuItem value={"5000"}>5000</MenuItem>
              <MenuItem value={"6000"}>6000</MenuItem>
              <MenuItem value={"otros"}>Otros</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Fragment>
        {tblLoading ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <div>Loading ...</div>
            <CardContent data-testid={"loading-card-micromerchant-review"}>
              <CircularProgress data-testid={"loading-circular-progress-micromerchant-review"} color="warning" />
            </CardContent>
          </Box>
        ) : (
          <Stack>
            <Table />
          </Stack>
        )}
      </Fragment>
    </Fragment>
  );
};

export default Review;
