import { useTranslation } from "react-i18next";

import { createStyles, makeStyles } from "@material-ui/core";
import SyncIcon from "@mui/icons-material/Sync";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 2s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  })
);

const IconReload = ({ props }) => {
  const [t] = useTranslation("global");
  const classes = useStyles();

  return (
    <span className="ag-icon-default">
      <Tooltip title={t("Micromerchants.LevelChange.ToolTip.ReloadAction")}>
        <IconButton
          data-testid="icon-action-reload"
          className="Cursor"
          onClick={(e) => {
            props.reload(props.data, e);
          }}
          style={{ padding: "2px", paddingTop: "10px", paddingBottom: "10px" }}
        >
          <SyncIcon data-testid="sync-icon-id" className={props.data?.isLoading ? classes.rotateIcon : ""} />
        </IconButton>
      </Tooltip>
    </span>
  );
};

const SwitchAction = ({ props, active }) => {
  return (
    <Switch
      data-testid="switch-button-dynamic"
      disabled={props.data?.isLoading}
      checked={active}
      onChange={(e) => {
        props.reload(props.data, e);
      }}
      inputProps={{
        "aria-label": "controlled",
        "data-testid": "checkId",
      }}
      sx={{ marginTop: -1 }}
    />
  );
};

const DynamicAsyncAction = (props) => {
  let dynamicAction = null;

  switch (props.action) {
    case "ReloadAction":
      dynamicAction = <IconReload props={props} />;
      break;
    case "SwitchAction":
      dynamicAction = <SwitchAction props={props} active={props?.active} />;
      break;
    default:
      dynamicAction = <div data-testid="dynamic-without-icon"></div>;
      break;
  }
  return dynamicAction;
};

export default DynamicAsyncAction;
