import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import { Avatar, TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import noBatch from "../../img/noBatch.svg";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ApisEnum from "../../utils/enums/apis.enum";

import DeleteModal from "./deleteModal";
import SendModal from "./sendModal";

import RecordsNumbers from "../../components/batchs/recordsnumbers";

const styles = {
  avatarSize: {
    width: 88,
    height: 88,
    marginRight: "12px",
  },
  tableRow: {
    background: "#E7EFF9",
    padding: 0,
  },
  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
};

export function sumRiskQuantities(jsonData) {
  let clientsUsedBatches = 0;
  let totalCount = 0;
  let risks = {};
  for (let offer of jsonData.used_offers) {
    clientsUsedBatches += offer.quantity_per_risk;
    if (!risks[offer.name]) {
      risks[offer.name] = 0;
    }
    risks[offer.name] -= offer.quantity_per_risk;
  }
  for (let offer of jsonData.direct_offers_available) {
    totalCount += offer.count;
    if (!risks[offer.risk_level]) {
      risks[offer.risk_level] = 0;
    }
    risks[offer.risk_level] += offer.count;
  }
  for (let offer of jsonData.batch_offers_available) {
    totalCount += offer.count;
    if (!risks[offer.risk_level]) {
      risks[offer.risk_level] = 0;
    }
    risks[offer.risk_level] += offer.count;
  }
  let aviableClients = totalCount - clientsUsedBatches;
  return {
    aviableClients: Math.floor(aviableClients),
    clientsUsedBatches: Math.floor(clientsUsedBatches),
    risks: Object.entries(risks).map(([name, amount]) => ({
      name: name.replace(/_/g, ""),
      amount: Math.floor(amount),
    })),
  };
}

export function formatDate(dateTime) {
  const date = new Date(dateTime);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month < 10 ? "0" + month : month}/${year}`;
}

export function getDataBase(listDataBase) {
  const groupedData = {};

  listDataBase.forEach((item) => {
    const { class: className, quantity, risk, risk_percentage } = item;

    if (groupedData[className]) {
      groupedData[className].quantity = quantity;
      groupedData[className].risks[risk] = (risk_percentage * 100).toFixed(0);
    } else {
      groupedData[className] = {
        quantity,
        risks: {
          [risk]: (risk_percentage * 100).toFixed(0),
        },
      };
    }
  });

  const resultList = Object.keys(groupedData).map((className) => ({
    class: className,
    quantity: groupedData[className].quantity,
    ...groupedData[className].risks,
  }));

  return resultList;
}

const BatchEmptyState = () => {
  const [t] = useTranslation("global");

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Avatar alt="not batch" src={noBatch} sx={styles.avatarSize} />
        <Typography
          variant="h5"
          sx={{
            marginTop: "-12px",
          }}
        >
          {t("Risk.NoBatchesAddes")}
        </Typography>
      </Grid>
    </Container>
  );
};

export function Row(props) {
  const { row, index, updateOpen, open, selected, onSelected, onDelete } = props;
  const [t] = useTranslation("global");

  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          background: index % 2 === 0 ? "white" : "#DDDFE1",
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            data-testid="selectOne"
            checked={selected}
            onChange={() => onSelected(!selected, row)}
            indeterminate={false}
            color="primary"
          />
        </TableCell>
        <TableCell align="left">{row.id}</TableCell>
        <TableCell align="center">{formatDate(row.created_date)}</TableCell>
        <TableCell align="center">{row.sent}</TableCell>
        <TableCell align="center">{row.audiences}</TableCell>
        <TableCell align="center">{row.used_clients}</TableCell>
        <TableCell align="center">
          <IconButton
            data-testid="test-delete-batch"
            sx={{ color: "black" }}
            size="small"
            onClick={() => onDelete(row)}
          >
            <DeleteForeverOutlinedIcon />
          </IconButton>
          <IconButton sx={{ color: "black" }} aria-label="expand row" size="small" onClick={() => updateOpen(index)}>
            {open === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow style={styles.tableRow}>
        <TableCell style={styles.tableCell} colSpan={7}>
          <Collapse in={open === index} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Typography variant="subtitle1">
                {t("Risk.DatabaseUsed")}: <span style={{ fontWeight: "400" }}>{row.base}</span>
              </Typography>
              <Table size="medium" aria-label="purchases" align="right" sx={{ marginBottom: 3, paddingTop: 0 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Risk.Class")}</TableCell>
                    <TableCell align="left">{t("Risk.AmountCostumers")}</TableCell>
                    <TableCell align="left" sx={{ width: 800 }}>
                      {t("Risk.RiskLevelPercentages")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getDataBase(row.details).map((detail, index) => (
                    <TableRow key={detail.date} sx={{ background: index % 2 === 0 ? "white" : "#DDDFE1" }}>
                      <TableCell>{detail.class}</TableCell>
                      <TableCell align="left">{detail.quantity}</TableCell>
                      <TableCell align="left">
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={2} md={10}>
                            <Grid sx={{ fontWeight: "bold" }} item md={2}>
                              {t("Risk.VeryLow")}
                            </Grid>
                            <Grid sx={{ fontWeight: "bold" }} item md={2}>
                              {t("Risk.Low")}
                            </Grid>
                            <Grid sx={{ fontWeight: "bold" }} item md={2}>
                              {t("Risk.Medium")}
                            </Grid>
                            <Grid sx={{ fontWeight: "bold" }} item md={2}>
                              {t("Risk.High")}
                            </Grid>
                            <Grid sx={{ fontWeight: "bold" }} item md={3}>
                              {t("Risk.VeryHigh")}
                            </Grid>
                            <Grid item md={2}>
                              {detail.Very_Low}%
                            </Grid>
                            <Grid item md={2}>
                              {detail.Low}%
                            </Grid>
                            <Grid item md={2}>
                              {detail.Medium}%
                            </Grid>
                            <Grid item md={2}>
                              {detail.High}%
                            </Grid>
                            <Grid item md={3}>
                              {detail.Very_High}%
                            </Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function RegisterTable() {
  const [t] = useTranslation("global");
  const axiosPrivateRisk = useAxiosPrivate(ApisEnum.Risk);
  const navigate = useNavigate();

  const [aviableClients, setAviableClients] = useState(0);
  const [ClientsUsedBatches, setClientsUsedBatches] = useState(0);
  const [risks, setRisks] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(-1);
  const [selected, setSelected] = React.useState([]);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletedBatch, setDeletedBatch] = useState();

  const selectedRow = (id) => selectedAll || selected.some((s) => s.id === id);

  useEffect(() => {
    fetchRisks();
  }, []);

  const fetchRisks = () => {
    axiosPrivateRisk
      .get("/batch/info", {
        params: {
          base: "ALL",
        },
      })
      .then((response) => {
        const result = sumRiskQuantities(response.data);
        setAviableClients(result.aviableClients);
        setClientsUsedBatches(result.clientsUsedBatches);
        setRisks(result.risks);
      });
  };

  useEffect(() => {
    fetchBatches();
  }, [page, rowsPerPage]);

  const fetchBatches = () => {
    setLoading(true);
    axiosPrivateRisk
      .get("/batches", {
        params: {
          page: page + 1,
          pageSize: rowsPerPage,
          publishable: false,
        },
      })
      .then((response) => {
        setData(response.data.data);
        setTotalItems(response.data.totalItems);
        setLoading(false);
      });
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateOpen = (newState) => {
    if (newState === open) {
      setOpen(-1);
    } else {
      setOpen(newState);
    }
  };

  const handleSelectAll = () => {
    setSelected([]);
    setSelectedAll(!selectedAll);
  };

  const handleSelectedRow = (selected, selectedRow) => {
    if (selected) {
      setSelected((prev) => [selectedRow, ...prev]);
    } else {
      setSelected((prev) => prev.filter((r) => r.id != selectedRow.id));
    }
  };

  const formatNumber = (number) => {
    if (number !== "") {
      let dollarUSLocale = Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
      });
      number = dollarUSLocale.format(parseInt(number));
    }
    return number;
  };

  const handleActivation = (activatedBatches) => {
    setSelected([]);
    if (activatedBatches.length !== 0) {
      fetchBatches();
    } else {
      setData([]);
      setTotalItems(0);
    }
  };

  const handleDeleteBatch = (batch) => {
    setDeletedBatch(batch);
    setDeleteModalOpen(true);
  };

  const handleDeleteBatchSuccess = () => {
    fetchRisks();
    fetchBatches();
  };

  return (
    <>
      <SendModal
        open={activateModalOpen}
        selectedBatches={selected}
        onSuccess={handleActivation}
        handleClose={() => setActivateModalOpen(false)}
      />
      <DeleteModal
        open={deleteModalOpen}
        batch={deletedBatch}
        onSuccess={handleDeleteBatchSuccess}
        handleClose={() => setDeleteModalOpen(false)}
      />
      <div>
        <Grid container marginTop={2} direction="row" justifyContent="flex-end" alignItems="center">
          <Button
            style={{ marginRight: "12px" }}
            variant={totalItems ? "solid" : "disabled"}
            startIcon={<NearMeOutlinedIcon />}
            disabled={!totalItems}
            onClick={() => setActivateModalOpen(true)}
          >
            {selected.length ? t("Risk.ActivateSelection") : t("Risk.ActivateAll")}
          </Button>
          <Button
            variant={aviableClients ? "solid" : "disabled"}
            startIcon={<AddIcon />}
            disabled={!aviableClients}
            onClick={() => navigate("/Risk/CreateBatch")}
          >
            {t("Risk.CreateBatch")}
          </Button>
        </Grid>

        <CardContent>
          <RecordsNumbers
            ClientsUsedBatches={ClientsUsedBatches}
            AviableClients={formatNumber(aviableClients)}
            InitialRisks={risks}
          />
        </CardContent>
        <div style={{ paddingTop: "1%" }}>
          {totalItems === 0 ? (
            !loading && <BatchEmptyState />
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        data-testid="selectAll"
                        checked={selectedAll}
                        color="primary"
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell align="left">{t("Risk.BatchId")}</TableCell>
                    <TableCell align="center">{t("Risk.CreatedDate")}</TableCell>
                    <TableCell align="center">{t("Risk.ShipmentNumber")}</TableCell>
                    <TableCell align="center">{t("Risk.NumberOfAudiences")}</TableCell>
                    <TableCell align="center">{t("Risk.UsedClients")}</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody data-testid="batches-table">
                  {data.map((row, index) => {
                    return (
                      <Row
                        key={row.id}
                        row={row}
                        index={index}
                        open={open}
                        updateOpen={updateOpen}
                        selected={selectedRow(row.id)}
                        onSelected={handleSelectedRow}
                        onDelete={handleDeleteBatch}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={totalItems}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("Insights.Transactions.Table.LabelRowsPerPage")}
                labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
                  return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}`;
                }}
              />
            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
}
