import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import { MenuItem, Stack, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ApisEnum from "../../utils/enums/apis.enum";

import NoShipmentHistory from "./NoShipmentHistory";
import StopModal from "./stopModal";

const status_values = {
  FINISHED: "FINISHED",
  IN_PROGRESS: "IN_PROGRESS",
  STOPPED: "STOPPED",
};

const styles = {
  [status_values.FINISHED]: {
    color: "#66BC6B",
  },
  [status_values.IN_PROGRESS]: {
    color: "#2D6DDD",
  },
  [status_values.STOPPED]: {
    color: "#708195",
  },
};

export function formatDate(date) {
  if (!date) {
    return "-";
  }
  const fecha = new Date(date);
  const dia = fecha.getDate().toString().padStart(2, "0");
  const mes = (fecha.getMonth() + 1).toString().padStart(2, "0"); // El mes se indexa desde 0, por eso se suma 1
  const anio = fecha.getFullYear().toString();
  return dia + "/" + mes + "/" + anio;
}

export function buildData(data) {
  return data.map((rowData) => {
    let ePendientes = rowData.sent - rowData.finished;
    if ("statusCode" in rowData.details) {
      return {
        loteId: rowData.id,
        activatedDate: rowData.activated_at,
        fechaEnvio: rowData.created_date,
        enviosProgramados: rowData.sent,
        enviosExitosos: rowData.finished,
        enviosPendientes: ePendientes,
        usedClients: rowData.used_clients,
        audiences: rowData.audiences,
        fechaUltimoEnvio: rowData.publication_date,
        base: rowData.base,
        Status: "Error",
        totalItems: rowData.totalItems,
        details: [],
      };
    } else {
      let detailsArray = rowData.details.reduce((result, detail) => {
        let existingItem = result.find((item) => item.class === detail.class);

        if (existingItem) {
          let riskIndex = existingItem.risk.findIndex((riskObj) => riskObj.risk === detail.risk);
          if (riskIndex !== -1) {
            existingItem.risk[riskIndex].risk_percentage = detail.risk_percentage;
          } else {
            existingItem.risk.push({
              risk: detail.risk,
              risk_percentage: detail.risk_percentage,
            });
          }
        } else {
          result.push({
            class: detail.class,
            quantity: detail.quantity,
            risk: [{ risk: detail.risk, risk_percentage: detail.risk_percentage }],
          });
        }

        return result;
      }, []);

      const formattedDetails = detailsArray.reduce((formatted, item) => {
        const muyBajoRisk = item.risk.find((r) => r.risk === "Very_Low");
        const bajoRisk = item.risk.find((r) => r.risk === "Low");
        const medioRisk = item.risk.find((r) => r.risk === "Medium");
        const altoRisk = item.risk.find((r) => r.risk === "High");
        const muyAltoRisk = item.risk.find((r) => r.risk === "Very_High");

        formatted.push({
          clase: item.class,
          cantidadClientes: item.quantity,
          muyBajo: muyBajoRisk ? `${muyBajoRisk.risk_percentage * 100}%` : "0%",
          bajo: bajoRisk ? `${bajoRisk.risk_percentage * 100}%` : "0%",
          medio: medioRisk ? `${medioRisk.risk_percentage * 100}%` : "0%",
          alto: altoRisk ? `${altoRisk.risk_percentage * 100}%` : "0%",
          muyAlto: muyAltoRisk ? `${muyAltoRisk.risk_percentage * 100}%` : "0%",
        });

        return formatted;
      }, []);

      return {
        loteId: rowData.id,
        activatedDate: rowData.activated_at,
        fechaEnvio: rowData.created_date,
        enviosProgramados: rowData.sent,
        enviosExitosos: rowData.finished,
        enviosPendientes: ePendientes,
        usedClients: rowData.used_clients,
        audiences: rowData.audiences,
        fechaUltimoEnvio: rowData.publication_date,
        base: rowData.base,
        Status: rowData.status,
        totalItems: rowData.totalItems,
        details: formattedDetails,
      };
    }
  });
}

export function Row(props) {
  const [t] = useTranslation("global");

  const { row, index, handleStopBatch } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={index % 2 === 0 ? { backgroundColor: "#FFFFFF" } : { backgroundColor: "#DDDFE1" }}>
        <TableCell component="th" scope="row">
          {row.loteId}
        </TableCell>
        <TableCell align="center">{formatDate(row.activatedDate)}</TableCell>
        <TableCell align="center">{row.enviosProgramados}</TableCell>
        <TableCell align="center">{row.enviosExitosos}</TableCell>
        <TableCell align="center">{row.enviosPendientes}</TableCell>
        <TableCell align="center">
          <Typography sx={row.Status ? styles[row.Status] : {}}>{row.Status ? t(`Risk.${row.Status}`) : ""}</Typography>
        </TableCell>
        <TableCell align="right">
          {row.Status === status_values.IN_PROGRESS &&
          <IconButton title={t("Risk.Stop")} onClick={() => handleStopBatch(row)} sx={{ color: "#09121F" }}>
            <StopCircleOutlinedIcon />
          </IconButton>}
          <IconButton
            data-testid="keyboardArrow"
            aria-label="expand row"
            size="small"
            sx={{ color: "#09121F" }}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#E7EFF9",
          }}
          colSpan={8}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="16px" gutterBottom component="div" sx={{ mr: 2 }}>
                  {t("Risk.CreateDate")}: {formatDate(row.fechaEnvio)}
                </Typography>
                <Typography variant="16px" gutterBottom component="div" sx={{ ml: 2 }}>
                  {t("Risk.LastShipmentDate")}: {formatDate(row.fechaUltimoEnvio)}
                </Typography>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="16px" gutterBottom component="div" sx={{ mr: 2 }}>
                  {t("Risk.CreatedAudiences")}: {row.audiences}
                </Typography>
                <Typography variant="16px" gutterBottom component="div" sx={{ mL: 2 }}>
                  {t("Risk.UsedClients")}: {row.usedClients}
                </Typography>
              </div>
              <Typography variant="16px" gutterBottom component="div">
                {t("Risk.DatabaseUsed")}: {row.base}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Risk.Class")}</TableCell>
                    <TableCell align="left">{t("Risk.AmountCostumers")}</TableCell>
                    <TableCell align="left">{t("Risk.RiskLevelPercentages")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details.map((rowDetails, index) => (
                    <TableRow
                      key={index}
                      sx={index % 2 === 0 ? { backgroundColor: "#F8F9FA" } : { backgroundColor: "#DDDFE1" }}
                    >
                      <TableCell align="left">{rowDetails.clase}</TableCell>
                      <TableCell align="left">{rowDetails.cantidadClientes}</TableCell>
                      <TableCell align="left">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div>
                            <Typography variant="16px" sx={{ mr: 6 }}>
                              {t("Risk.VeryLow")}
                            </Typography>
                            <Typography>{rowDetails.muyBajo}</Typography>
                          </div>
                          <div>
                            <Typography variant="16px" sx={{ mr: 6 }}>
                              {t("Risk.Low")}
                            </Typography>
                            <Typography>{rowDetails.bajo}</Typography>
                          </div>
                          <div>
                            <Typography variant="16px" sx={{ mr: 6 }}>
                              {t("Risk.VeryLow")}
                            </Typography>
                            <Typography>{rowDetails.medio}</Typography>
                          </div>
                          <div>
                            <Typography variant="16px" sx={{ mr: 6 }}>
                              {t("Risk.High")}
                            </Typography>
                            <Typography>{rowDetails.alto}</Typography>
                          </div>
                          <div>
                            <Typography fontSize="16px">{t("Risk.VeryHigh")}</Typography>
                            <Typography fontSize="16px">{rowDetails.muyAlto}</Typography>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const ShippingHistory = ({ data = [] }) => {
  const [t] = useTranslation("global");
  const axiosPrivateRisk = useAxiosPrivate(ApisEnum.Risk);
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [idBatch, setIdBatch] = useState("");
  const [statusBatches, setStatusBatches] = useState("");
  const [date, setDate] = React.useState("");
  const [rows, setRows] = React.useState(data);
  const [colorSearch, setColorSearch] = React.useState(false);
  const [stoppedBatch, setStoppedBatch] = useState();
  const [stopModalOpen, setStopModalOpen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [parameters, setParameters] = React.useState({
    page: page + 1,
    pageSize: rowsPerPage,
    publishable: true,
  });

  useEffect(() => {
    getShippingData();
  }, [page, rowsPerPage, parameters]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangeSearchById = (event) => {
    let value = event.target.value;
    if (value !== " ") {
      value = value.replace(/[^0-9]/g, "");
      setIdBatch(value);
      setColorSearch(true);
    }
    if (value === "") {
      setColorSearch(false);
    }
  };
  const handleChangeSearchByDate = (event) => {
    const val = event.target.value;
    setDate(val);
    setColorSearch(true);
    if (val === "") {
      setColorSearch(false);
    }
  };
  const handleChangeSearchByStatus = (status_search) => {
    setStatusBatches(status_search);
  };

  const handleBuildParams = () => {
    const currentParams = {
      page: page + 1,
      pageSize: rowsPerPage,
      publishable: true,
      ...(statusBatches !== ("" && undefined) && { status: statusBatches }),
      ...(date !== ("" && undefined) && { date }),
      ...(idBatch !== ("" && undefined) && { id_batch: idBatch }),
    };
    setColorSearch(false);
    setParameters(currentParams);
    setPage(0);
  };

  const handleStopBatch = (batch) => {
    setStopModalOpen(true);
    setStoppedBatch(batch);
  };

  const handleStopBatchSuccess = (batchId) => {
    setRows((prev) => {
      const newRows = prev.filter((r) => r.loteId !== batchId);
      const updatedRowIndex = prev.findIndex((r) => r.loteId === batchId);
      const updatedRow = { ...prev[updatedRowIndex] };
      updatedRow["Status"] = status_values.STOPPED;
      newRows.splice(updatedRowIndex, 0, updatedRow);
      return newRows;
    });
  };

  function getShippingData() {
    let url = `batches`;
    axiosPrivateRisk
      .get(url, {
        params: { ...parameters, page: page + 1, pageSize: rowsPerPage },
      })
      .then((response) => {
        setRows(buildData(response.data.data));
        setTotalItems(response.data.totalItems);
      });
  }

  return (
    <>
      <StopModal
        open={stopModalOpen}
        batch={stoppedBatch}
        onSuccess={handleStopBatchSuccess}
        handleClose={() => setStopModalOpen(false)}
      />
      <Stack
        direction="row"
        spacing={3}
        justifyContent="left"
        sx={{
          marginTop: "5px",
          marginInlineStart: 0,
          paddingRight: "20px",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
        data-testid="ShippingHistory"
      >
        <TextField
          label={t("Risk.SearchId")}
          variant="outlined"
          size="small"
          sx={{ background: "white", width: "180px", height: "20px" }}
          InputProps={{ style: { borderRadius: 20 } }}
          value={idBatch}
          onChange={(event) => handleChangeSearchById(event)}
          data-testid="buscarid"
        />
        <TextField
          id="outlined-basic"
          variant="outlined"
          type="date"
          size="small"
          label={t("Risk.ActivatedDate")}
          InputProps={{ style: { borderRadius: 20 } }}
          value={date}
          onChange={(event) => handleChangeSearchByDate(event)}
          data-testid="date-id"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          id="outlined-basic"
          label={t("Risk.StatusBatch")}
          variant="outlined"
          select
          size="small"
          sx={{ background: "white", width: "180px" }}
          InputProps={{ style: { borderRadius: 20 } }}
          value={statusBatches}
          onChange={(event) => handleChangeSearchByStatus(event.target.value)}
          data-testid="status-select"
        >
          <MenuItem value=""> </MenuItem>
          <MenuItem data-testid="FinalizadoId" value={status_values.FINISHED}>
            {t("Risk.FINISHED")}
          </MenuItem>
          <MenuItem data-testid="EnprogresoId" value={status_values.IN_PROGRESS}>
            {t("Risk.IN_PROGRESS")}
          </MenuItem>
          <MenuItem data-testid="DetenidoId" value={status_values.STOPPED}>
            {t("Risk.STOPPED")}
          </MenuItem>
        </TextField>
        <Button
          size="sm"
          variant="solid"
          style={{
            backgroundColor: colorSearch ? "#363853" : "#CFD0DF",
          }}
          onClick={() => handleBuildParams()}
          data-testid="buscar-button"
        >
          {t("Risk.Search")}
        </Button>
      </Stack>
      <div>
        {totalItems === 0 ? (
          <div style={{ display: "flex", justifyItems: "center" }}>
            <NoShipmentHistory />
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" data-testid="table-id">
              <TableHead>
                <TableRow
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey",
                    },
                  }}
                >
                  <TableCell data-testid="batch-id">{t("Risk.BatchId")}</TableCell>
                  <TableCell data-testid="activated-id" align="center">
                    {t("Risk.ActivatedDate")}
                  </TableCell>
                  <TableCell data-testid="schedule-id" align="center">
                    {t("Risk.ScheduleShipments")}
                  </TableCell>
                  <TableCell data-testid="successfully-id" align="center">
                    {t("Risk.SuccessfullyShipments")}
                  </TableCell>
                  <TableCell data-testid="pending-id" align="center">
                    {t("Risk.PendingShipments")}
                  </TableCell>
                  <TableCell align="center" data-testid="column-status">
                    {t("Risk.StatusBatch")}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <Row key={row.loteId} row={row} index={index} handleStopBatch={handleStopBatch} />
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("Insights.Transactions.Table.LabelRowsPerPage")}
              labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
                return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}`;
              }}
            />
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default ShippingHistory;
