import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Formik } from "formik";

import { SnackBar } from "../components/utils/alert";
import { removeAuthTokenAzure } from "../components/utils/localStorage";
import { UseIsAuthenticated, UseSignIn, UseSignOut } from "../hooks/authUser";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import GenericBackdrop from "../utils/GenericBackdrop";

import AzureAuthenticationComponent from "../components/azure/azure-authentication-component";
import Title from "../components/inputs/title";
import OnBoardingLayout from "../components/onBoarding/on-boarding-layout";
import dataJson from "../config/signIn.json";

const Login = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const signIn = UseSignIn();
  const signOut = UseSignOut();
  const isAuthenticated = UseIsAuthenticated();

  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  useEffect(() => {
    if (isAuthenticated()) navigate("/"); // si ya tiene una sesion iniciada, entra a la pagina de inicio (tal como funcionan varios sitios actuales)
  }, []);

  const onAuthenticated = async (userAccountInfo) => {
    try {
      handleLoaderOpen();

      let role = (userAccountInfo?.idTokenClaims?.roles ?? ["fullaccess"])[0].toLowerCase();
      let rolebd = "";

      switch (role) {
        case "fullaccess":
          rolebd = "Internal";
          break;
        case "user":
          rolebd = "Standard-internal";
          break;
        case "lectura":
          rolebd = "Validator-internal";
          break;
        default:
          rolebd = "Internal";
      }

      userAccountInfo.idTokenClaims.roles = userAccountInfo?.idTokenClaims?.roles
        ? userAccountInfo.idTokenClaims.roles.concat(["RISK_FULL_ACCESS"])
        : ["RISK_FULL_ACCESS"];

      if (userAccountInfo) {
        signIn({
          token: userAccountInfo.token,
          tokenType: "Bearer",
          expiresIn: userAccountInfo.idTokenClaims.exp,
          authState: {
            ...userAccountInfo,
            roleName: rolebd,
          },
        });

        await axiosPrivate
          .get("/internal-policies", {
            params: {
              roleName: rolebd,
            },
          })
          .then((response) => {
            signIn({
              token: userAccountInfo.token,
              tokenType: "Bearer",
              expiresIn: userAccountInfo.idTokenClaims.exp,
              authState: {
                ...userAccountInfo,
                roles: response.data[0].policy,
                roleName: rolebd,
              },
            });

            handleLoaderClose();
            navigate("/");
          })
          .catch(() => {
            errorManager();
          });
      }
    } catch (_) {
      errorManager(true);
    }
  };

  const errorManager = (isError = false) => {
    signOut();
    removeAuthTokenAzure(isError);

    handleLoaderClose();
    SnackBar(t("SignIn.SessionError"), isError ? "error" : "warning");
  };

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <Formik initialValues={{}}>
        {() => (
          <Form className="formulario">
            <Title title={t(dataJson.forms.title)} />
            <AzureAuthenticationComponent btn={dataJson.forms.actionsButtons[0]} onAuthenticated={onAuthenticated} />
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default function SignIn() {
  return <OnBoardingLayout screen={<Login />} />;
}
