import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import { getContract } from "../utils/actions/axios-api-wc";
import { Alert } from "../utils/alert";
import { getAuthTokenAzure } from "../utils/localStorage";

import DetailContract from "./components/detailContract";
import DetailDocuments from "./components/detailDocuments";
import DetailOwner from "./components/detailOwner";

import { axiosClientKym } from "../commons/axiosClient";
import PermissionsGate from "../commons/PermissionsGate";

const OwnerDetailWc = ({ idpdv }) => {
  const [t] = useTranslation("global");
  const azureToken = getAuthTokenAzure();

  const [value, setValue] = useState(0);
  const [detailOwner, setDetailOwner] = useState({});
  const [showDetailComponent, setShowDetailComponent] = useState(false);
  const [render, setRender] = useState("Profile");
  const arrTabs = [];
  const [loader_, setLoader_] = useState(false);
  const [elements_, setElements_] = useState([]);
  const [status_, setStatus_] = useState(0);

  const buildSectionDetailsWC = (response) => {
    return {
      Profile: {
        general_information: {
          name: `${response.Negocio["owner-information"].name} ${response.Negocio["owner-information"].lastname}`,
          idpdv: response.Negocio["facturation-information"].id_pdv,
          email: response.Negocio["contact-information"].email,
          cedula: response.Negocio["facturation-information"].id_number,
          emoneyNumber: response.Negocio["contact-information"].emoney_cell_number,
          ruc: response.Negocio["facturation-information"].ruc,
          epinNumber: response.Negocio["contact-information"].epin_cell_number,
          createDate: response.createDate,
        },
      },
      name: `${response.Negocio["owner-information"].name} ${response.Negocio["owner-information"].lastname}`,
      Documents: {
        documents_list: response.Negocio["documents"],
        form_id: response["formId"],
      },
      is_abbreviate: response["is_abbreviate"],
    };
  };

  const getDetailWcUser = () => {
    let url = "user";
    axiosClientKym.defaults.headers["Authorization"] = azureToken;

    axiosClientKym
      .get(url, { params: { id_pdv: idpdv } })
      .then((response) => {
        setShowDetailComponent(true);
        setDetailOwner(buildSectionDetailsWC(response.data));
      })
      .catch((_) => {
        setShowDetailComponent(false);
        return Alert(t("General.Message.ErrorService"), "error");
      });
  };
  const getInfo = async () => {
    setLoader_(true);
    const response = await getContract(idpdv);
    setElements_([response.data]);
    setStatus_(response.status);
    setLoader_(false);
  };
  useEffect(() => {
    getDetailWcUser();
    getInfo();
  }, []);

  const fnGate = (tabNameWC, _) => {
    arrTabs.push(tabNameWC);
    let label;
    let Weight;
    let color;
    label = "Owners.Profile.";

    if (tabNameWC == "Document") {
      label = "Owners.Document.";
    }
    if (tabNameWC == "Contracts") {
      //Is "Profile"
      label = "Owners.Contracts.";
    }

    let scope =
      label +
      tabNameWC.charAt(0).toLowerCase() +
      tabNameWC.slice(1) +
      (tabNameWC.endsWith("s") ? "" : "s") +
      ".actions.view";
    if (render == tabNameWC) {
      Weight = 500;
      color = "#404A56";
    } else {
      Weight = 400;
      color = "#4C5866";
    }

    return (
      <PermissionsGate scopes={scope}>
        <Tab
          label={t(label + tabNameWC)}
          data-testid={tabNameWC}
          onClick={() => handleTabs(tabNameWC)}
          style={{
            minWidth: "172px",
            fontFamily: "Roboto",
            fontSize: "18px",
            color: color,
            opacity: 100,
            fontWeight: Weight,
          }}
        />
      </PermissionsGate>
    );
  };

  const handleTabs = (tabNameWC) => {
    setRender(tabNameWC);
    const index = arrTabs.indexOf(tabNameWC);
    setValue(index);
  };

  const renderiza = () => {
    switch (render) {
      case "Profile": {
        return (
          <DetailOwner
            detailOwner={detailOwner[render]}
            translate="OwnersWC"
            rowVersion="v2"
            icons={true}
          ></DetailOwner>
        );
      }
      case "Document": {
        return <DetailDocuments detailDocuments={detailOwner} translate="OwnersWC" icons={true}></DetailDocuments>;
      }
      case "Contracts": {
        return (
          <DetailContract
            idpdv={idpdv}
            detailDocuments={detailOwner}
            translate="OwnersWC"
            icons={true}
            elements={elements_}
            loader={loader_}
            status={status_}
          ></DetailContract>
        );
      }
    }
  };

  return (
    <Fragment>
      {showDetailComponent ? (
        <div>
          <Card style={{ borderRadius: "10px" }}>
            <Grid container direction="row" style={{ justifyContent: "space-between" }}>
              <Grid item md={6} xs={12}>
                <Grid container direction="column">
                  <Grid item>
                    <Box display="flex" justifyContent="left" alignItems="left">
                      <CardContent
                        sx={{
                          textAlign: "left",
                          paddingBottom: "0px !important",
                          paddingTop: "0px !important",
                          width: "100%",
                        }}
                      >
                        <Grid container>
                          <Grid item md={10} sx={{ paddingLeft: "10px" }}>
                            <Typography
                              variant="subtitle1"
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "24px",
                                fontWeight: "700",
                                marginTop: "20px",
                                lineHeight: "18px",
                              }}
                            >
                              {detailOwner.name}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                marginTop: "10px",
                                lineHeight: "18px",
                              }}
                            >
                              {t("OwnersWC.Profile.Title")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Fragment>
            <Box sx={{ borderBottom: 1, borderColor: "#DFE3E7" }} mt={3} mr={3} ml={0}>
              <Tabs value={value}>
                {fnGate("Profile")}
                {fnGate("Document")}
                {fnGate("Contracts")}
              </Tabs>
            </Box>
            <div style={{ paddingTop: "30px" }}>{renderiza()}</div>
          </Fragment>
        </div>
      ) : null}
      {GoBack()}
    </Fragment>
  );
};

export default OwnerDetailWc;

function GoBack() {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const navigateBack = () => {
    navigate(-1);
  };
  return (
    <div
      style={{
        textAlign: "right",
        marginBlockEnd: "30px",
        marginTop: "30px",
      }}
    >
      <Button
        variant="contained"
        style={{
          background: "#FFC10E",
          color: "#404A56",
          textTransform: "none",
          minWidth: "139px",
          borderColor: "#FFC10E",
          height: "48px",
        }}
        onClick={navigateBack}
        className="action-button-outlined"
      >
        {t("Buttons.Back")}
      </Button>
    </div>
  );
}
