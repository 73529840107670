import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { Button } from "@material-ui/core";
import NavigateBeforeIcon from "@mui/icons-material//NavigateBefore";
import NavigateNextIcon from "@mui/icons-material//NavigateNext";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFPreview({ pdfUrl, showAnnotations = false }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  console.log(pdfUrl);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function goToPrevPage() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  function goToNextPage() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  useEffect(() => {
    const base = window.location.origin;
    console.log(base + "/pdf.worker.js");
  }, []);

  return (
    <div data-testid="pdf-document">
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<NavigateBeforeIcon />}
          disabled={pageNumber <= 1}
          onClick={goToPrevPage}
          style={{ marginRight: "10px" }}
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<NavigateNextIcon />}
          disabled={pageNumber >= numPages}
          onClick={goToNextPage}
        >
          Siguiente
        </Button>
      </div>
      <p style={{ textAlign: "center", marginTop: "10px" }}>
        Página {pageNumber} de {numPages}
      </p>
      {showAnnotations === false && <style>{`.react-pdf__Page__annotations { display: none !important; }`}</style>}
    </div>
  );
}

export default PDFPreview;
