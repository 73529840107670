import { Button } from "@mui/material";
import PropTypes from "prop-types";

/**
 * Custom Button component
 * @param {string} props.children The content of the button
 * @param {string} props.width The width of the button, if not provided, the default width is 100%
 * @param {Object} props.sxProps The sx props of the button, if not provided, the default sx props are {}
 * @param {Function} props.onClick The function to be called when the button is clicked
 * @param {Object} props.buttonProps The props of the button, if not provided, the default props are {}
 */
export default function CustomButton({
  children,
  width = "100%", // default width
  sxProps = {}, // default sxProps
  onClick,
  ...buttonProps
}) {
  return (
    <Button
      variant="contained"
      {...buttonProps}
      onClick={onClick}
      sx={{
        width,
        height: "inherit",
        borderRadius: "16px",
        background: "#363853",
        color: "white",
        ...sxProps,
      }}
    >
      {children}
    </Button>
  );
}

CustomButton.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  sxProps: PropTypes.object,
  onClick: PropTypes.func,
};
