import { useState } from "react";
import { useEffect } from "react";

import { Clear } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { IconButton } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { formatDateString } from "../../../../utils/formatDate";

export default function DateInput({ onChange, placeholder }) {
  const [value, setValue] = useState(null);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleClear = () => {
    setValue(null);
  };

  useEffect(() => {
    if (onChange) {
      if (value === null) return onChange("");

      const callbackValue = formatDateString(value);
      onChange(callbackValue);
    }
  }, [value, onChange]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputFormat="DD/MM/YYYY"
        value={value}
        onChange={handleChange}
        InputAdornmentProps={{
          position: "start",
        }}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <IconButton size="small" sx={{ display: value ? "" : "none" }} onClick={handleClear}>
                  <Clear />
                </IconButton>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              placeholder,
            }}
            sx={{ width: "inherit" }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
