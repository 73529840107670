import SingleBatchModal from "./singleBatchModal";

const StopModal = ({ batch, ...props }) => {
  const formatDate = (stringDate) => {
    if (!stringDate) {
      return "-";
    }

    let date = new Date(stringDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return `${day}/${month}/${year}`;
  };

  const columns = batch
    ? {
        "Risk.BatchId": batch.loteId,
        "Risk.ActivatedDate": formatDate(batch.activatedDate),
        "Risk.ScheduledShipments": batch.enviosProgramados,
        "Risk.SuccessfulShipments": batch.enviosExitosos,
        "Risk.PendingShipments": batch.enviosPendientes,
      }
    : {};

  return (
    <>
      {batch && (
        <SingleBatchModal
          action="Stop"
          url={`batches/${batch.loteId}/stop`}
          method="post"
          columns={columns}
          batch={batch}
          id="loteId"
          {...props}
        />
      )}
    </>
  );
};

export default StopModal;
