import { ReactComponent as ReactLogo } from "../../../assets/backoffice/profile-avatar.svg";

export default function ProfileAvatar() {
  return (
    <div
      style={{
        height: "160px",
        width: "160px",
        background: "#363853",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLogo />
    </div>
  );
}
