import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Card, CardContent, CircularProgress, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";

import TabPanel from "../../utils/tabPanel";

import { getKyxMicromerchantUserOne } from "./data/repositories/micromerchant_repository";

const MicromerchantDetail = () => {
  const [t] = useTranslation("global");
  const { state } = useLocation();
  const theme = useTheme();
  const [showLoading, setLoading] = useState(true);
  const [micromerchant, setMicromerchant] = useState([]);

  const showDetail = () => setLoading(false);
  const hideDetail = () => setLoading(true);

  useEffect(() => {
    getMicromerchant()
      .then(() => console.log)
      .finally(() => console.log);
    localStorage.setItem("MicromerchantDetail", "1");
  }, []);

  const getMicromerchant = async () => {
    hideDetail();
    const response = await getKyxMicromerchantUserOne({
      wallet_id: state.client_id,
    });
    setMicromerchant(response);
    showDetail();
  };

  const styles = {
    card: {
      borderRadius: "10px",
    },
    cardContent: {
      textAlign: "left",
      paddingBottom: "3px !important",
      paddingTop: "3px !important",
      width: "100%",
    },
    avatar: {
      bgcolor: "#DADADA",
      width: 55,
      height: 55,
      marginTop: "-10px",
    },
    title: {
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: "700",
      marginTop: "12px",
      lineHeight: "18px",
    },
    subtitle: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "400",
      marginTop: "6px",
      lineHeight: "18px",
    },
    tab: {
      minWidth: "172px",
      fontFamily: "Roboto",
      fontSize: "18px",
      color: "#404A56",
      opacity: 100,
      fontWeight: 500,
    },
    header: {
      ...theme.typography.body1,
      padding: theme.spacing(1),
      textAlign: "left",
      color: "#404A56",
      boxShadow: "none",
      fontWeight: "bold",
    },
    tag: {
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "left",
      color: "#708195",
      boxShadow: "none",
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    tagContent: {
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "left",
      color: "#404A56",
      fontWeight: "bold",
      boxShadow: "none",
    },
  };

  const dataGridTitle = (title) => {
    return (
      <React.Fragment>
        <Grid item xs={12} sx={{ borderStyle: "none" }}>
          <div style={styles.header}>{t(title)}</div>
        </Grid>
      </React.Fragment>
    );
  };

  const gridComponent = (keyName, value) => (
    <React.Fragment>
      <Grid item xs={4}>
        <span style={styles.tag}>{t(keyName)}</span>
      </Grid>
      <Grid item xs={8}>
        <span style={styles.tagContent}>{value}</span>
      </Grid>
    </React.Fragment>
  );

  const getDate = (application_date) => {
    let removehours = application_date.split(" ");
    let date = removehours[0].split("-");
    let newDate = date[2] + "/" + date[1] + "/" + date[0];
    return newDate;
  };

  const statusTranslate = (key) => {
    const keys = {
      true: t("Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Active"),
      false: t("Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Inactive"),
    };
    let status = keys[key];
    return status;
  };

  const showSocialMedia = (element) => Object.keys(element).map((key, _) => key + ": " + element[key] + " ");

  const renderMicromerchant = () => {
    return (
      <Card style={{ borderRadius: "20px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{ borderStyle: "none" }} className={"card-grid-padding"}>
            <Grid
              item
              xs={6}
              spacing={3}
              sx={{ borderStyle: "none" }}
              style={{ display: "flex", flexWrap: "wrap", height: "0px" }}
            >
              {dataGridTitle("Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Title")}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Merchant",
                micromerchant["business_profile"]["public_name"]
              )}

              {gridComponent("Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Id", state.client_id)}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Email",
                micromerchant["email"]
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.MSISDN",
                micromerchant["user_data"]["msisdn"]
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.Level",
                micromerchant["user_data"]["level"]
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.RegistrationDate",
                getDate(micromerchant["contract"]["date_accept_conditions"])
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.RegistrationFinishDate",
                getDate(micromerchant["contract"]["date_accept_conditions"])
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.AccountSection.T&CDate",
                getDate(micromerchant["contract"]["date_accept_conditions"])
              )}
            </Grid>

            <Grid item xs={6} sx={{ borderStyle: "none" }} style={{ display: "flex", flexWrap: "wrap" }}>
              <Grid item xs={12} sx={{ borderStyle: "none" }}>
                <div style={styles.header}>{t("Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.Title")}</div>
              </Grid>

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.PublicName",
                micromerchant["business_profile"]["public_name"]
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.Category",
                micromerchant["business_profile"]["business_category"]
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.UserName",
                micromerchant["business_profile"]["user"]
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.BusinessDescription",
                micromerchant["business_profile"]["business_description"]
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.StateAndCity",
                micromerchant["sales_channel"]["state"] + micromerchant["sales_channel"]["city"]
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.Address",
                micromerchant["sales_channel"]["street_and_number"] === ""
                  ? ""
                  : micromerchant["sales_channel"]["street_and_number"] +
                      " C.P " +
                      micromerchant["sales_channel"]["post_code"]
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.SocialNetworks",
                showSocialMedia(micromerchant["sales_channel"]["social_media_ckb"]) +
                  showSocialMedia(micromerchant["contact"]["social_network"])
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.OnlineStore",
                statusTranslate(micromerchant["cards_sales_channel"]["has_online_store"])
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.PhysicalStore",
                statusTranslate(micromerchant["cards_sales_channel"]["has_physical_store"])
              )}

              {gridComponent(
                "Micromerchants.MicromerchantDetail.TabPerfil.TradeSection.Website",
                micromerchant["sales_channel"]["website"]
              )}
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  };

  return (
    <div>
      {showLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        >
          <CardContent data-testid={"loading-change-bank-status"}>
            <CircularProgress data-testid={"loading-change-cash-out-status"} color="warning" />
          </CardContent>
        </Box>
      ) : (
        <Fragment>
          <Card style={styles.card}>
            <Grid container direction="row" display={"flex"} justifyContent={"space-between"}>
              <Grid item md={6} xs={12}>
                <Grid container direction="column">
                  <Grid item>
                    <Box display="flex" justifyContent="left" alignItems="left">
                      <CardContent sx={styles.cardContent}>
                        <Grid container>
                          <Grid item md={1}>
                            <Typography variant="h3" style={{ marginTop: "13px" }}>
                              <Avatar alt="Remy Sharp" sx={styles.avatar}>
                                <PersonIcon className="Cursor" fontSize="40" />
                              </Avatar>
                            </Typography>
                          </Grid>

                          <Grid item md={10} sx={{ paddingLeft: "10px", marginLeft: "10px" }}>
                            <Typography variant="subtitle1" style={styles.title}>
                              {micromerchant["business_profile"]["public_name"]}
                            </Typography>
                            <Typography variant="subtitle1" style={styles.subtitle}>
                              Micromerchant
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>

          <Fragment>
            <Box sx={{ borderBottom: 1, borderColor: "#DFE3E7" }} mt={3} mr={3} ml={0}>
              <Tabs value={0}>
                <Tab label={t("Micromerchants.MicromerchantDetail.TabPerfil.Tab")} style={styles.tab} />
              </Tabs>
            </Box>
            <TabPanel value={0} index={0}>
              <div style={{ paddingTop: "30px" }}>{renderMicromerchant()}</div>
            </TabPanel>
          </Fragment>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "16px",
            }}
          >
            <Link
              data-testid="custom-element"
              to={`/Micromerchants`}
              style={{
                color: "#707070",
                fontSize: "16px",
                background: "#FFF",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #f8c327",
              }}
            >
              <ArrowBackIcon />
              <div style={{ display: "inline-block", marginLeft: "10px" }}>
                {t("Micromerchants.MicromerchantDetail.BtnReturn")}
              </div>
            </Link>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default MicromerchantDetail;
