import SearchClient from "../../components/backoffice/ClientsList";
import Sesion from "../../components/commons/sesion";

export default function SearchClientView() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Backoffice"} />
      <SearchClient />
    </div>
  );
}
