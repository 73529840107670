import React from "react";

const Transfer = () => {
  return (
    <div>
      <p>ComponentTransfer</p>
    </div>
  );
};

export default Transfer;
