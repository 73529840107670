import useInvoices from "../../../hooks/backoffice/useInvoices";

import InvoicesFilter from "./invoicesFilter";

import InvoicesList from "../../commons/backoffice/invoicesList";

const Invoices = ({ clientId }) => {
  const { isLoading, invoices, totalItems, currentPage, onFilterChange, onPageChange } = useInvoices({ clientId });

  const handleFilterSubmit = (filter) => {
    onFilterChange(filter);
  };

  return (
    <div>
      <InvoicesFilter onFilterSubmit={handleFilterSubmit} />
      <div style={{ marginTop: "36px" }}>
        <InvoicesList
          onPageChange={(page) => onPageChange(page)}
          isLoading={isLoading}
          invoices={invoices}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default Invoices;
