import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import DateRangePicker from "../../commons/DateRangePicker";
import MMButtonCustom, { MMTypeButtonStyles } from "../components/MMButtonCustom";
import MMDialogCustom from "../components/MMDialogCustom";

const MMDownloadFileDialog = (props) => {
  const { onAction, onClose, isOpen, isLoading = false } = props;

  const [t] = useTranslation("global");
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [valueForm, setValueForm] = useState(null);

  useEffect(() => {
    setIsUpdateData(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isOpen) {
      setValueForm(null);
    }
  }, [isOpen]);

  const buildActionsModal = () => {
    return (
      <Fragment>
        <MMButtonCustom
          nameButton={"button-accept"}
          onAction={() => onAction(valueForm)}
          disabled={isUpdateData || !valueForm}
          isLoading={isUpdateData}
        >
          <span>{t("Micromerchants.Modal.DownloadFile.Actions.BtnContinue")}</span>
        </MMButtonCustom>
        <MMButtonCustom
          nameButton={"button-cancel"}
          onAction={onClose}
          type={MMTypeButtonStyles.Secondary}
          disabled={isUpdateData}
        >
          <span>{t("Micromerchants.Modal.Commons.Actions.BtnCancel")}</span>
        </MMButtonCustom>
      </Fragment>
    );
  };

  const handlerDateRange = (values) => {
    if (values.hasError) {
      setValueForm(null);
      return;
    }
    setValueForm(values);
  };

  return (
    <MMDialogCustom
      title={t("Micromerchants.Modal.DownloadFile.Title")}
      open={isOpen}
      onClose={!isUpdateData ? onClose : () => {}}
      actions={buildActionsModal()}
    >
      <MMFormDateRangePicker
        title={t("Micromerchants.Modal.DownloadFile.Form.Title")}
        onValuePicker={handlerDateRange}
      />
    </MMDialogCustom>
  );
};

export default MMDownloadFileDialog;

const MMFormDateRangePicker = (props) => {
  const { title, onValuePicker } = props;

  const [t] = useTranslation("global");

  const [value, setValue] = useState({ start: null, end: null });

  return (
    <Box sx={{ width: 620, padding: 2 }}>
      <span>{title}</span>
      <Box sx={{ padding: 1 }} />
      <DateRangePicker
        value={value}
        localeTexts={{
          start: t("Micromerchants.DateRangePicker.Text.Start"),
          end: t("Micromerchants.DateRangePicker.Text.End"),
        }}
        errorTexts={{
          rangeDate: t("Micromerchants.DateRangePicker.Text.Error.RangeDate"),
          limitRange: t("Micromerchants.DateRangePicker.Text.Error.LimitRange"),
        }}
        onChange={(newValue) => {
          setValue(newValue);
          onValuePicker(newValue);
        }}
      />
    </Box>
  );
};
