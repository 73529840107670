import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";

import ArticleIcon from "@mui/icons-material/Article";
import DownloadIcon from "@mui/icons-material/Download";
import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  CardContent,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableCell,
  Tooltip,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { AlertSimple, ConfirmSimple, SnackBar } from "../../../components/utils/alert";
import { ExportExcel } from "../../../utils/exportExcel";

import DynamicAsyncAction from "../../aggrid/DynamicAsyncAction";
import BasicTable from "../../commons/basicTable";
import ModalStatusMM, { TypeModals } from "../../commons/modalStatusMM";
import MMButtonCustom, { MMTypeButtonStyles } from "../components/MMButtonCustom";
import MMSearchBar from "../components/MMSearchBar";
import {
  getKyxMicromerchantById,
  getKyxMicromerchants,
  sendHistoricalMMToEmail,
  updateMicroMerchantById,
} from "../data/repositories/micromerchant_repository";
import MMDownloadFileDialog from "../dialogs/MMDownloadFileDialog";

const ChangeLevel = (props) => {
  const { country } = props;
  const [t] = useTranslation("global");

  const useAuth = useAuthUser();
  const auth = useAuth();

  const [level, setLevel] = useState("");
  const [formulary, setFormulary] = useState("");
  const [discover, setDiscover] = useState("");
  const [showLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const handleShowTable = () => setLoading(false);
  const handleHideTable = () => setLoading(true);
  const [rowsPerPage, setRowPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const [clModal, setClModal] = useState(false);
  const [clTypeModal, setClTypeModal] = useState("");
  const [clMMSelected, setClMMSelected] = useState([]);

  const [openDownloadFileModal, setOpenDownloadFileModal] = useState(false);
  const showDownloadFileDialog = () => setOpenDownloadFileModal(true);
  const hideDownloadFileDialog = () => setOpenDownloadFileModal(false);

  const [isBusyRequestFile, setIsBusyRequestFile] = useState(false);

  const [totalMicromerchants, setTotalMicromerchants] = useState(0);
  const [filterParams, setFilterParams] = useState({});
  const [sortParams, setSortParams] = useState({});

  //variables para el grid y la busqueda
  const searchPlaceHolder = "Micromerchants.LevelChange.Search";
  const type = "Micromerchant";
  const typeStorage = `${type}Detail`;
  const rowsPerPageStorage = `${type}-rowsPerPage`;
  const searchStorage = `${type}-search`;
  const pageStorage = `${type}-page`;
  const sortStorage = `${type}-sort`;

  //busqueda local
  const [dataBackUp, setDataBackUp] = useState([]);

  //variables para mostrar el titulo de las columnas y el ordenamiento
  const [columnsShow, setColumnsShow] = useState([]);
  const [columnsSort, setColumnsSort] = useState([]);

  const setColumnsName = (columns, translate) => {
    let columnsName = [];
    for (let column of Object.values(columns)) {
      columnsName.push(translate.concat(column));
    }
    setColumnsShow((_) => [...columnsName]);
  };

  const getValueLocalStorage = (key) => {
    return localStorage.getItem(key) !== "null" ? localStorage.getItem(key) : null;
  };

  useEffect(() => {
    const typeLocal = getValueLocalStorage(typeStorage);
    if (typeLocal) {
      workLocalStorage("set");
    } else {
      workLocalStorage("remove");
    }
    getDataTable()
      .then(() => console.log)
      .finally(() => console.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshUser = (user) => {
    const userIndex = data.findIndex((item) => item["client_id"] === user["client_id"]);
    if (userIndex < 0) return;
    setData((data) => {
      data[userIndex] = { ...user };
      return [...data];
    });
  };

  const setValuesPagination = () => {
    const rowsPerPageLocal = parseInt(getValueLocalStorage(rowsPerPageStorage));
    if (rowsPerPageLocal) {
      setRowPerPage(rowsPerPageLocal);
    }
    const pageLocal = parseInt(getValueLocalStorage(pageStorage));
    if (pageLocal || pageLocal >= 0) {
      setPage(pageLocal);
    }
  };

  const setValuesFilters = () => {
    let params = {};
    const levelLocal = getValueLocalStorage("Micromerchant-level");
    if (levelLocal) {
      setLevel(levelLocal);
      params = { ...params, level: levelLocal };
    }
    const formLocal = getValueLocalStorage("Micromerchant-form");
    if (formLocal) {
      setFormulary(formLocal);
      params = { ...params, form: formLocal };
    }
    const bool = localStorage.getItem("Micromerchant-discover") === "true";
    if (bool) {
      setDiscover(bool);
      params = { ...params, status: bool };
    }
    const search = getValueLocalStorage(searchStorage);
    if (search || search === "") {
      params = { ...params, search_field: search };
    }
    return params;
  };

  const resetStorage = () => {
    localStorage.removeItem(pageStorage);
    localStorage.removeItem(rowsPerPageStorage);
    localStorage.removeItem("Micromerchant-level");
    localStorage.removeItem("Micromerchant-form");
    localStorage.removeItem("Micromerchant-discover");
    localStorage.removeItem(searchStorage);
    localStorage.removeItem(sortStorage);
  };

  const workLocalStorage = (type) => {
    let valuesFilters = {};
    let valuesSort = {};
    if (type === "set") {
      setValuesPagination();
      valuesFilters = setValuesFilters();
      const sort = getValueLocalStorage(sortStorage);
      if (sort) {
        valuesSort = { sort: sort };
      }
    } else {
      resetStorage();
    }
    Object.assign(sortParams, valuesSort);
    Object.assign(filterParams, valuesFilters);
  };

  const updateClientHandler = async (props, e) => {
    workLocalStorage("set");
    e.preventDefault();
    const active = !props["is_active"];
    let client = { ...props, isLoading: true, is_active: active };
    refreshUser(client);
    const micromerchant = {
      business_profile: {
        is_active: active,
      },
      physicalstore: {},
      onlinestore: {},
      docs: {},
      micromerchantadditional: {},
    };
    client = (await getUpdateClient(client, micromerchant)) ?? { ...props };
    refreshUser({ ...client, isLoading: false });
    workLocalStorage("set");
  };

  const getUpdateClient = async (client, micromerchant) => {
    const clientUpdate = await getClientKymById(client["client_id"]);
    if (clientUpdate && parseInt(clientUpdate?.level) !== 5000) {
      const c = await updateClientById(client["client_id"], micromerchant);
      clientUpdate["level"] = c === "" ? clientUpdate.level : client.level;
    }
    return clientUpdate;
  };

  const handleLevelChange = async (e) => {
    const level = e.target.value;
    localStorage.setItem("Micromerchant-level", level);
    setLevel(level);
    delete filterParams["level"];
    const filter = level !== "" ? Object.assign({}, { ...filterParams, level: level }) : filterParams;
    Object.assign(filterParams, filter);
    await getDataTable();
  };

  const handleFormulary = async (e) => {
    const status = e.target.value;
    localStorage.setItem("Micromerchant-form", status);
    setFormulary(status);
    delete filterParams["form"];
    const filter = status !== "" ? Object.assign({}, { ...filterParams, form: status }) : filterParams;
    Object.assign(filterParams, filter);
    await getDataTable();
  };

  const handleDiscover = async (e) => {
    const active = e.target.value;
    localStorage.setItem("Micromerchant-discover", active);
    setDiscover(active);
    delete filterParams["active"];
    const filter = active !== "" ? Object.assign({}, { ...filterParams, active: active }) : filterParams;
    Object.assign(filterParams, filter);
    await getDataTable();
  };

  const handleSearch = async (value) => {
    const filter = {
      ...filterParams,
      search_field: value,
    };
    Object.assign(filterParams, filter);
    await getDataTable();
  };

  const getDataTable = async () => {
    handleHideTable();
    const search = filterParams;
    let nPage = page + 1;
    let rPage = rowsPerPage;

    const pageLocal = parseInt(getValueLocalStorage(pageStorage));
    if (pageLocal || pageLocal === 0) {
      nPage = pageLocal + 1;
    }

    const rowsPerPageLocal = parseInt(getValueLocalStorage(rowsPerPageStorage));
    if (rowsPerPageLocal) {
      rPage = parseInt(rowsPerPageLocal);
    }

    const sort = getValueLocalStorage(sortStorage) ? JSON.parse(getValueLocalStorage(sortStorage)) : sortParams;

    const response = await getKyxMicromerchants({
      country: country,
      page: nPage,
      limit: rPage,
      search: search,
      sort: sort,
      status: "complete",
    });
    if (response !== null) {
      setData(response.data.results);
      setDataBackUp(response.data.results);
      setFilterParams(search);
      setSortParams(sort);
      setTotalMicromerchants(response.data.total_micromerchant);
      setColumnsName(response.data.columns, "Micromerchants.LevelChange.Columns.");
      setColumnsSort(response.data.sort);
    }
    handleShowTable();
  };

  const getClientKymById = async (id) => {
    try {
      const client = await getKyxMicromerchantById({
        country: country,
        id: id,
      });
      return client;
    } catch (error) {
      SnackBar(t("Micromerchants.LevelChange.Message.Error.Reload"), "error");
      return null;
    }
  };

  const updateClientById = async (id, body) => {
    try {
      const client = await updateMicroMerchantById({
        id,
        body,
      });
      return client;
    } catch (error) {
      SnackBar(t("Micromerchants.LevelChange.Message.Error.Reload"), "error");
      return null;
    }
  };

  const generateDataCSV = async () => {
    let level;
    const countryCSV = country?.toLowerCase();
    switch (countryCSV) {
      case "bo":
        level = "6";
        break;
      case "py":
      case "pa":
        level = "1";
        break;
      default:
        level = "2";
        break;
    }

    let response = await getKyxMicromerchants({
      country: country,
      status: "complete",
      page: -1,
      search: { level: "5000" },
    });

    if (!response) response = { data: null };

    const mmerchants = response.data?.results ?? [];
    const dataMMCSV = mmerchants.map((mm) => {
      return {
        msisdn: mm?.msisdn,
        client_id: mm?.client_id,
        test: "test",
        prueba: "prueba",
        level: level,
      };
    });
    return dataMMCSV;
  };

  const setTypeAction = (data) => {
    const level = parseInt(data["level"]);
    if (level === 5000) {
      return "ReloadAction";
    }
    return "SwitchAction";
  };

  const modifiedDataTable = useCallback(() => {
    const fn = () => {
      return;
    };
    fn();
  }, []);

  const openModal = (type, row) => {
    setClMMSelected(row);
    setClTypeModal(type);
    setClModal(true);
  };

  const handleRequestDownloadFile = (event) => {
    setIsBusyRequestFile(true);
    let hasErrorRequest = false;
    const email = auth.username.toLowerCase();
    const startDate = event.start;
    const endDate = event.end;
    const params = { country: country, email, startDate, endDate };
    sendHistoricalMMToEmail(params)
      .catch((_) => (hasErrorRequest = true))
      .finally(() => {
        setIsBusyRequestFile(false);
        hideDownloadFileDialog();
        if (!hasErrorRequest) {
          ConfirmSimple(
            t("Micromerchants.Modal.SweetAlert.DownloadFile.Success"),
            email,
            t("Micromerchants.Modal.SweetAlert.DownloadFile.Buttons.Confirm")
          );
        } else {
          AlertSimple(t("Micromerchants.Modal.SweetAlert.DownloadFile.Error"));
        }
      });
  };

  const createaction = (row) => {
    const typeAction = setTypeAction(row);
    return (
      <Fragment key={uuidv4()}>
        <Fragment key={uuidv4()}>
          <TableCell
            key={uuidv4()}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <DynamicAsyncAction action={typeAction} reload={updateClientHandler} data={row} active={row["is_active"]} />

            {props.eyedIcon(row.client_id, row)}

            <Tooltip title={"Revisión"}>
              <IconButton onClick={() => openModal(TypeModals.Inreview, row)} data-testid="btn-review-changelevel">
                <ArticleIcon className="Cursor" style={{ color: "#707070" }} />
              </IconButton>
            </Tooltip>
            <IconButton onClick={() => openModal(TypeModals.Blocked, row)} data-testid="btn-blocked-changelevel">
              <Tooltip title={"Bloquear"}>
                <LockIcon className="Cursor" style={{ color: "#707070" }} />
              </Tooltip>
            </IconButton>
          </TableCell>
        </Fragment>
      </Fragment>
    );
  };

  const AagridTableMicroMerchant = useCallback(() => {
    return (
      <BasicTable
        dataTable={data}
        dataColumns={columnsShow}
        action={createaction}
        condition={["is_active", "isLoading"]}
        dynamicAction={[
          { field: "form_status", icon: "MicromerchantsFormStatus" },
          { field: "user_status", icon: "MicromerchantsUserStatus" },
        ]}
        rowsPerPageOpt={[]}
        tableType={type}
        rowsPerPageValue={parseInt(getValueLocalStorage(rowsPerPageStorage) ?? 50)}
        pageValue={parseInt(getValueLocalStorage(pageStorage) ?? 0)}
        isTxtRowsPerPagHidden={false}
        columnsSort={columnsSort}
        totalData={totalMicromerchants}
        getData={getDataTable}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, columnsShow, columnsSort, totalMicromerchants]);

  return (
    <Fragment>
      <ModalStatusMM
        row={clMMSelected}
        setMmSelected={setClMMSelected}
        typeModal={clTypeModal}
        openModal={clModal}
        setShowModal={setClModal}
        data={data}
        setData={setData}
        dataBackUp={dataBackUp}
        setBackUp={setDataBackUp}
        setPage={setPage}
        country={country}
        changeDataTable={modifiedDataTable}
      />
      <MMDownloadFileDialog
        isOpen={openDownloadFileModal}
        onAction={handleRequestDownloadFile}
        onClose={hideDownloadFileDialog}
        isLoading={isBusyRequestFile}
      />
      <Stack width="100%" mb={2}>
        <Stack direction="row" width="100%" mb={2}>
          <MMSearchBar type={type} placeholder={searchPlaceHolder} onSearch={handleSearch} />
        </Stack>
        <Stack direction="row" width="100%" mb={2}>
          <Stack direction="row" width="150vh" spacing={1} justifyContent="start">
            <FormControl sx={{ minWidth: 160 }}>
              <InputLabel id="demo-simple-select-helper-label">
                {t("Micromerchants.LevelChange.filters.Level.SelectLevel")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={level}
                label="Age"
                onChange={handleLevelChange}
                data-testid="select"
                sx={{ paddingBottom: "0px", borderRadius: "16px" }}
              >
                <MenuItem value="">
                  <em>{t("Micromerchants.LevelChange.filters.Level.SelectOption")}</em>
                </MenuItem>
                <MenuItem value={"5000"}>5000</MenuItem>
                <MenuItem value={"6000"}>6000</MenuItem>
                <MenuItem value={"otros"}>Otros</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 160 }}>
              <InputLabel id="demo-simple-select-helper-label_status" sx={{ background: "white", width: 1 / 2 }}>
                {t("Micromerchants.LevelChange.filters.Status.SelectLevel")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label-status"
                id="demo-simple-select-helper-status"
                value={formulary}
                label="Formulary"
                onChange={handleFormulary}
                data-testid="select-form"
                sx={{ paddingBottom: "0px", borderRadius: "16px" }}
              >
                <MenuItem value="">
                  <em>{t("Micromerchants.LevelChange.filters.Status.SelectOption")}</em>
                </MenuItem>
                <MenuItem value={"inprogress"}>En progreso</MenuItem>
                <MenuItem value={"inreview"}>En revisión</MenuItem>
                <MenuItem value={"complete"}>Completado</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="demo-simple-select-helper-label_discover" sx={{ background: "white", width: 1 / 2 }}>
                {t("Micromerchants.LevelChange.filters.Discover.SelectLevel")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label-discover"
                id="demo-simple-select-helper-discover"
                value={discover}
                label="Age"
                onChange={handleDiscover}
                data-testid="select-discover"
                sx={{ paddingBottom: "0px", borderRadius: "16px" }}
              >
                <MenuItem value="">
                  <em>{t("Micromerchants.LevelChange.filters.Discover.SelectOption")}</em>
                </MenuItem>
                <MenuItem value={true}>Activo</MenuItem>
                <MenuItem value={false}>Inactivo</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" width="150vh" spacing={1} justifyContent="end" alignItems="center">
            <MMButtonCustom
              nameButton={"Micromerchants.LevelChange.Buttons.ExportAll"}
              type={MMTypeButtonStyles.Secondary}
              startIcon={<DownloadIcon />}
              onAction={showDownloadFileDialog}
            >
              <span>{t("Micromerchants.LevelChange.Buttons.ExportAll")}</span>
            </MMButtonCustom>
            <ExportExcel
              fileType={"csv"}
              fileName={"NewMicromerchant"}
              asyncData={generateDataCSV}
              columns={["client_id", "msisdn", "test", "prueba", "level"]}
              showHeader={false}
              delimiter={";"}
              rowDelimiter={"\r\n"}
              disabled={false}
            />
          </Stack>
        </Stack>
      </Stack>
      <Fragment>{showLoading ? <BoxLoaderInner /> : <AagridTableMicroMerchant />}</Fragment>
    </Fragment>
  );
};

const BoxLoaderInner = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
      }}
    >
      <CardContent data-testid={"loading-change-bank-status"}>
        <CircularProgress data-testid={"loading-change-cash-out-status"} color="warning" />
      </CardContent>
    </Box>
  );
};

export default ChangeLevel;
