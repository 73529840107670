import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import { InsertEmoticonOutlined } from "@mui/icons-material";
import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

import Frametigo from "../img/Frametigo.svg";
import Logotigo from "../img/Logotigo.svg";
import "./styles.css";

import pathindex from "../components/utils/pathindex";
import { UseIsAuthenticated } from "../hooks/authUser";

import PermissionsGate from "../components/commons/PermissionsGate";
import PermissionsGateAzure from "../components/commons/PermissionsGateAzure";

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  background: "linear-gradient(0deg, rgba(250,190,0,1) 50%, rgba(255,216,88,1) 90%);",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  background: "linear-gradient(0deg, rgba(250,190,0,1) 51%, rgba(255,216,88,1) 83%);",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  height: "132px",
  padding: theme.spacing(0, 1),
  backgroundColor: "#ffc70d",
  clipPath: "circle(68% at 52% 100%)",
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "red",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const useStyles = makeStyles((theme) => ({
  listitem: {
    [theme.breakpoints.down("md")]: {
      marginTop: "0%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0%",
    },
  },
  Coloricon: {
    color: "#343C46",
    marginLeft: "5px",
  },
  Textitem: {
    fontSize: "16px",
    color: "#343C46",
    fontWeight: "bold",
    fontFamily: "Roboto",
    whiteSpace: "pre-wrap",
  },
  Textitemactive: {
    fontSize: "16px",
    color: "#004FB6",
    fontWeight: "bold",
    fontFamily: "Roboto",
    whiteSpace: "pre-wrap",
  },
  Borderitem: {
    borderLeft: "4px solid #004FB6",
  },
  Coloritem: {
    color: "#004FB6",
  },
}));

const StyleOpen = {
  paddingLeft: "35px",
  paddingRight: "25px",
  paddingTop: "15%",
  paddingBottom: "28%",
  clipPath: "circle(75% at 57% 0%)",
  background: "#FABE00",
  transition: "all .1s ease-in-out",
};

const StyleClose = {
  paddingLeft: "10px",
  paddingRight: "20px",
  paddingTop: "30%",
  paddingBottom: "120%",
  clipPath: "circle(100% at 150% 6%)",
  transition: "all .3s ease-in-out",
  background: "#FABE00",
};

const StyleImgOpen = {
  transition: "all .3s ease-in-out",
  width: "160px",
  height: "48px",
};

const StyleImgClose = {
  width: "48px",
  height: "48px",
  transition: "all .1s ease-in-out",
};

const StyleDrawerOpen = {
  borderBottomWidth: 1,
  marginLeft: "10%",
  marginRight: "10%",
  marginTop: "5%",
  background: "#343C46",
};

const StyleDrawerClose = {
  borderBottomWidth: 2,
  marginLeft: "24px",
  marginRight: "30%",
  marginTop: "5%",
  background: "#343C46",
};

const rotateIcon = {
  transform: "scaleX(-1)",
};

export default function Sidebar() {
  const [t] = useTranslation("global");
  const isAuthenticated = UseIsAuthenticated();

  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  let history = useNavigate();
  const wrapperRef = useRef(null);
  useOutside(wrapperRef);
  const classes = useStyles();
  let location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setSelectedIndex(pathindex(location.pathname));
  }, []);

  const component = (index, value = "/") => {
    setSelectedIndex(index);
    setOpen(false);
    history(value);
  };

  function useOutside(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleDrawerClose();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const stylesitems = (index) => {
    return selectedIndex === index ? classes.Borderitem : "";
  };

  const stylesitemsicon = (index) => {
    return selectedIndex === index ? classes.Coloritem : classes.Coloricon;
  };

  const getPrimary = (index) => {
    return selectedIndex === index ? classes.Textitemactive : classes.Textitem;
  };

  return (
    isAuthenticated() && (
      <Box ref={wrapperRef} sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open} style={{ backgroundColor: "#FABE00" }}>
          {open ? (
            <div style={StyleOpen}>
              <img style={StyleImgOpen} src={"../" + Frametigo} alt={"Tigo"} />
            </div>
          ) : (
            <div style={StyleClose}>
              <img style={StyleImgClose} src={"../" + Logotigo} alt={"LogoTigo"} />
            </div>
          )}

          <List>
            <PermissionsGate scopes={"resources.insights"}>
              <ListItem
                className={`logotigocss ${stylesitems(0)}`}
                button
                key="Insights"
                onClick={() => component(0, "/Insights")}
              >
                <ListItemIcon>
                  <Tooltip title={t("Menu.Insights")}>
                    <EqualizerIcon className={stylesitemsicon(0)} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: getPrimary(0),
                  }}
                  primary={t("Menu.Insights")}
                />
              </ListItem>
            </PermissionsGate>
            <PermissionsGate scopes={"resources.merchants"}>
              <ListItem
                className={`logotigocss ${stylesitems(1)}`}
                button
                key="Merchants"
                data-testid="Merchants"
                onClick={() => component(1, "/Merchants")}
              >
                <ListItemIcon>
                  <Tooltip title={t("Menu.Merchants")}>
                    <StoreMallDirectoryOutlinedIcon className={stylesitemsicon(1)} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: getPrimary(1),
                  }}
                  primary={t("Menu.Merchants")}
                />
              </ListItem>
            </PermissionsGate>
            <PermissionsGate scopes={"resources.transfers"}>
              <ListItem
                className={`logotigocss ${stylesitems(2)}`}
                button
                key="Operations"
                data-testid="Operaciones"
                onClick={() => component(2, "/Operations")}
              >
                <ListItemIcon>
                  <Tooltip title={t("Menu.Operations")}>
                    <SyncOutlinedIcon className={stylesitemsicon(2)} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: getPrimary(2),
                  }}
                  primary={t("Menu.Operations")}
                />
              </ListItem>
            </PermissionsGate>
            <PermissionsGate scopes={"resources.micromerchants"}>
              <ListItem
                className={`logotigocss ${stylesitems(4)}`}
                button
                key="Micromerchants"
                data-testid="Micromerchants"
                onClick={() => {
                  localStorage.setItem("tab-micromerchant", 0);
                  localStorage.removeItem("MicromerchantDetail");
                  component(4, "/Micromerchants");
                }}
              >
                <ListItemIcon>
                  <Tooltip title="Micromerchants">
                    <AssignmentOutlinedIcon className={stylesitemsicon(4)} />
                  </Tooltip>
                </ListItemIcon>
                <div>
                  <ListItemText
                    classes={{
                      primary: getPrimary(4),
                    }}
                    primary={t("Menu.Micromerchants")}
                  />
                </div>
              </ListItem>
            </PermissionsGate>
            <PermissionsGate scopes={"resources.merchants"}>
              <ListItem
                className={`logotigocss ${stylesitems(5)}`}
                button
                key="WorkingCapital"
                data-testid="WorkingCapital"
                onClick={() => component(5, "/WorkingCapital")}
              >
                <ListItemIcon>
                  <Tooltip title={t("Menu.WorkingCapitalName")}>
                    <AccountBalanceOutlined className={stylesitemsicon(5)} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: getPrimary(5),
                  }}
                  primary={t("Menu.WorkingCapitalName")}
                />
              </ListItem>
            </PermissionsGate>
            <PermissionsGateAzure scopes={"RISK_FULL_ACCESS"}>
              <ListItem
                className={`logotigocss ${stylesitems(6)}`}
                button
                key="Services"
                data-testid="Services"
                onClick={() => component(6, "/Services")}
              >
                <ListItemIcon>
                  <Tooltip title={t("Menu.Batch")}>
                    <LocalOfferOutlinedIcon className={stylesitemsicon(6)} style={rotateIcon} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: getPrimary(6),
                  }}
                  primary={t("Menu.Batch")}
                />
              </ListItem>
            </PermissionsGateAzure>
          </List>

          {/* // TODO: Change this to resources.backoffice and add the backoffice scope to the user */}
          <PermissionsGate scopes={"resources.merchants"}>
            <Divider sx={open ? StyleDrawerOpen : StyleDrawerClose} />
            <List style={{ height: "inherit" }}>
              <ListItem
                className={`logotigocss ${stylesitems(7)}`}
                button
                key="BackOffice"
                onClick={() => component(7, "/Backoffice")}
              >
                <ListItemIcon>
                  <Tooltip title={t("Menu.Backoffice")}>
                    <InsertEmoticonOutlined className={stylesitemsicon(3)} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: getPrimary(7),
                  }}
                  primary={t("Menu.Backoffice")}
                />
              </ListItem>
            </List>
          </PermissionsGate>

          <Divider sx={open ? StyleDrawerOpen : StyleDrawerClose} />

          <List style={{ height: "inherit" }}>
            <PermissionsGate scopes={"resources.configuration"}>
              <ListItem
                className={`logotigocss ${stylesitems(3)}`}
                button
                key="Insights"
                onClick={() => component(3, "/Configuration")}
              >
                <ListItemIcon>
                  <Tooltip title={t("Menu.Configuration")}>
                    <SettingsOutlinedIcon className={stylesitemsicon(3)} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: getPrimary(3),
                  }}
                  primary={t("Menu.Configuration")}
                />
              </ListItem>
            </PermissionsGate>
          </List>
          <div>
            <DrawerHeader>
              {open ? (
                <IconButton data-testid={t("Menu.Hide")} onClick={handleDrawerClose}>
                  <Tooltip title={t("Menu.Hide")}>
                    <ChevronLeftIcon style={{ fontSize: 40 }} />
                  </Tooltip>
                </IconButton>
              ) : (
                <IconButton data-testid={t("Menu.Open")} onClick={handleDrawerOpen}>
                  <Tooltip title={t("Menu.Open")}>
                    <ChevronRightIcon style={{ fontSize: 40 }} />
                  </Tooltip>
                </IconButton>
              )}
            </DrawerHeader>
          </div>
        </Drawer>
      </Box>
    )
  );
}
