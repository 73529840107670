import { renderToString } from "react-dom/server";

import Swal from "sweetalert2";

import { Marks } from "../icon-animate/marks.jsx";

export async function Alert(text, icon) {
  try {
    const iconToText = icon ? renderToString(<Marks size={"56px"} icon={icon}></Marks>) : "";
    return await Swal.fire({
      timer: 2500,
      showConfirmButton: false,
      html: '<div style="margin-top: 21%;">' + iconToText + '<div style="margin-top: 5%;">' + text + "</div></div>",
      hideClass: {
        popup: "animate__animated animate__fadeOut",
      },
      customClass: {
        htmlContainer: "swal-htmlContainer",
        popup: "swal-container",
      },
    });
  } catch (_error) {}
}

export async function ConfirmSimple(title, text, confirmButtonText) {
  return await Swal.fire({
    width: "620px",
    title,
    text,
    icon: "success",
    confirmButtonText,
    customClass: {
      confirmButton: "swal-button-contained",
      cancelButton: "swal-button-outlined",
      title: "swal-title-center",
      htmlContainer: "swal-htmlContainer-with-swal-actions",
      actions: "swal-actions",
    },
  });
}

export async function AlertSimple(title, text, confirmButtonText = "OK") {
  return await Swal.fire({
    width: "620px",
    title,
    text,
    icon: "error",
    confirmButtonText,
    customClass: {
      confirmButton: "swal-button-contained",
      cancelButton: "swal-button-outlined",
      title: "swal-title-center",
      htmlContainer: "swal-htmlContainer-with-swal-actions",
      actions: "swal-actions",
    },
  });
}

export async function Confirm(title, text, icon) {
  const result = await Swal.fire({
    width: "532px",
    title: title,
    html:
      '<div style="margin-top: 19%;">' +
      (icon ? renderToString(<Marks size={"56px"} icon={icon}></Marks>) : "") +
      '<div style="margin-top: 5%;">' +
      "<div>" +
      text +
      "</div>" +
      "</div></div>",
    showCancelButton: true,
    confirmButtonText: "OK",
    customClass: {
      confirmButton: "swal-button-contained",
      cancelButton: "swal-button-outlined",
      title: "swal-title",
      htmlContainer: "swal-htmlContainer-with-swal-actions",
      actions: "swal-actions",
    },
    reverseButtons: true,
  });

  return result.isConfirmed;
}

export const SnackBar = (message, icon, background = "#FABE00") => {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 2500,
    background,
    color: "#404A56",
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: icon,
    title: message,
  });
};
