import React from "react";
import { useTranslation } from "react-i18next";

import validator from "validator";

import "../../../Styles/App.scss";

import AggridTable from "../../aggrid/aggridTable";
import { DynamicAction } from "../../aggrid/dynamicAction";
import { ColumnsGenerator } from "../../operations/columnGenerator";

const Callback = () => {
  const [t] = useTranslation("global");
  const toolTipValueGetter = () => t("AGgrid.Message.Edit");
  const columns = ColumnsGenerator([
    {
      headerName: t("Integrator.Callback.Id"),
      field: "id",
      minWidth: 305,
      editable: false,
    },
    {
      headerName: t("Integrator.Callback.Method"),
      field: "method",
      minWidth: 120,
      editable: true,
      tooltipValueGetter: toolTipValueGetter,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["GET", "POST", "PUT", "DELETE", "PATCH"],
      },
    },
    {
      headerName: t("Integrator.Callback.Appname"),
      field: "app_name",
      minWidth: 150,
      editable: true,
      tooltipValueGetter: toolTipValueGetter,
    },
    {
      headerName: t("Integrator.Callback.Url"),
      field: "url",
      minWidth: 350,
      editable: true,
      tooltipValueGetter: toolTipValueGetter,
    },
    {
      headerName: t("Integrator.Callback.Params"),
      field: "params",
      minWidth: 350,
      editable: true,
      tooltipValueGetter: toolTipValueGetter,
      cellEditor: "popupEditParams",
    },
    {
      headerName: t("Integrator.Callback.Headers"),
      field: "headers",
      minWidth: 120,
      editable: true,
      tooltipValueGetter: toolTipValueGetter,
    },
    {
      headerName: t("Integrator.Callback.Body"),
      field: "body",
      minWidth: 120,
      editable: true,
      tooltipValueGetter: toolTipValueGetter,
    },
    {
      headerName: t("Integrator.Callback.Redirect"),
      field: "redirect",
      minWidth: 160,
      editable: true,
      tooltipValueGetter: toolTipValueGetter,
    },
    {
      headerName: t("Integrator.Callback.Message"),
      field: "message",
      minWidth: 120,
      editable: true,
      tooltipValueGetter: toolTipValueGetter,
    },
    {
      headerName: t("Integrator.Callback.Created_at"),
      field: "created_at",
      minWidth: 180,
      editable: false,
    },
    {
      headerName: t("Integrator.Callback.Is_deleted"),
      field: "is_deleted",
      minWidth: 200,
      editable: false,
      cellRendererFramework: DynamicAction,
      cellRendererParams: {
        icon: "IsDedeted",
      },
    },
  ]);

  const getUrl = () => `mts/callback`;

  const ValidateonRowValue = (data) => {
    return validator.isURL(data.url);
  };

  return (
    <AggridTable
      columnDefs={columns}
      pagination={false}
      height={500}
      width={"100%"}
      showsearch={true}
      ValidateonRowValue={ValidateonRowValue}
      url={getUrl()}
      edit={true}
      startEditingcolumn={"method"}
    />
  );
};

export default Callback;
