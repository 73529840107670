import React, { useState } from "react";

import CustomButton from "./customButton";
import CustomInput from "./customInput";

export const SearchInput = ({ placeholder, onChange, onSubmit, id }) => {
  const [value, setValue] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) onSubmit(value);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <div style={{ height: "40px", width: "100%", display: "flex" }}>
      <CustomInput id={id} placeholder={placeholder} onChange={handleChange} />
      <CustomButton width="15%" sxProps={{ marginLeft: "37px" }} onClick={handleSubmit}>
        Buscar
      </CustomButton>
    </div>
  );
};
