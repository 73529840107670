import React from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import useLogout from "../../hooks/useLogout";

import BackButton from "../batchs/backbutton";

const Sesion = ({ screen, subtitle }) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const logout = useLogout();
  const useAuth = useAuthUser();
  const auth = useAuth();

  const getInitials = (name) => {
    let names = name?.split(" ");
    return names?.[0].substring(0, 1) + names?.[1].substring(0, 1);
  };

  const signOut = async () => {
    navigate("/SignIn");
    await logout();
  };

  const stylesubtitle = {
    color: "#708195",
    fontSize: "14px",
    letterSpacing: "0.247px",
    fontWeight: "400",
  };

  return (
    <Grid container direction="row" style={{ paddingBottom: "2%" }}>
      <Grid item xs={12} sm={9}>
        {subtitle == undefined && (
          <Typography
            color="#343C46"
            fontSize="42px"
            fontFamily="Nunito"
            fontWeight="600"
            variant="h4"
            gutterBottom
            component="div"
          >
            {t(`Menu.${screen}`)}
          </Typography>
        )}
        {subtitle != undefined && (
          <div>
            <BackButton />
            <br />
            <div>
              <Typography variant="body1" sx={stylesubtitle}>
                {t(`Risk.${subtitle}`)}
              </Typography>
            </div>
            <div>
              <Typography variant="h2">{t(`Menu.${screen}`)}</Typography>
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={9} sm={10}>
            <Grid container direction="column">
              <Grid item xs={2} sm={2}>
                <Typography
                  color="#4C5866"
                  gutterBottom
                  fontSize="16px"
                  fontFamily="Nunito Sans"
                  fontWeight="bold"
                  component="div"
                  textAlign="right"
                >
                  {auth.name}
                </Typography>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Typography
                  color="#708195"
                  gutterBottom
                  fontSize="14px"
                  fontFamily="Nunito Sans"
                  fontWeight="regular"
                  component="div"
                  textAlign="right"
                >
                  <label style={{ cursor: "pointer" }} data-testid="lbl-logout" onClick={signOut}>
                    {t("Menu.Logout")}
                  </label>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={2} style={{ textAlign: "end" }}>
            <Avatar alt="Remy Sharp" sx={{ bgcolor: "#DADADA", width: 48, height: 48 }}>
              {getInitials(auth.name)}
            </Avatar>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Sesion;
