import useSWR from "swr";

import ApisEnum from "../../utils/enums/apis.enum";

import useAxiosPrivate from "../useAxiosPrivate";

const useLoanDetails = ({ loanId }) => {
  const axiosClient = useAxiosPrivate(ApisEnum.Backoffice);

  const loanFetcher = async (url) => {
    if(loanId){
      const { data } = await axiosClient.get(url);
      return data;
    } else {
      return {};
    }
  };

  const { data, isLoading, error } = useSWR(`/loans?loanId=${loanId}`, loanFetcher);

  return {
    loan: data,
    loading: isLoading,
    error: error,
  };
};

export default useLoanDetails;
