import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  components: {
    MuiCheckbox: {
      checked: {
        backgroundColor: "#0067ED",
      },
      colorPrimary: {
        color: "#0067ED",
        "&$checked": {
          color: "#0067ED",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            textTransform: "none",
            backgroundColor: "#FFC10E",
            color: "#404A56",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: "600",
            height: "48px",
            borderRadius: "4px",
            ":hover": {
              boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
              backgroundColor: "#FABE00",
              fontWeight: "700",
            },
          },
        },
        {
          props: { variant: "variant-contained" },
          style: {
            textTransform: "none",
            backgroundColor: "#FFC10E",
            color: "#404A56",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: "500",
            height: "48px",
            width: "180px",
            borderRadius: "4px",
            ":hover": {
              boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
              backgroundColor: "#FABE00",
              fontWeight: "700",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            textTransform: "none",
            backgroundColor: "#FFFFFF",
            color: "#404A56",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: "500",
            height: "48px",
            borderRadius: "4px",
            border: "1px solid #FFC10E",
            ":hover": {
              boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
              backgroundColor: "#FFFFFF",
              border: "1px solid #FABE00",
              fontWeight: "700",
            },
          },
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#004FB6",
          height: "3px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          textTransform: "none",
          color: "#4C5866",
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontSize: "18px",
          fontWeight: "normal",
          "&.Mui-selected": {
            color: "#404A56",
            fontWeight: 500,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          "&.Mui-checked": {
            "& > .MuiSwitch-thumb": {
              color: "#363853",
            },
            "& + .MuiSwitch-track": {
              backgroundColor: "#CCCCCC",
              opacity: "1 !important",
            },
          },
          "&.Mui-disabled": {
            color: "#BDBDBD",
            "& > .MuiSwitch-thumb": {
              color: "#BDBDBD",
            },
            "& + .MuiSwitch-track": {
              backgroundColor: "#E0E0E0",
              opacity: "1 !important",
              borderColor: "#E0E0E0",
            },
          },
        },
        thumb: {
          color: "#CFD0DF",
        },
        track: {
          backgroundColor: "#708195",
          opacity: "1 !important",
        },
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: ["Roboto"].join(","),
      textTransform: "none",
      color: "#404A56",
    },
    title: {
      fontSize: "42px",
      fontWeight: "600",
      color: "#404A56",
    },
    subTitle: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#404A56",
    },
    text14: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#404A56",
    },
    text18: {
      fontWeight: "700",
      fontSize: "18px",
      color: "#404A56",
    },
    text16: {
      fontWeight: "400",
      fontSize: "16px",
      color: "#404A56",
    },
    text24: {
      fontWeight: "700",
      fontSize: "24px",
      color: "#404A56",
    },
    body: {
      fontSize: "16px",
      fontWeight: "300",
      color: "#565656",
    },
    checkbox: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#565656",
    },
    wrapper: {
      textAlign: "center",
    },
  },
  tableStyle: {
    tableHeaders: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontWeightImportant: "700 !important",
      fontSize: "16px",
      lineHeight: "18px",
      color: "#343C46 !important",
    },
    tableBody: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#404A56 !important",
    },
  },
});

export default Theme;
