import React from "react";

import { ThemeProvider } from "@mui/material/styles";

import Theme from "../themes/Theme";

import Sesion from "../components/commons/sesion";
import Insights from "../components/insights/insights";

export default function Insight() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Insights"} />
      <ThemeProvider theme={Theme}>
        <Insights />
      </ThemeProvider>
    </div>
  );
}
