import React from "react";
import { useParams } from "react-router-dom";

import CardContent from "@mui/material/CardContent";

import Sesion from "../components/commons/sesion";
import OwnerDetailWc from "../components/owners/ownerDetailWc";
import Owners from "../components/owners/owners";

export default function WorkingCapital() {
  const { ownerId } = useParams();

  return (
    <div className={"Drawer"}>
      <CardContent>
        <Sesion screen={"WorkingCapital"} />
        {ownerId !== undefined ? <OwnerDetailWc idpdv={ownerId} /> : <Owners isWorkingCapital={true} />}
      </CardContent>
    </div>
  );
}
