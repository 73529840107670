import SingleBatchModal from "./singleBatchModal";

const DeleteModal = ({ batch, ...props }) => {
  const formatDate = (stringDate) => {
    let date = new Date(stringDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return `${day}/${month}/${year}`;
  };

  const columns = batch
    ? {
        "Risk.BatchId": batch.id,
        "Risk.CreatedDate": formatDate(batch.created_date),
        "Risk.UsedClients": batch.used_clients,
        "Risk.ShipmentNumber": batch.sent,
        "Risk.NumberOfAudiences": batch.audiences,
      }
    : {};

  return (
    <>
      {batch && (
        <SingleBatchModal
          action="Delete"
          url={`/batches/${batch.id}`}
          method="delete"
          columns={columns}
          batch={batch}
          id="id"
          {...props}
        />
      )}
    </>
  );
};

export default DeleteModal;
