import { Fragment, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Box } from "@mui/material";

const useStyles = makeStyles((_) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export function compareDates(a, b) {
  const dateA = new Date(a);
  const dateB = new Date(b);

  return dateA > dateB;
}

export function dateRangeLimiteInDays(lowDate, highDate, limit) {
  const differenceInMilliseconds = new Date(highDate).getTime() - new Date(lowDate).getTime();
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  return differenceInDays > limit;
}

export default function DateRangePicker(props) {
  const classes = useStyles();

  const {
    value = { start: null, end: null },
    limitInDays = 365,
    localeTexts = { start: "Start Date", end: "End Date" },
    errorTexts = {
      rangeDate: "La fecha de fin, debe ser mayor a la de inicio...",
      limitRange: "Se ha superado el rango entre fechas...",
    },
    onChange,
  } = props;

  const [startDate, setStartDate] = useState(value?.start ?? "");
  const [endDate, setEndDate] = useState(value?.end ?? "");
  const [errorText, setErrorText] = useState("");

  const handleStartDateChange = (event) => {
    event.preventDefault();

    const value = event.target.value;
    setStartDate(value);
  };

  const validateFields = (a, b) => {
    if (compareDates(a, b)) {
      setErrorText(errorTexts.rangeDate);
      return false;
    } else if (dateRangeLimiteInDays(a, b, limitInDays)) {
      setErrorText(errorTexts.limitRange);
      return false;
    } else {
      setErrorText("");
      return true;
    }
  };

  const handleEndDateChange = (event) => {
    event.preventDefault();
    if (!startDate) return;
    let endDateValue = event.target.value;
    const hasErrorField = !validateFields(startDate, endDateValue) || !endDateValue;
    if (hasErrorField) {
      endDateValue = "";
    }
    setEndDate(endDateValue);
    const start = new Date(startDate.concat("T00:00:00"));
    start.setHours(0, 0, 0, 0);
    const end = !endDateValue ? null : new Date(endDateValue.concat("T23:59:59"));
    end?.setHours(23, 59, 59, 999);
    const range = {
      start: start,
      end: end,
      hasError: hasErrorField,
    };
    onChange(range);
  };

  return (
    <div>
      <form className={classes.container} noValidate>
        <TextField
          id="start-date"
          data-testid="test-start-date-input"
          label={localeTexts.start}
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={!!endDate}
        />
        <Fragment>
          <Box sx={{ mx: 2 }}></Box>
        </Fragment>
        <TextField
          id="end-date"
          data-testid="test-end-date-input"
          label={localeTexts.end}
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={!startDate}
        />
      </form>
      {errorText && (
        <p data-testid="test-error-text-input" style={{ fontSize: "0.85rem", color: "red" }}>
          {errorText}
        </p>
      )}
    </div>
  );
}
