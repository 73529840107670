import { useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { ErrorOutline } from "@mui/icons-material";
import { Box, Card, Tab, Tabs } from "@mui/material";

import useClientDetails from "../../hooks/backoffice/useClientDetails";
import { formatDateString } from "../../utils/formatDate";

import FinancialProduct from "./financialProduct";
import Kyc from "./kyc";

import Invoices from "../../components/backoffice/invoices";
import BackButton from "../commons/backButton";
import ProfileAvatar from "../commons/backoffice/profileAvatar";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ClientDetails = ({ clientId }) => {
  const [t] = useTranslation("global");
  const [tabIndex, setTabIndex] = useState(0);
  const { client, isLoading } = useClientDetails(clientId);

  const tabStyles = {
    fontWeight: "400",
    fontSize: "14px",
    color: "#708195",
    "&.Mui-selected": {
      fontWeight: "600",
      fontSize: "14px",
      color: "#363853",
    },
  };

  const divider = (
    <div
      style={{
        border: "1px solid rgb(112, 129, 149)",
        height: "14px",
      }}
    />
  );

  const isPepIndicator = (
    <div style={{ color: "orangered" }}>
      <ErrorOutline sx={{ marginRight: "6px" }} />
      Persona políticamente expuesta (PEP)
    </div>
  );

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <div style={{ display: "block", marginBottom: "29px" }}>
        <BackButton backTo={"/Backoffice"} />
      </div>
      <label style={{ fontWeight: "400", fontSize: "14px", color: "#708195" }}>{t("Backoffice.Details.Label")}</label>
      <h2 style={{ fontWeight: "700", fontSize: "28px" }}>{t("Backoffice.Details.Title")}</h2>
      <Card
        sx={{
          padding: "40px",
          marginTop: "32px",
          boxShadow: "0px 4px 12px rgba(194, 209, 217, 0.46)",
        }}
      >
        <div style={{ display: "inline-block", verticalAlign: "middle" }}>
          <ProfileAvatar />
        </div>
        <div
          style={{
            display: "inline-block",
            marginLeft: "40px",
            verticalAlign: "middle",
          }}
        >
          <h2
            style={{
              fontWeight: "700",
              fontSize: "28px",
              color: "#363853",
              marginBottom: "2px",
            }}
          >
            {isLoading ? <Skeleton /> : client?.name}
          </h2>
          {isLoading ? (
            <Skeleton />
          ) : (
            <div
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "black",
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <p style={{ margin: 0 }}>ID: {client?.id}</p>
              {divider}
              <p style={{ margin: 0 }}>{client?.country}</p>
              {client?.isPep && (
                <>
                  {divider}
                  {isPepIndicator}
                </>
              )}
            </div>
          )}
          <h3
            style={{
              marginTop: "20px",
              fontWeight: "500",
              fontSize: "16px",
              color: "#363853",
            }}
          >
            {t("Backoffice.Details.ContactInfo")}
          </h3>
          <div style={{ fontWeight: "400", fontSize: "14px" }}>
            <p
              style={{
                display: "inline-block",
                color: "#708195",
                margin: "0",
              }}
            >
              {t("Backoffice.Details.Phone")}:
            </p>
            <p
              style={{
                display: "inline-block",
                color: "#4C5866",
                margin: "0 8px",
              }}
            >
              {isLoading ? <Skeleton width={100} /> : client?.phoneNumber}
            </p>
          </div>
          <div style={{ fontWeight: "400", fontSize: "14px" }}>
            <p
              style={{
                display: "inline-block",
                color: "#708195",
                margin: "0",
              }}
            >
              {t("Backoffice.Details.Email")}:
            </p>
            <p
              style={{
                display: "inline-block",
                color: "#4C5866",
                margin: "0 8px",
              }}
            >
              {isLoading ? <Skeleton width={100} /> : client?.email}
            </p>
          </div>
          <div style={{ fontWeight: "400", fontSize: "14px" }}>
            <p
              style={{
                display: "inline-block",
                color: "#708195",
                margin: "0",
              }}
            >
              {t("Backoffice.Details.Birthdate")}:
            </p>
            <p
              style={{
                display: "inline-block",
                color: "#4C5866",
                margin: "0 8px",
              }}
            >
              {isLoading ? <Skeleton width={100} /> : client?.birthdate ? formatDateString(client?.birthdate) : ""}
            </p>
          </div>
        </div>
      </Card>
      <Box
        sx={{
          marginTop: "40px",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: {
                background: "#FABE00",
              },
            }}
          >
            <Tab label={t("Backoffice.Details.PersonalData")} {...a11yProps(0)} sx={tabStyles} />
            <Tab label={t("Backoffice.Details.Invoices")} {...a11yProps(1)} sx={tabStyles} />
            <Tab label={t("Backoffice.Details.FinancialProduct")} {...a11yProps(2)} sx={tabStyles} />
          </Tabs>
        </Box>
        <Box sx={{ margin: "30px 0", height: "500px" }}>
          {tabIndex === 0 && <Kyc clientId={clientId} />}
          {tabIndex === 1 && <Invoices clientId={clientId} />}
          {tabIndex === 2 && <FinancialProduct clientId={client?.accountholderkey} loanId={client?.id} />}
        </Box>
      </Box>
    </>
  );
};

export default ClientDetails;
