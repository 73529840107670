import useSWR from "swr";

import ApisEnum from "../../utils/enums/apis.enum";

import useAxiosPrivate from "../useAxiosPrivate";

const useClientDetails = (clientId) => {
  const axiosClient = useAxiosPrivate(ApisEnum.Backoffice);

  const clientFetcher = async (url) => {
    const { data } = await axiosClient.get(url);
    return data;
  };

  const { data, isLoading, error } = useSWR(`/clients/${clientId}`, clientFetcher);

  return {
    isLoading,
    client: data,
    error,
  };
};

export default useClientDetails;
