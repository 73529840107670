import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";

import useLoanDetails from "../../../hooks/backoffice/useLoanDetails";
import LoanEnum from "../../../utils/enums/loan.enum";
import { formatCurrency } from "../../../utils/formatCurrency";
import { formatDateTimeString } from "../../../utils/formatDate";

import CustomButton from "./customButton";

const CustomSection = ({ title, data = [], isLoading }) => {
  return (
    <section style={{ marginTop: "32px" }}>
      <h3 style={{ fontSize: "16px", marginBottom: "16px" }}>{title}</h3>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
        {data.map((e) => (
          <div style={{ marginBottom: "16px", width: "70%" }}>
            <label
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#708195",
                margin: "0",
              }}
            >
              {e.label}
            </label>
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#343C46",
                margin: "0",
                wordWrap: "break-word",
              }}
            >
              {isLoading ? <Skeleton width="50%" /> : e.value}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default function LoanInfoModal({ open, onClose, loanId }) {
  const [t] = useTranslation("global");
  const { loan, isLoading } = useLoanDetails({ loanId });
  const loanEnum = new LoanEnum(t);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: "16px",
            width: "100%",
            minWidth: "50%",
            padding: "36px",
          },
        },
      }}
    >
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 style={{ fontWeight: "700", fontSize: "28px" }}>{t("Backoffice.FinancialProduct.LoanDetails.Title")}</h2>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <CustomSection
          title={t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.Title")}
          isLoading={isLoading}
          data={[
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.CustomerID"),
              value: loan?.customerId,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.ContractNumber"),
              value: loan?.contractNumber,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.LoanID"),
              value: loan?.loanId,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.IpUsed"),
              value: loan?.ipUsed,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.LoanType"),
              value: loanEnum.loanType[loan?.loanType],
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.Currency"),
              value: loan?.currency,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.FinancialActivity"),
              value: loan?.financialActivity,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.LoanTerm"),
              value: loan?.loanTerm,
            },
          ]}
        />
        <CustomSection
          title={t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.Title")}
          isLoading={isLoading}
          data={[
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.DisbursementCity"),
              value: loan?.disbursementCity,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.RequestTime"),
              value: formatDateTimeString(loan?.requestTime),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.DisbursementTime"),
              value: formatDateTimeString(loan?.disbursementTime),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.LoanDestination"),
              value: loan?.loanDestination,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.AmountRequested"),
              value: formatCurrency(loan?.amountRequested),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.WalletID"),
              value: loan?.walletId,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.AmountDisbursed"),
              value: formatCurrency(loan?.amountDisbursed),
            },
          ]}
        />
        <CustomSection
          title={t("Backoffice.FinancialProduct.LoanDetails.PaymentInfo.Title")}
          isLoading={isLoading}
          data={[
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.PaymentInfo.PaymentsQuantity"),
              value: loan?.paymentsQuantity,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.PaymentInfo.TransactionExpirationDate"),
              value: formatDateTimeString(loan?.transactionExpirationDate),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.PaymentInfo.TransactionCancellationDate"),
              value: formatDateTimeString(loan?.transactionCancellationDate),
            },
          ]}
        />
        <CustomSection
          title={t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.Title")}
          isLoading={isLoading}
          data={[
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.EarlyCancellationDays"),
              value: loan?.earlyCancellationDays,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.MoratoriumDays"),
              value: loan?.moratoriumDays,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.OperationCurrentBalance"),
              value: formatCurrency(loan?.operationCurrentBalance),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.MoratoriumInterest"),
              value: formatCurrency(loan?.moratoriumInterest),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.AgentCode"),
              value: loan?.agentCode,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.CompensatoryInterest"),
              value: formatCurrency(loan?.compensatoryInterest),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.PlaceAndMethodPayment"),
              value: loan?.placeAndMethodPayment,
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.PunitiveInterest"),
              value: formatCurrency(loan?.punitiveInterest),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.AdministrativeExpenses"),
              value: formatCurrency(loan?.administrativeExpenses),
            },
          ]}
        />
      </DialogContent>
      <DialogActions sx={{ marginTop: "16px" }}>
        <CustomButton width="20%" onClick={handleClose}>
          {t("Backoffice.FinancialProduct.LoanDetails.Close")}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
