import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Avatar, Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getFile, updateDocumentStatus } from "../../utils/actions/axios-api-kym";
import {
  sendMailDocumentRemedy,
  validateDocumentsComplete,
  validDocumentsAbbreviate,
} from "../../utils/actions/axios-api-wc";
import { SnackBar } from "../../utils/alert";
import PDFPreview from "../../utils/PDFPreview";

const Title = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#404A56",
  boxShadow: "none",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
}));

const DetailDocuments = ({ detailDocuments, translate, icons }) => {
  const [t] = useTranslation("global");
  const country = "py";
  const [keyDocument, setKeyDocument] = useState("");
  const [valueRadio, setValueRadio] = useState(null);
  const [optionReject, setOptionReject] = useState(null);
  const [textReason, setTextReason] = useState("");

  const [urlImage, setUrlImage] = useState("");
  const [openImage, setOpenImage] = React.useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [openPdf, setopenPdf] = useState(false);
  const [filename, setfilename] = useState("");
  const handleOpen = (data) => {
    const { extension } = getFileNameAndExtension(data.data);
    if (extension === "pdf") {
      setopenPdf(true);
    } else {
      setOpenImage(true);
    }
  };
  const handleClose = () => setOpenImage(false);
  const handleClosePdf = () => setopenPdf(false);
  const handleOpenOptions = () => setOpenOptions(true);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleChangeReject = (event) => {
    setOptionReject(event.target.value);
  };

  const handleChangeInput = (e) => {
    setTextReason(e.target.value);
  };

  const handleCloseOptions = () => {
    setValueRadio(null);
    setOpenOptions(false);
  };

  function getFileNameAndExtension(url) {
    try {
      const splitUrl = url.split("?response-content-disposition");
      const fileNameAndExtension = splitUrl[0].split("/").pop();
      const splitFileName = fileNameAndExtension.split(".");
      const fileName = splitFileName[0];
      const extension = splitFileName[1];
      setfilename(fileName);
      return { extension };
    } catch (error) {
      console.log(error);
    }
  }

  const downloadFile = (key, type) => {
    let isView = "view" === type;
    getFile(key, type)
      .then((response) => {
        if (response.status === 200) {
          setUrlImage(response.data);
          if (isView) {
            handleOpen(response);
          } else {
            window.open(response.data);
          }
        } else {
          SnackBar("Error al desplegar el documento", "warning", "#FABE00");
        }
      })
      .catch((error) => {
        console.log(error);
        SnackBar("Error al desplegar el documento", "warning", "#FABE00");
      });
  };

  const openValidateDialog = (key) => {
    setKeyDocument(key);
    handleOpenOptions();
  };

  const buildDocuments = (docs) => {
    let gridArray = [];
    let count = 0;
    if (docs?.Documents?.documents_list) {
      Object.keys(docs.Documents.documents_list).forEach(function (key) {
        let status = docs.Documents.documents_list[key].status;
        count++;
        let keyDocument = key;
        let validateicon;
        let classaContent;
        let classNumber;
        switch (status) {
          case "pending":
            validateicon = <div>{count}</div>;
            classaContent = "box-principal-document";
            classNumber = "number-box";
            break;
          case "rejected":
            validateicon = (
              <div>
                <ErrorOutlineIcon />
              </div>
            );
            classaContent = "box-principal-document rejected";
            classNumber = "number-box rejected";
            break;
          default:
            validateicon = (
              <div>
                <CheckCircleOutlineIcon />
              </div>
            );
            classaContent = "box-principal-document validate";
            classNumber = "number-box validate";
        }

        gridArray.push(
          <Box className={classaContent} key={count} data-testid={"box-" + count}>
            <Box className={classNumber}>{validateicon}</Box>
            <Box className={"title-box"}>
              <CardContent style={{ padding: "0px", boxShadow: "none" }}>
                <Card>{t(translate + ".Document." + key)}</Card>
                {status === "rejected" && (
                  <Card
                    style={{
                      fontSize: "14px",
                      color: "#708195",
                      borderTop: "1px solid #CFD5DC",
                    }}
                  >
                    {" "}
                    Motivo del rechazo:{" "}
                    {docs.Documents.documents_list[key].reasonRejected === "Otro motivo"
                      ? docs.Documents.documents_list[key].otherReason
                      : docs.Documents.documents_list[key].reasonRejected}
                  </Card>
                )}
              </CardContent>
            </Box>
            <Box className={"box-actionsContainer"}>
              <FileDownloadOutlinedIcon
                data-testid={"download-icon-" + count}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  downloadFile(docs.Documents.documents_list[key].key, "download");
                }}
              />
            </Box>
            <Box className={"box-actionsContainer"}>
              <VisibilityOutlinedIcon
                data-testid={"see-icon-" + count}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  downloadFile(docs.Documents.documents_list[key].key, "view");
                }}
              />
            </Box>
            {status === "pending" && (
              <Box
                className={"content-button-validate"}
                onClick={() => openValidateDialog(keyDocument)}
                data-testid="btn-validate"
              >
                <Box style={{ fontFamily: "Roboto" }} className={"button-validate"}>
                  Validar
                </Box>
              </Box>
            )}
          </Box>
        );
      });
    } else {
      gridArray.push(
        <div style={{ height: "50vh" }}>
          <div
            style={{
              position: "relative",
              left: "80%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Card
              style={{
                padding: "0px",
                boxShadow: "none",
                background: "transparent",
              }}
            >
              {t("General.Message.NoData")}
            </Card>
          </div>
        </div>
      );
    }
    return gridArray;
  };

  const validDocuments = async () => {
    let status;
    let messageSnack;
    let data;
    let email = detailDocuments.Profile.general_information.email;
    let formId = detailDocuments.Documents.form_id;
    let idpdv = detailDocuments.Profile.general_information.idpdv;
    if (valueRadio === null) {
      SnackBar("select a radio please");
      return;
    } else if (valueRadio === false) {
      messageSnack = "Se ha enviado un correo al cliente solicitando de nuevo el documento rechazado.";
      status = "rejected";
      data = `{
        "Negocio": {
          "documents": {
            "${keyDocument}": {
                "status": "${status}",
                "reasonRejected":"${optionReject}",
                "otherReason":"${textReason}"
              }
            }
        }
      }`;
    } else {
      messageSnack = "Documento validado correctamente";
      status = "Validated";
      data = `{
        "Negocio": {
          "documents": {
            "${keyDocument}": {
                "status": "${status}"
              }
            }
        }
      }`;
    }

    data = JSON.parse(data);
    const response = await updateDocumentStatus(email, formId, country, {
      ...data,
    });
    if (response.status === 200) {
      detailDocuments.Documents.documents_list[keyDocument]["status"] = status;
      detailDocuments.Documents.documents_list[keyDocument]["otherReason"] = textReason;
      detailDocuments.Documents.documents_list[keyDocument]["reasonRejected"] = optionReject;
      const responseValidDocs = await validateDocumentsComplete(email, formId, country);
      if (responseValidDocs?.status === 200)
        if (responseValidDocs?.data.send_email === true) {
          sendMailDocumentRemedy({
            email,
            id_pdv: idpdv,
            documents: responseValidDocs.data.documents,
          })
            .then((response) => console.log(response))
            .catch((error) => console.log(error));
        } else {
          validDocumentsAbbreviate({
            id_pdv: idpdv,
          })
            .then((response) => console.log(response))
            .catch((error) => console.log(error));
        }

      handleCloseOptions();
      SnackBar(messageSnack, "info", "#EDF7EF");
    } else {
      setTextReason("");
      SnackBar(messageSnack, "info", "#EDF7EF");
    }
  };

  return (
    <Card style={{ borderRadius: "20px" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{ borderStyle: "none" }} className={"card-grid-padding"}>
          <Grid item xs={6}>
            <Grid item xs={12} sx={{ borderStyle: "none" }}>
              {icons ? (
                <Box style={{ width: "8%", float: "left" }}>
                  <Avatar
                    style={{
                      backgroundColor: "#FFC70D",
                      color: "#FFFFFF",
                      marginBottom: "10px",
                    }}
                  >
                    <AccountBalanceIcon />
                  </Avatar>
                </Box>
              ) : (
                <></>
              )}
              <Box
                style={{
                  width: "92%",
                  float: "left",
                  marginBottom: "10px",
                }}
              >
                <Title>{t(`${translate}.Document.Document`)}</Title>
              </Box>
              <Box
                key={"documents-box"}
                itemID={"documents-box"}
                style={{
                  width: "92%",
                  float: "left",
                  marginBottom: "10px",
                }}
              >
                {buildDocuments(detailDocuments)}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Dialog fullScreen={fullScreen} open={openImage} onClose={handleClose}>
        <DialogContent>
          <img data-testid="DialogImage" src={urlImage} alt={filename} width="100%" height="100%" />
        </DialogContent>
      </Dialog>

      <Dialog fullScreen={fullScreen} open={openPdf} onClose={handleClosePdf}>
        <DialogContent>
          <PDFPreview pdfUrl={urlImage} />
        </DialogContent>
      </Dialog>

      <Dialog fullScreen={fullScreen} open={openOptions} onClose={handleCloseOptions} className={"validateDialog"}>
        <DialogContent>
          <Box className={"boxClose"}>
            <Button onClick={handleCloseOptions}>
              <CancelOutlinedIcon style={{ width: "24px" }} />
            </Button>
          </Box>
          <h5>{t(`${translate}.Document.TitleModal`)}</h5>
          <p style={{ padding: "10px 0" }}>{t(`Owners.Document.Question`)}</p>
          <Box style={{ width: "100%", padding: "10px 0" }}>
            <Box style={{ float: "left", marginRight: "20px", width: "50px" }}>
              <input
                type="radio"
                data-testid="radio-yes"
                value="yes"
                onChange={(_) => setValueRadio(true)}
                name="validateDocument"
              />{" "}
              {t(`${translate}.Document.RadioYes`)}
            </Box>
            <Box style={{ float: "left", marginRight: "39px", width: "50px" }}>
              <input
                type="radio"
                data-testid="radio-no"
                value="no"
                onChange={(_) => setValueRadio(false)}
                name="validateDocument"
              />{" "}
              {t(`${translate}.Document.RadioNo`)}
            </Box>
            {valueRadio === false && (
              <Box style={{ clear: "both", width: "300px", marginTop: "40px" }}>
                <FormControl
                  sx={{
                    m: 1,
                    width: "250px",
                    borderRadius: "10px",
                    height: "35px",
                  }}
                >
                  <InputLabel id="demo-simple-select-autowidth-label">
                    {t(`${translate}.Document.ReasonReject`)}
                  </InputLabel>
                  <Select
                    value={optionReject}
                    onChange={handleChangeReject}
                    label="Motivo de rechazo"
                    autoWidth
                    style={{ borderRadius: "20px", height: "40px" }}
                    inputProps={{ "data-testid": "reject-options" }}
                  >
                    <MenuItem value="">
                      <em></em>
                    </MenuItem>
                    <MenuItem value={t(`${translate}.Document.unfocused`)} data-testid={"unfocused"}>
                      {t(`${translate}.Document.unfocused`)}
                    </MenuItem>
                    <MenuItem data-testid={"invalid1"} value={t(`${translate}.Document.darkfile`)}>
                      {t(`${translate}.Document.darkfile`)}
                    </MenuItem>
                    <MenuItem data-testid={"invalid2"} value={t(`${translate}.Document.timeout`)}>
                      {t(`${translate}.Document.timeout`)}
                    </MenuItem>
                    <MenuItem data-testid={"invalid3"} value={t(`${translate}.Document.invalid`)}>
                      {t(`${translate}.Document.invalid`)}
                    </MenuItem>
                    <MenuItem data-testid={"invalid4"} value={t(`${translate}.Document.others`)}>
                      {t(`${translate}.Document.others`)}
                    </MenuItem>
                  </Select>
                </FormControl>
                {optionReject === t(`${translate}.Document.others`) && (
                  <FormControl style={{ marginTop: "15px" }}>
                    <TextField
                      placeholder={t(`${translate}.Document.explain`)}
                      multiline
                      value={textReason}
                      data-testid={"textreason"}
                      rows={2}
                      onChange={handleChangeInput}
                      maxRows={6}
                      style={{ width: "250px" }}
                    />
                  </FormControl>
                )}
              </Box>
            )}
          </Box>
          <Box style={{ marginTop: "30px", clear: "both", width: "320px" }}>
            <Box
              style={{
                float: "right",
                marginLeft: "39px",
                textAlign: "right",
                display: "inline-block",
              }}
            >
              <Button
                data-testid={"button-accept"}
                style={{
                  padding: "16px",
                  gap: "16px",
                  width: "129px",
                  height: "48px",
                  background: "#363853",
                  color: "#ffffff",
                  borderRadius: "16px",
                  order: "1",
                }}
                onClick={validDocuments}
              >
                {t(`${translate}.Document.ButtonAcept`)}
              </Button>
            </Box>
            <Box
              style={{
                float: "right",
                marginLeft: "39px",
                textAlign: "right",
                display: "inline-block",
              }}
            >
              <Button onClick={handleCloseOptions}>{t(`${translate}.Document.ButtonCancel`)}</Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default DetailDocuments;
