import axios from "axios";

const BASE_URL = process.env.REACT_APP_DOMAIN;

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_DOMAIN_APIKEY,
    Accept: "application/json",
  },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_DOMAIN_APIKEY,
    Accept: "application/json",
  },
});

export const axiosPrivateRisk = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN_RISK,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_DOMAIN_RISK_APIKEY,
    Accept: "application/json",
  },
});

export const axiosPrivateBackoffice = axios.create({
  baseURL: process.env.REACT_APP_BACKOFFICE_DOMAIN,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_BACKOFFICE_APIKEY,
    Accept: "application/json",
  },
});
