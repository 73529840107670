import { ThemeProvider } from "@mui/material/styles";

import Sesion from "../components/commons/sesion";
import ServiceContainer from "../components/services/serviceContainer";
import ThemeRisks from "../themes/ThemeRisks";

export default function BackofficeServiceMenu() {
  return (
    <ThemeProvider theme={ThemeRisks}>
      <div className={"Drawer"}>
        <Sesion screen={"BackofficeRisk"} />
        <ServiceContainer></ServiceContainer>
      </div>
    </ThemeProvider>
  );
}
