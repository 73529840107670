import { formatDateToDDMMYYYY } from "../../../utils/commons/dateUtils";

import { axiosClient, axiosClientKym } from "../../../commons/axiosClient";

const getKyxMicromerchants = async (params) => {
  const status = params?.status ? params.status : "";
  const page = params?.page ? params.page : 1;
  const limit = params?.limit ? params.limit : 10;
  const search = typeof params?.search === "object" ? params.search : {};
  const sort = typeof params?.sort === "object" ? params.sort : {};
  const country = params?.country;

  const url = "micromerchants";
  try {
    const response = await axiosClientKym.get(url, {
      params: {
        country: country,
        page: page,
        limit: limit,
        search: search,
        sort: sort,
        status: status,
      },
    });
    return response;
  } catch (error) {
    return null;
  }
};

const getKyxMicromerchantById = async (params) => {
  const country = params?.country;
  const id = params?.id ? params.id : null;
  const url = `micromerchant/${id}`;
  try {
    const response = await axiosClientKym.get(url, {
      params: { country: country },
    });
    return response.data;
  } catch (error) {
    const exception = new Error("Api Exception");
    exception.response = error?.response?.data;
    exception.isAxiosError = error?.isAxiosError;
    exception.status = error?.response?.status;
    throw exception;
  }
};

const updateMicroMerchantById = async (params) => {
  const id = params?.id;
  const url = `micromerchant/${id}`;
  try {
    const response = await axiosClient.patch(url, { ...params.body });
    return response.data;
  } catch (error) {
    const exception = new Error("Api Exception");
    exception.response = error?.response?.data;
    exception.isAxiosError = error?.isAxiosError;
    exception.status = error?.response?.status;
    throw exception;
  }
};

const getKyxMicromerchantUserOne = async (params) => {
  try {
    const response = await axiosClientKym.get("user/one", {
      params: { wallet_id: params.wallet_id },
    });
    return response.data;
  } catch (error) {
    const exception = new Error("Api Exception");
    exception.response = error?.response?.data;
    exception.isAxiosError = error?.isAxiosError;
    exception.status = error?.response?.status;
    throw exception;
  }
};

const sendHistoricalMMToEmail = async (params) => {
  const country = params?.country;
  const email = params.email;
  const startDate = formatDateToDDMMYYYY(params.startDate);
  const endDate = formatDateToDDMMYYYY(params.endDate);
  const url = `micromerchants/historical`;
  const body = {
    email,
    country,
    start_date: startDate,
    end_date: endDate,
  };
  try {
    const response = await axiosClientKym.post(url, {
      ...body,
    });
    return response.data;
  } catch (error) {
    const exception = new Error("Api Exception");
    exception.response = error?.response?.data;
    exception.isAxiosError = error?.isAxiosError;
    exception.status = error?.response?.status;
    throw exception;
  }
};

export {
  getKyxMicromerchants,
  getKyxMicromerchantById,
  getKyxMicromerchantUserOne,
  updateMicroMerchantById,
  sendHistoricalMMToEmail,
};
