import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Download, RemoveRedEye } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { formatCurrency } from "../../../utils/formatCurrency";
import { formatDateTimeString } from "../../../utils/formatDate";

export default function InvoicesList({
  invoices = [],
  isLoading = false,
  onPageChange = () => {},
  totalItems = 0,
  currentPage = 0,
  disableVirtualization = false,
}) {
  const [t] = useTranslation("global");
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: 5,
  });

  const renderActions = () => {
    return (
      <>
        <IconButton onClick={() => {}}>
          <Download
            sx={{
              color: "#404A56",
            }}
          />
        </IconButton>
        <IconButton onClick={() => {}}>
          <RemoveRedEye
            sx={{
              color: "#404A56",
            }}
          />
        </IconButton>
      </>
    );
  };

  const columns = [
    {
      field: "cdc",
      headerName: t("Backoffice.InvoicesTable.Cdc"),
      flex: 0.2,
    },
    {
      field: "date",
      headerName: t("Backoffice.InvoicesTable.Date"),
      flex: 0.2,
    },
    {
      field: "amount",
      headerName: t("Backoffice.InvoicesTable.Amount"),
      flex: 0.15,
    },
    {
      field: "billingId",
      headerName: t("Backoffice.InvoicesTable.BillingId"),
      flex: 0.15,
    },
    {
      field: "idNumber",
      headerName: t("Backoffice.InvoicesTable.IdNumber"),
      flex: 0.15,
    },
    {
      field: "loanAccountId",
      headerName: t("Backoffice.InvoicesTable.LoanAccountId"),
      flex: 0.15,
    },
    {
      field: "description",
      headerName: t("Backoffice.InvoicesTable.Description"),
      flex: 0.15,
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.1,
      renderCell: renderActions,
    },
  ];

  const rows = invoices.map((invoice) => {
    return {
      ...invoice,
      date: formatDateTimeString(invoice.date),
      amount: formatCurrency(invoice.amount),
    };
  });

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <DataGrid
          getRowId={(row) => row.cdc}
          columns={columns}
          rows={rows}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          rowCount={totalItems}
          loading={isLoading}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(newPagination) => {
            setPaginationModel(newPagination);
            onPageChange(newPagination.page);
          }}
          disableVirtualization={disableVirtualization}
          sx={{
            height: rows.length === 0 ? "401px" : null,
            padding: "16px",
            border: "none",
            "&>.MuiDataGrid-main": {
              "& div div div div >.MuiDataGrid-cell": {
                borderBottom: "none",
              },
            },
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: "#EEF0F2",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            },
          }}
        />
      </Box>
    </>
  );
}
