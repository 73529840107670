export default function pathindex(pathname) {
  switch (pathname) {
    case "/":
      return 0;
    case "/Insights":
      return 1;
    case "/Merchants":
      return 2;
    case "/Operations":
      return 3;
    case "/Configuration":
      return 4;
    case "/IntegratorConfiguration":
      return 5;
    case "/Help":
      return 6;
    default:
      return 0;
  }
}
