import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { SnackBar } from "../../components/utils/alert";

import { axiosClient, axiosClientKym } from "../commons/axiosClient";
import MMButtonCustom, { MMTypeButtonStyles } from "../micromerchants/components/MMButtonCustom";
import MMDialogCustom from "../micromerchants/components/MMDialogCustom";
import MMUnBlockedDialog from "../micromerchants/dialogs/MMUnBlockedDialog";

export const TypeModals = Object.freeze({
  Empty: Symbol("Empty"),
  Approve: Symbol("Approve"),
  Blocked: Symbol("Blocked"),
  Inreview: Symbol("Inreview"),
  UnBlocked: Symbol("Inreview"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxshadow: 24,
  p: 4,
};

const ModalStatusMM = ({
  row,
  setMmSelected,
  typeModal,
  openModal,
  setShowModal,
  data,
  setData,
  dataBackUp,
  setBackUp,
  setPage,
  country,
  changeDataTable,
}) => {
  const [t] = useTranslation("global");
  const [selectOptions, setSelectOptions] = useState([]);
  const [valueSelect, setValueSelect] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let errorModal = false;

  const handleClose = () => {
    changeDataTable();
    setInputValue("");
    setValueSelect("");
    setMmSelected([]);
    setShowModal(false);
  };

  useEffect(() => {
    getCatalogData();
  }, []);

  const getCatalogData = () => {
    const url = "review/options";
    axiosClient.get(url).then((res) => {
      setSelectOptions(res.data);
    });
  };

  const handleChange = (e) => {
    setError(false);
    errorModal = false;
    if (e.target.value !== "otro") {
      setInputValue("");
    }
    setValueSelect(e.target.value);
  };

  const handleChangeInput = (e) => {
    setInputValue(e.target.value);
  };

  const buildOptions = (item) => {
    return (
      <MenuItem key={item.id} value={item.description}>
        {item.description}
      </MenuItem>
    );
  };

  const updateClientKms = async (id, body) => {
    const dataUpdate = JSON.parse(JSON.stringify(body));
    delete dataUpdate["form_status"]["reason"];
    delete dataUpdate["country"];
    const url = `micromerchant/${id}`;
    return axiosClient.patch(url, { ...dataUpdate }).then((response) => {
      return response;
    });
  };

  const updateClientKyx = async (id, body) => {
    const url = `micromerchant/${id}`;
    return axiosClientKym.patch(url, { ...body }).then((response) => {
      return response.data;
    });
  };

  const updateClient = async (clientId, data) => {
    await updateClientKms(clientId, data);
    await updateClientKyx(clientId, data);
    const clientUpdated = {
      ...row,
      ...data["form_status"],
      ...data["business_profile"],
    };
    return clientUpdated;
  };

  const updateFormStatus = (status) => {
    const formStatus = {
      form_status: status,
      id_revision_catalog: null,
      description: null,
      reason: null,
    };

    if (valueSelect !== "") {
      if (valueSelect === "otro") {
        formStatus["reason"] = inputValue;
        formStatus["description"] = inputValue;
      } else {
        const elemento = selectOptions.filter((x) => x["description"] === valueSelect);
        formStatus["reason"] = valueSelect;
        formStatus["id_revision_catalog"] = elemento[0]["id"];
      }
    }

    return Object.freeze({ ...formStatus });
  };

  const updateClientBlocked = async (clientId) => {
    const type = typeModal.description.toLowerCase();
    const form_status = updateFormStatus(type);
    const dataUpdate = {
      country: country,
      business_profile: {
        is_active: false,
      },
      physicalstore: {},
      onlinestore: {},
      docs: {},
      micromerchantadditional: {},
      profile_create: {},
      form_status,
    };

    await updateClient(clientId, dataUpdate);
  };

  const updateClientApproved = async (clientId, level) => {
    const dataUpdate = {
      country: country,
      form_status: { ...updateFormStatus("complete") },
      business_profile: {
        is_active: level === "6000" ? true : false,
      },
    };
    await updateClient(clientId, dataUpdate);
  };

  const validateSelect = async (clientId) => {
    setError(false);
    errorModal = false;
    if ((valueSelect === "otro" && inputValue === "") || valueSelect === "") {
      setError(true);
      errorModal = true;
    } else {
      await updateClientBlocked(clientId);
    }
  };

  const chooseTypeUpdate = async () => {
    const mmClient = Object.assign({}, row);
    const clientId = mmClient["client_id"];
    const level = mmClient["level"];

    if (typeModal === TypeModals.Approve || typeModal === TypeModals.UnBlocked) {
      await updateClientApproved(clientId, level);
    } else {
      await validateSelect(clientId);
    }
  };

  const updateClientHandler = () => {
    setIsLoading(true);
    chooseTypeUpdate()
      .then(() => {
        if (!errorModal) updateTable();
      })
      .catch((_) => {
        SnackBar(t("Micromerchants.Modal.Commons.Errors.MessageErrorConnection"), "error");
      })
      .finally(() => {
        setIsLoading(false);
        if (!errorModal) handleClose();
      });
  };

  const updateTable = () => {
    let consultaLongitud = [];
    let dataNew = data.filter((item) => item["client_id"] !== row["client_id"]);
    let dataBackUpNew = dataBackUp.filter((item) => item["client_id"] !== row["client_id"]);

    const level =
      localStorage.getItem("Micromerchant-level") !== null ? localStorage.getItem("Micromerchant-level") : "";

    consultaLongitud = dataNew;
    if (level !== "") {
      const arr = ["5000", "6000"];
      if (level === "otros") {
        consultaLongitud = dataNew.filter((mm) => {
          return !arr.includes(mm["level"]);
        });
      } else {
        consultaLongitud = dataNew.filter((mm) => {
          return mm["level"] === level;
        });
      }
    }

    setData(dataNew);
    setBackUp(dataBackUpNew);
    restructurePagination(consultaLongitud);
    changeDataTable();
  };

  const restructurePagination = (consultaLongitud) => {
    const rowsPerPage =
      localStorage.getItem("Micromerchant-rowsPerPage") !== null
        ? parseInt(localStorage.getItem("Micromerchant-rowsPerPage"))
        : 50;
    const page =
      localStorage.getItem("Micromerchant-page") !== null ? parseInt(localStorage.getItem("Micromerchant-page")) : 0;

    let division = consultaLongitud.length / rowsPerPage;
    let newPage = page;

    if (division === page) {
      newPage = page - 1;
      localStorage.setItem("Micromerchant-page", newPage);
      setPage(newPage);
    }
  };

  return (
    <ModalType
      openModal={openModal}
      handleClose={handleClose}
      t={t}
      valueSelect={valueSelect}
      handleChange={handleChange}
      typeModal={typeModal}
      inputValue={inputValue}
      handleChangeInput={handleChangeInput}
      buildOptions={buildOptions}
      selectOptions={selectOptions}
      onAction={updateClientHandler}
      isLoading={isLoading}
      error={error}
    />
  );
};

const ModalType = (props) => {
  switch (props.typeModal) {
    case TypeModals.Approve:
      return <MMApproveDialog isOpen={props.openModal} onAction={props.onAction} onClose={props.handleClose} />;
    case TypeModals.Inreview:
    case TypeModals.Blocked:
      return (
        <MMModal
          openModal={props.openModal}
          handleClose={props.handleClose}
          t={props.t}
          valueSelect={props.valueSelect}
          handleChange={props.handleChange}
          typeModal={props.typeModal}
          inputValue={props.inputValue}
          handleChangeInput={props.handleChangeInput}
          buildOptions={props.buildOptions}
          selectOptions={props.selectOptions}
          onAction={props.onAction}
          error={props.error}
        />
      );
    case TypeModals.UnBlocked:
      return (
        <MMUnBlockedDialog
          isOpen={props.openModal}
          onAction={props.onAction}
          isLoading={props.isLoading}
          onClose={props.handleClose}
        />
      );
    default:
      return <div data-testid="test-modal-empty"></div>;
  }
};

export default ModalStatusMM;

const MMModal = (props) => {
  const {
    openModal,
    handleClose,
    valueSelect,
    handleChange,
    typeModal,
    inputValue,
    handleChangeInput,
    buildOptions,
    selectOptions,
    onAction,
    error,
  } = props;

  const [t] = useTranslation("global");

  return (
    <Fragment>
      <Modal
        data-testid="test-mm-modal"
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            style={{
              position: "absolute",
              right: "11px",
              top: "0px",
              color: "#343C46",
              fontSize: "20px",
              padding: "5px 10px",
            }}
            onClick={handleClose}
            data-testid="icon-close"
          >
            x
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              fontWeight: 600,
              fontStyle: "normal",
              fontSize: "22px",
              color: "#343C46",
            }}
          >
            {t(`Micromerchants.Modal.${typeModal.description}.Title`)}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
            }}
          >
            {t(`Micromerchants.Modal.${typeModal.description}.Description`)}
          </Typography>
          <Stack width="100%" mt={2}>
            <FormControl>
              <InputLabel id="select-reason">{t(`Micromerchants.Modal.${typeModal.description}.Select`)}</InputLabel>
              <Select
                labelId="select-change-level-lock"
                id="select-change-level-lock-helper"
                value={valueSelect}
                label="reason"
                data-testid="select-modal-status-test"
                sx={{ paddingBottom: "0px", borderRadius: "16px" }}
                onChange={handleChange}
                error={error}
              >
                <MenuItem value=""></MenuItem>
                {selectOptions.map((sec, _) => {
                  let codeType = typeModal === TypeModals.Blocked ? 2 : 1;
                  return codeType === sec.type ? buildOptions(sec) : "";
                })}
                <MenuItem value="otro">{t(`Micromerchants.Modal.${typeModal.description}.OptOther`)}</MenuItem>
              </Select>
              {valueSelect === "otro" ? (
                <div style={{ marginTop: "10px" }}>
                  <TextField
                    className="inputRounded"
                    id="outlined-other-input"
                    label={t(`Micromerchants.Modal.${typeModal.description}.TextField`)}
                    value={inputValue}
                    onChange={handleChangeInput}
                    error={error}
                    style={{
                      width: "100%",
                    }}
                    data-testid="textfield-modal-status-test"
                  />
                </div>
              ) : (
                <div></div>
              )}
              {error ? (
                <FormHelperText data-testid="test-modal-Lock-message-error" style={{ color: "red" }}>
                  {t(`Micromerchants.Modal.${typeModal.description}.MessageError`)}
                </FormHelperText>
              ) : (
                <div></div>
              )}
            </FormControl>
          </Stack>
          <div width="100%" className="buttonModalMM">
            <Button className="swal-button-outlined btn" onClick={handleClose}>
              {t(`Micromerchants.Modal.${typeModal.description}.Actions.BtnCancel`)}
            </Button>
            <Button
              className="swal-button-contained btn"
              onClick={onAction}
              data-testid="btn-modal-status-continue-test"
            >
              {t(`Micromerchants.Modal.${typeModal.description}.Actions.BtnContinue`)}
            </Button>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};

const MMApproveDialog = (props) => {
  const { onAction, onClose, isOpen } = props;

  const [t] = useTranslation("global");

  const buildActionsModal = () => {
    return (
      <Fragment>
        <MMButtonCustom nameButton={"button-cancel"} onAction={onClose} type={MMTypeButtonStyles.Secondary}>
          <span>{t("Micromerchants.Modal.Commons.Actions.BtnCancel")}</span>
        </MMButtonCustom>
        <MMButtonCustom nameButton={"button-accept"} onAction={onAction}>
          <span>{t("Micromerchants.Modal.Approbe.Actions.BtnApprobe")}</span>
        </MMButtonCustom>
      </Fragment>
    );
  };

  return (
    <MMDialogCustom
      title={t("Micromerchants.Modal.Approbe.Title")}
      open={isOpen}
      onClose={onClose}
      actions={buildActionsModal()}
    >
      <Box sx={{ height: 145 }}>
        <span>{t("Micromerchants.Modal.Approbe.Description")}</span>
      </Box>
    </MMDialogCustom>
  );
};
