import React from "react";
import { useTranslation } from "react-i18next";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";

const RecordsNumbers = ({ ClientsUsedBatches, AviableClients, InitialRisks }) => {
  const [t] = useTranslation("global");

  const styleBackgroundAccordion = {
    background: "#E7EFF9",
    borderRadius: "15px",
    marginTop: "15px",
    position: "unset",
    boxShadow: "none",
  };

  const styleContainerAccordion = {
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
    position: "center",
  };

  const styleContainerItem = {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    position: "center",
  };
  return (
    <>
      <Typography variant="h6">{t(`Risk.RecordsDataBase`)}</Typography>
      <Accordion style={styleBackgroundAccordion} disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div style={styleContainerAccordion}>
            <div style={styleContainerItem}>
              <Typography variant="subtitle1">{t(`Risk.ClientsUsedBatches`)}:</Typography>
              <Typography variant="body1">{ClientsUsedBatches}</Typography>
            </div>
            <div style={styleContainerItem}>
              <Typography variant="subtitle1">{t(`Risk.AviableClients`)}:</Typography>
              <Typography variant="body1">{AviableClients}</Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid justifyContent="space-between" alignItems="center" direction="row" container>
            {InitialRisks != undefined &&
              InitialRisks.map((item, key) => (
                <div style={styleContainerItem} key={key}>
                  <Typography variant="subtitle1">{t(`Risk.${item.name}`)}:</Typography>
                  <Typography variant="body1">{item.amount}</Typography>
                </div>
              ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default RecordsNumbers;
