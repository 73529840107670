import React from "react";

import CardContent from "@mui/material/CardContent";
import { ThemeProvider } from "@mui/material/styles";

import DragAndDrop from "../../components/commons/DragAndDrop";
import Sesion from "../../components/commons/sesion";
import ThemeRisks from "../../themes/ThemeRisks";

export default function BankPayments() {
  return (
    <ThemeProvider theme={ThemeRisks}>
      <div className={"Drawer"}>
        <CardContent>
          <Sesion screen={"BankPayments"} subtitle={"Risks"} />
        </CardContent>
        <CardContent
          sx={{
            width: "88%",
            height: "49%",
            padding: "40px",
            borderRadius: "16px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            gap: "32px",
            position: "absolute",
            top: "222px",
            left: "144px",
          }}
        >
          <DragAndDrop />
        </CardContent>
      </div>
    </ThemeProvider>
  );
}
