import { useState } from "react";
import { useTranslation } from "react-i18next";

import { RemoveRedEye } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { formatCurrency } from "../../../utils/formatCurrency";
import { formatDateTimeString } from "../../../utils/formatDate";

import LoanInfoModal from "./loanInfoModal";

export default function LoansList({
  loans = [],
  isLoading = false,
  onPageChange = () => {},
  totalItems = 0,
  currentPage = 0,
  disableVirtualization = false,
}) {
  const [t] = useTranslation("global");
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: 5,
  });
  const [selectedLoanId, setSelectedLoanId] = useState(null);

  const handleOpenDetails = (loanId) => setSelectedLoanId(loanId);
  const handleCloseDetails = () => setSelectedLoanId(null);

  const renderActions = (params) => {
    const { id } = params.row;
    return (
      <IconButton onClick={() => handleOpenDetails(id)}>
        <RemoveRedEye
          sx={{
            color: "#404A56",
          }}
        />
      </IconButton>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: t("Backoffice.FinancialProduct.LoanID"),
      flex: 0.3,
    },
    {
      field: "loanType",
      headerName: t("Backoffice.FinancialProduct.LoanType.Title"),
      flex: 0.15,
    },
    {
      field: "date",
      headerName: t("Backoffice.FinancialProduct.Table.Date"),
      flex: 0.2,
    },
    {
      field: "amount",
      headerName: t("Backoffice.FinancialProduct.Table.Amount"),
      flex: 0.1,
    },
    {
      field: "loanStatus",
      headerName: t("Backoffice.FinancialProduct.Status.Title"),
      flex: 0.1,
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.06,
      renderCell: renderActions,
    },
  ];

  const rows = loans.map((loan) => {
    const { id, loanType, date, amount, loanStatus } = loan;
    return {
      id: id,
      loanType: loanType,
      date: formatDateTimeString(date),
      amount: formatCurrency(amount),
      loanStatus: loanStatus,
    };
  });

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          disableRowSelectionOnClick
          pageSizeOptions={[5]}
          rowCount={totalItems}
          loading={isLoading}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(newPagination) => {
            setPaginationModel(newPagination);
            onPageChange(newPagination.page);
          }}
          disableVirtualization={disableVirtualization}
          sx={{
            height: rows.length === 0 ? "401px" : null,
            padding: "16px",
            border: "none",
            "&>.MuiDataGrid-main": {
              "& div div div div >.MuiDataGrid-cell": {
                borderBottom: "none",
              },
            },
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: "#EEF0F2",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            },
          }}
        />
      </Box>
      <LoanInfoModal open={selectedLoanId !== null} loanId={selectedLoanId} onClose={handleCloseDetails} />
    </>
  );
}
