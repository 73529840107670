import React, { Fragment } from "react";
import { RequireAuth } from "react-auth-kit";
import "react-loading-skeleton/dist/skeleton.css";
import { Route, Routes } from "react-router-dom";

import "./App.css";

import Missing from "./components/Missing";
import ClientDetailsView from "./views/backoffice/ClientDetails";
import SearchClientView from "./views/backoffice/SearchClient";
import BackofficeServiceMenu from "./views/BackofficeServiceMenu";
import Configuration from "./views/Configuration";
import Help from "./views/Help";
import Insights from "./views/Insights";
import IntegratorConfiguration from "./views/IntegratorConfiguration";
import Merchants from "./views/Merchants";
import MigrateMerchants from "./views/MigrateMerchants";
import Micromerchants from "./views/Micromerchants";
import Operations from "./views/Operations";
import Payments from "./views/Payments";
import BankPayments from "./views/Risk/BankPayments";
import Batch from "./views/Risk/Batch";
import CreateBatch from "./views/Risk/CreateBatch";
import SideBar from "./views/sideBar";
import SignIn from "./views/SignIn";
import Transfer from "./views/Transfer";
import WorkingCapital from "./views/WorkingCapital";

function App() {
  return (
    <Fragment>
      <SideBar />
      <Routes>
        <Route exact path="/SignIn" element={<SignIn />} />
        <Route
          path="/index.html"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Insights />
            </RequireAuth>
          }
        />
        <Route
          path="/"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Insights />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/Configuration"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Configuration />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/IntegratorConfiguration"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <IntegratorConfiguration />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/Transfer"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Transfer />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/Operations"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Operations />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/Insights"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Insights />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/Merchants"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Merchants />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/Migrate"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <MigrateMerchants />
            </RequireAuth>
          }
        />
        <Route
          path="/Merchants/:ownerId"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Merchants />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/Payments"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Payments />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/Help"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Help />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/Micromerchants"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Micromerchants />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/Micromerchants/:client_id"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Micromerchants />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/WorkingCapital"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <WorkingCapital />
            </RequireAuth>
          }
        />
        <Route
          path="/WorkingCapital/:ownerId"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <WorkingCapital />
            </RequireAuth>
          }
        />
        <Route
          path="/Services"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <BackofficeServiceMenu />
            </RequireAuth>
          }
        />
        <Route
          path="/Risk/CreateBatch"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <CreateBatch />
            </RequireAuth>
          }
        />
        <Route
          path="/Risk/Batch"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <Batch />
            </RequireAuth>
          }
        />
        <Route
          path="/Risk/BankPayments"
          element={
            <RequireAuth loginPath={"/SignIn"}>
              <BankPayments />
            </RequireAuth>
          }
        />
        <Route exact path="Backoffice" element={<SearchClientView />} />
        <Route exact path="Backoffice/Client/:client_id" element={<ClientDetailsView />} />
        <Route path="*" element={<Missing />} />
      </Routes>
    </Fragment>
  );
}

export default App;
