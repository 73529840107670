import { axiosClientWc } from "../../commons/axiosClient";

export const validateDocumentsComplete = async (email, formId, country) => {
  let url = `/user/finish_document_validation?email=${email}&formId=${formId}&country=${country}`;
  return axiosClientWc
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const sendMailDocumentRemedy = (body) => {
  return axiosClientWc
    .post(`/document`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getContract = async (idpdv) => {
  const url = `download_contract`;
  return await axiosClientWc
    .get(url, {
      params: {
        key: idpdv,
        isView: true,
        isDownload: false,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const validDocumentsAbbreviate = (body) => {
  return axiosClientWc
    .post(`/documents/abbreviate`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const notifyRejected = (country) => {
  return axiosClientWc
    .post(`/document_resend`, null, {
      params: {
        country: country,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
