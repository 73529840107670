import React from "react";
import { useTranslation } from "react-i18next";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

const styles = {
  cell: {
    borderBottom: "none",
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 16,
  },
  title: {
    paddingBottom: 10,
  },
  table: {
    width: "100%",
  },
};

const Title = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#404A56",
  boxShadow: "none",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
}));

const DetailOwner = ({ detailOwner, translate = "Owners", rowVersion = "v1", icons = false }) => {
  const [t] = useTranslation("global");

  const selectIcon = (section) => {
    switch (section) {
      case "general_information":
        return <StoreMallDirectoryIcon />;
      default:
        return <AccountBalanceIcon />;
    }
  };

  const buildCell = (data) => {
    const cell = [];
    for (const aField in data) {
      cell.push(
        <Grid
          key={aField}
          item
          xs={6}
          style={{
            marginBottom: "10px",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box
                style={{
                  color: "#00000A",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {t(`${translate}.Profile.${aField}`)}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  color: "#4C5866",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                {data[aField]}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return cell;
  };

  const buildRowProfileV2 = (data) => {
    let newData = {};
    let counter = 0;
    let cell = [];
    for (const aField in data) {
      counter++;
      newData[aField] = data[aField];
      if (counter == 4) {
        counter = 0;
        cell.push(buildCell(newData));
        newData = {};
      }
    }
    return (
      <TableRow style={{ borderColor: "red" }}>
        <TableCell align="left" sx={styles.cell} style={{ color: "gray" }}>
          <Box style={{ width: "100%" }}>
            <Grid container>{cell}</Grid>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const buildRowProfile = (data) => {
    let boxArray = [];
    for (const aField in data) {
      boxArray.push(
        <TableRow key={aField}>
          <TableCell align="left" sx={styles.cell} style={{ color: "gray" }}>
            <Box style={{ width: "100%" }}>
              <Box style={{ width: "30%", float: "left" }}>{t(`${translate}.Profile.${aField}`)}</Box>
              <Box
                style={{
                  width: "70%",
                  float: "left",
                  color: "#404A56",
                  fontWeight: "500",
                }}
              >
                {data[aField]}
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return boxArray;
  };

  const buildProfile = (data) => {
    let gridArray = [];
    for (const aSection in data) {
      gridArray.push(
        <Grid item xs={6} key={aSection}>
          <Grid item xs={12} sx={{ borderStyle: "none" }}>
            {icons ? (
              <Box style={{ width: "8%", float: "left" }}>
                <Avatar
                  style={{
                    backgroundColor: "#FFC70D",
                    color: "#FFFFFF",
                    marginBottom: "10px",
                  }}
                >
                  {selectIcon(aSection)}
                </Avatar>
              </Box>
            ) : (
              <></>
            )}
            <Box
              style={{
                width: "92%",
                float: "left",
                marginBottom: "10px",
              }}
            >
              <Title>{t(`${translate}.Profile.${aSection}`)}</Title>
            </Box>
          </Grid>
          <Box>
            <TableContainer style={{ paddingBottom: "10px" }}>
              <Table sx={styles.table}>
                {rowVersion == "v1" ? (
                  <TableBody key={aSection}>{buildRowProfile(data[aSection])}</TableBody>
                ) : (
                  <TableBody key={aSection}>{buildRowProfileV2(data[aSection])}</TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      );
    }
    return gridArray;
  };

  return (
    <Card style={{ borderRadius: "20px" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{ borderStyle: "none" }} className={"card-grid-padding"}>
          {buildProfile(detailOwner)}
        </Grid>
      </Box>
    </Card>
  );
};

export default DetailOwner;
