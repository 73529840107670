const basicColumnDefinition = {
  headerClass: "ag-header-center",
  cellClass: "ag-cell-center",
  width: "100%",
  flex: 1,
};

function generateColumn(columDefinition) {
  return {
    ...basicColumnDefinition,
    ...columDefinition,
  };
}

export function ColumnsGenerator(columnsDefinition) {
  return columnsDefinition.map((columnDef) => {
    return generateColumn(columnDef);
  });
}
