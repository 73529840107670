import { sleep } from "../../utils/validateExpression";

const AUTH_AZURE = "authTokenAzure";

/**
 * The function sets an Azure authentication token in local storage.
 *
 * Args:
 *   data: The data parameter is the value that you want to store in the localStorage with the key
 * `authTokenAzure`.
 */
const setAuthTokenAzure = (data) => localStorage.setItem(AUTH_AZURE, data);

/**
 * The function `getAuthTokenAzure` retrieves the value of the `authTokenAzure` key from the browser's
 * local storage.
 */
const getAuthTokenAzure = () => localStorage.getItem(AUTH_AZURE);

/**
 * The function removes the Azure authentication token from the local storage, with an optional delay
 * if specified.
 *
 * Args:
 *   awaitFor: The `awaitFor` parameter is a boolean value that determines whether the function should
 * wait for a certain condition before executing the code inside the function. If `awaitFor` is `true`,
 * the function will wait for the condition to be met before continuing.
 * Defaults to false
 */
const removeAuthTokenAzure = async (awaitFor = false) => {
  if (awaitFor) await sleep();
  if (localStorage.getItem(AUTH_AZURE)) localStorage.removeItem(AUTH_AZURE);
};

export { getAuthTokenAzure, setAuthTokenAzure, removeAuthTokenAzure };
