import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Card, CardActionArea, CardActions, CardContent, Grid, Typography } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

import hello from "../../../img/hello.svg";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import GenericBackdrop from "../../../utils/GenericBackdrop";
import { Alert } from "../../utils/alert";

const Topics = ({ setIsAddHelp, setIdTopic }) => {
  const [t] = useTranslation("global");
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  const [topics, setTopics] = useState([]);

  useEffect(() => {
    handleLoaderOpen();

    axiosPrivate
      .get(`help/topics`)
      .then((response) => {
        setTopics(response.data);
        handleLoaderClose();
      })
      .catch((_) => {
        handleLoaderClose();
        return Alert(t("General.Message.ErrorService"), "error");
      });
  }, []);

  const getTopic = () => {
    if (topics.length > 0)
      return (
        <Fragment>
          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
            <Grid container spacing={2} sx={{ borderStyle: "none", maxWidth: "1240px !important" }}>
              <Grid item md={9} xs={12}>
                <Typography gutterBottom component="div" variant="text14">
                  {t("Configuration.Help.Topics.Section")}
                </Typography>
              </Grid>
              <Grid item md={3} xs={12} sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  style={{ width: "194px" }}
                  onClick={() => {
                    setIsAddHelp(true);
                  }}
                >
                  {t("Configuration.Help.Actions.Create")}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ borderStyle: "none", maxWidth: "1240px !important" }} mt={4}>
              {topics.map((item, index) => (
                <Grid item md={3} sm={6} xs={12} key={index} sx={{ paddingLeft: 24 }}>
                  <Card
                    sx={{
                      borderRadius: "16px",
                      boxShadow: "0 4px 11px rgba(194, 209, 217, 0.46)",
                      height: "208px",
                      maxWidth: "288px",
                    }}
                  >
                    <CardActionArea
                      sx={{
                        height: "208px",
                        borderRadius: "16px",
                        padding: "5%",
                        ":hover": {
                          border: "1px solid #FFC70D",
                        },
                      }}
                      onClick={() => {
                        setIdTopic(item.topicid);
                      }}
                    >
                      <Typography style={{ textAlign: "center" }} component="div" gutterBottom>
                        <SvgIcon viewBox="0 -2 18 26" style={{ fontSize: "40px" }}>
                          <path d={item.path} />
                        </SvgIcon>
                      </Typography>
                      <Typography variant="text18" style={{ textAlign: "center" }} component="div" gutterBottom>
                        {item.name}
                      </Typography>
                      <Typography variant="text14" style={{ textAlign: "center" }} component="div" gutterBottom>
                        {item.description}
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Fragment>
      );

    return (
      <Card className="mui-card">
        <GenericBackdrop open={loader} />
        <CardContent sx={{ textAlign: "center", marginTop: "120px" }}>
          <Typography gutterBottom component="div" variant="text24">
            {t("Configuration.Help.NoInfo")}
          </Typography>
          <Typography gutterBottom component="div" variant="text16" style={{ margin: "16px 0 82px 0" }}>
            {t("Configuration.Help.AddSection")}
          </Typography>
          <img src={hello} alt="Hello" />
        </CardContent>
        <CardActions sx={{ justifyContent: "center", margin: "62.3px 0 134px 0" }}>
          <Button
            variant="variant-contained"
            onClick={() => {
              setIsAddHelp(true);
            }}
          >
            {t("Configuration.Help.Actions.Add")}
          </Button>
        </CardActions>
      </Card>
    );
  };

  return getTopic();
};

export default Topics;
