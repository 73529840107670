import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Tab, Tabs } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { ThemeProvider } from "@mui/material/styles";

import RegisterTable from "../../components/batchs/registerTable";
import ShippingHistory from "../../components/batchs/ShippingHistory";
import Sesion from "../../components/commons/sesion";
import ThemeRisks from "../../themes/ThemeRisks";

export default function Batch() {
  const [t] = useTranslation("global");
  const [value, setValue] = useState(0);

  const handleChange = useCallback((_, tabValue) => {
    setValue(tabValue);
  }, []);

  function renderPanel() {
    if (value === 0) {
      return <RegisterTable />;
    }
    if (value === 1) {
      return <ShippingHistory />;
    }
  }

  return (
    <ThemeProvider theme={ThemeRisks}>
      <div className={"Drawer"}>
        <CardContent>
          <Sesion screen={"BatchConfig"} subtitle={"Risks"} />
        </CardContent>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "#DFE3E7" }}>
            <Tabs value={value} onChange={handleChange} data-testid="tabs">
              <Tab label={t("Risk.Menu.CreateBatchs")} value={0} data-testid="tab-createBatch" />
              <Tab label={t("Risk.Menu.BatchManagement")} value={1} data-testid="tab-shippingHistory" />
            </Tabs>
          </Box>
          {renderPanel()}
        </Box>
      </div>
    </ThemeProvider>
  );
}
