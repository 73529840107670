import React from "react";
import { useTranslation } from "react-i18next";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

function FileErrorAlert({ error, onClose, message = null }) {
  const [t] = useTranslation("global");
  if (!error) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: "360px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "#FFFFFF",
        padding: "40px",
        borderRadius: "16px",
        width: "381px",
        height: "fit-content",
        gap: "32px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 4px 12px 0px rgba(0,0,0,0.1)",
        zIndex: 1000,
      }}
      onClick={(e) => e.stopPropagation()}
      data-testid="error-alert-box"
    >
      <Box
        sx={{
          width: "68px",
          height: "68px",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ErrorOutlineIcon color="error" style={{ fontSize: 68 }} />
      </Box>

      <Typography
        fontSize={"16px"}
        sx={{
          fontFamily: "Readex Pro",
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: "0.247px",
          textAlign: "center",
          color: "#4C5866",
        }}
      >
        {message !== null ? message : t("Risk.BankPayments.UploadError")}
      </Typography>

      <Button
        variant="contained"
        sx={{
          width: "301px",
          height: "48px",
          borderRadius: "16px",
          backgroundColor: "#363853",
          color: "#FFFFFF",
        }}
        onClick={onClose}
        data-testid="on-close"
      >
        {t("Risk.BankPayments.Button")}
      </Button>
    </Box>
  );
}
FileErrorAlert.propTypes = {
  error: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
};
export default FileErrorAlert;
